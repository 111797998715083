import React from 'react';

import * as S from './styles';

export interface IndicatorEntry {
  onPress: () => void;
  isEnabled: boolean;
}

interface Data {
  data: IndicatorEntry[];
}

function Indicator({ data }: Data) {
  return (
    <S.Container>
      {data.map((item: IndicatorEntry, i: number) => (
        <S.CircularButton key={i} isEnabled={item.isEnabled} onClick={item.onPress}>
          {' '}
        </S.CircularButton>
      ))}
    </S.Container>
  );
}

export { Indicator };
