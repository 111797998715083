import styled from 'styled-components';

import BeatLoader from 'react-spinners/BeatLoader';

export const Button = styled.button`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  width: fit-content;

  :hover {
    transition: opacity 0.3s linear;
    opacity: 0.6;
  }

  :disabled {
    opacity: 0.6;
  }
`;

export const ButtonIcon = styled.img`
  width: 16px;
  height: 14px;

  margin-left: 8px;
`;

export const Loader = styled(BeatLoader).attrs(({ theme }) => {
  return { color: theme.palette.mono.main, size: 7 };
})`
  margin-left: 8px;
`;
