import React from 'react';

import { useTranslation } from 'react-i18next';

import * as S from './styles';

interface FeatureCardProps {
  title: string;
  subTitle: string;
  image: string;
  alt: string;
}

function FeatureCard({ title, subTitle, image, alt }: FeatureCardProps) {
  const { t: translator } = useTranslation();

  return (
    <S.Container>
      <S.Image src={image} alt={alt} />

      <S.SubTitle>{translator(subTitle)}</S.SubTitle>

      <S.Title>{translator(title)}</S.Title>
    </S.Container>
  );
}

export default FeatureCard;
