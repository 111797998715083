import React, { Fragment } from 'react';

import Spacer from 'components/Spacer';
import ArrowButton, { ArrowButtonColor } from 'components/ArrowButton';

import Image from 'assets/images/cards/join-us.webp';

import { useNavigate } from 'react-router-dom';

import useWindowDimensions from 'hooks/window-dimensions';
import defaultTheme from 'styles/theme';

import Text, { TextStyleType } from 'components/Text';

import * as S from './styles';

interface CardJoinUsProps {
  title: string;
  text: string;
  image?: string;
  alt?: string;
  color?: 'blue' | 'neutral' | 'white';
  route?: string;
  onPressed?: () => void;
  buttonLabel?: string;
  className?: string;
}

function CardJoinUs({
  title,
  text,
  image = Image,
  alt = '',
  color = 'neutral',
  route = '/',
  onPressed,
  buttonLabel = 'SAIBA MAIS',
  className,
}: CardJoinUsProps) {
  const map: { [id: string]: { backgroundColor: string; titleColor: string; textColor: string; buttonColor: ArrowButtonColor } } = {};

  map.blue = {
    backgroundColor: defaultTheme.palette.secondary.main,
    titleColor: defaultTheme.palette.primary.main,
    textColor: defaultTheme.palette.primary.main,
    buttonColor: ArrowButtonColor.Blue,
  };
  map.neutral = {
    backgroundColor: defaultTheme.palette.neutral.main,
    titleColor: defaultTheme.palette.contrast.main,
    textColor: defaultTheme.palette.mono.main,
    buttonColor: ArrowButtonColor.Green,
  };

  map.white = {
    backgroundColor: defaultTheme.palette.primary.main,
    titleColor: defaultTheme.palette.mono.main,
    textColor: defaultTheme.palette.mono.main,
    buttonColor: ArrowButtonColor.White,
  };

  const { width } = useWindowDimensions();
  const { hd } = defaultTheme.screensSize;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();

  return (
    <S.Card className={className} color={map[color].backgroundColor}>
      <S.Image src={image} alt={alt} />

      <S.Column>
        <Text className={className} type={TextStyleType.SectionTitle} color={map[color].titleColor}>
          {title}
        </Text>

        <Spacer height={10} />

        {text.split('|').map(function f(item, i) {
          return (
            <Fragment key={i}>
              <Text className={className} type={TextStyleType.CardJoinUsText} color={map[color].textColor}>
                {item}
              </Text>

              <br />
            </Fragment>
          );
        })}

        <Spacer height={40} />

        <ArrowButton
          label={buttonLabel}
          color={map[color].buttonColor}
          onPressed={onPressed !== undefined ? onPressed : () => navigate(route)}
        />

        <Spacer height={width < hd ? 40 : 0} />
      </S.Column>
    </S.Card>
  );
}

export default CardJoinUs;
