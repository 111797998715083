import React from 'react';

import { useTranslation } from 'react-i18next';

import useWindowDimensions from 'hooks/window-dimensions';

import defaultTheme from 'styles/theme';

import Text, { TextStyleType } from 'components/Text';

import * as S from './styles';

interface JobProps {
  name: string;
  badges: string[];
  location: string;
  form: string;
}

function Job({ name, badges, location, form }: JobProps) {
  const { t: translator } = useTranslation();

  const { width } = useWindowDimensions();
  const { mobile } = defaultTheme.screensSize;

  return (
    <S.Container onClick={width < mobile ? () => window.open(form, '_blank')?.focus() : undefined}>
      <S.Column>
        <S.RowLabel>
          <Text type={TextStyleType.JobLabel}>{translator(name)}</Text>

          <S.RowBadges>
            {badges.map((item, i) => {
              return (
                <S.Badge key={i}>
                  <Text type={TextStyleType.JobBadgeLabel}>{item}</Text>
                </S.Badge>
              );
            })}
          </S.RowBadges>
        </S.RowLabel>

        <S.Row>
          <S.Icon />

          <Text type={TextStyleType.JobLocationLabel}>{translator(location)}</Text>
        </S.Row>
      </S.Column>

      <S.Spacer />

      <S.Button onClick={() => window.open(form, '_blank')?.focus()}>
        {width < mobile ? (
          <S.ChevronIcon />
        ) : (
          <S.Row>
            <Text type={TextStyleType.JobButtonLabel}>{translator('career.jobs.buttonLabel')}</Text>

            <S.ArrowIcon />
          </S.Row>
        )}
      </S.Button>
    </S.Container>
  );
}

export default Job;
