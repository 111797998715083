import styled from 'styled-components';

export const BodyParagraph = styled.p<{ color: string; textAlign: string }>`
  width: 100%;

  font-weight: 400;

  font-size: 2.4rem;
  line-height: 4.2rem;

  text-align: ${({ textAlign }) => textAlign};
  color: ${({ color }) => color};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
`;

export const HighlightParagraphTitle = styled.p<{ textAlign: string }>`
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.5rem;

  text-align: ${({ textAlign }) => textAlign};
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const CampInfoItemLabel = styled.p<{ textAlign: string; marginLeft: number }>`
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.6rem;

  margin-left: ${({ marginLeft }) => `${marginLeft}px`};
  text-align: ${({ textAlign }) => textAlign};
  color: ${({ theme }) => theme.palette.neutral.main};
`;

export const CampInfoItemHighlight = styled.p<{ textAlign: string }>`
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.4rem;

  text-align: ${({ textAlign }) => textAlign};
`;

export const BodyParagraphNotFoundP = styled.p<{ color: string; textAlign: string }>`
  width: 100%;

  font-weight: 400;

  font-size: 2.4rem;
  line-height: 3.6rem;

  text-align: ${({ textAlign }) => textAlign};
  color: ${({ color }) => color};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 2rem;
    line-height: 2.4rem;
  }
`;

export const ListTitleH3 = styled.h3<{ color: string; textAlign: string }>`
  width: 100%;

  font-weight: 700;

  font-size: 4.8rem;
  line-height: 6.4rem;

  letter-spacing: -0.01em;

  padding-bottom: 10px;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 2.4rem;
    line-height: 3.6rem;
  }
`;

export const ListItemP = styled.p<{ color: string; textAlign: string }>`
  font-weight: 400;

  font-size: 2.4rem;
  line-height: 4.2rem;

  margin: 10px 0;

  color: ${({ color }) => color};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 1.8rem;
    line-height: 2.4rem;

    margin: 8px 0;
  }
`;

export const KnowMoreButtonLabel = styled.p<{ color: string; textAlign: string }>`
  width: 100%;

  font-weight: 500;

  font-size: 2.4rem;
  line-height: 4.2rem;

  letter-spacing: 0.1em;

  text-align: ${({ textAlign }) => textAlign};
  color: ${({ color }) => color};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
`;

export const TitleStrongCard = styled.p<{ color: string; textAlign: string }>`
  font-weight: 700;

  font-size: 2rem;
  line-height: 2.4rem;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
`;

export const ContactItemText = styled.p<{ color: string; textAlign: string }>`
  font-weight: 700;

  font-size: 2.4rem;
  line-height: 3.2rem;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};

  white-space: nowrap;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;

export const TopPageTitle = styled.h1<{ color: string; textAlign: string }>`
  width: 100%;

  font-weight: 700;
  font-size: 4.8rem;
  line-height: 6.4rem;

  letter-spacing: -0.01em;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 2.4rem;
    line-height: 3.6rem;
  }
`;

export const TopPageTitleAbout = styled.h2<{ color: string; textAlign: string }>`
  width: 100%;

  font-weight: 700;

  font-size: 4.8rem;
  line-height: 6.4rem;

  letter-spacing: -0.01em;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 3.2rem;
    line-height: 3.6rem;

    padding-bottom: 4px;
  }
`;

export const TopPageTitleNotFound = styled.h1<{ color: string; textAlign: string }>`
  width: 100%;

  font-weight: 700;

  font-size: 3.2rem;
  line-height: 3.6rem;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 2.4rem;
    line-height: 3rem;
  }
`;

export const NumberEntryAboutH2 = styled.p<{ color: string; textAlign: string }>`
  font-weight: 400;

  font-size: 3.2rem;
  line-height: 4rem;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};
`;

export const FeatureCardTitleH3 = styled.h3<{ color: string; textAlign: string }>`
  font-weight: 700;

  font-size: 2.4rem;
  line-height: 2.6rem;

  word-wrap: break-word;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};
`;

export const FeatureCardSubtitleH3 = styled.p<{ color: string; textAlign: string }>`
  font-weight: 400;

  font-size: 1.8rem;
  line-height: 2.6rem;

  letter-spacing: 0.1rem;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};
`;

export const TextYearAboutH2 = styled.h3<{ color: string; textAlign: string }>`
  font-weight: 700;

  font-size: 1.8rem;
  line-height: 2.6rem;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};
`;

export const TextDescriptionAboutH2 = styled.p<{ color: string; textAlign: string }>`
  font-weight: 400;

  font-size: 1.8rem;
  line-height: 2.6rem;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};
`;

export const SubtitleContacts = styled.h3<{ color: string; textAlign: string }>`
  width: 100%;

  font-weight: 500;

  font-size: 4rem;
  line-height: 4.6rem;

  padding-top: 40px;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 2.4rem;
    line-height: 3.2rem;

    padding-top: 20px;
  }
`;

export const SectionTitle = styled.h3<{ color: string; textAlign: string; paddingBottom: boolean }>`
  font-weight: 500;

  font-size: 6.4rem;
  line-height: 7.2rem;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};

  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? `20px;` : `0;`)};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 3.2rem;
    line-height: 4.2rem;

    padding-bottom: ${({ paddingBottom }) => (paddingBottom ? `4px;` : `0;`)};
  }
`;

export const JobsSectionTitle = styled.h2<{ color: string; textAlign: string }>`
  font-weight: 500;

  font-size: 6.4rem;
  line-height: 7.2rem;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};

  margin-bottom: 80px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 3.2rem;
    line-height: 4.2rem;

    margin-bottom: 40px;
  }
`;

export const Tagline = styled.h4<{ color: string; textAlign: string }>`
  font-weight: 400;

  font-size: 3.2rem;
  line-height: 4rem;

  letter-spacing: 0.1rem;
  font-variant: small-caps;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
`;

export const CardJoinUsText = styled.p<{ color: string; textAlign: string }>`
  font-weight: 600;

  font-size: 2rem;
  line-height: 3.2rem;

  color: ${({ color }) => color};

  text-align: ${({ textAlign }) => textAlign};

  max-width: 700px;
  white-space: pre-wrap;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`;

export const JobLabel = styled.p<{ color: string; textAlign: string }>`
  font-weight: 700;

  font-size: 2rem;
  line-height: 2.4rem;

  white-space: nowrap;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    white-space: normal;

    font-size: 1.4rem;
    line-height: 2rem;
  }
`;

export const JobLocationLabel = styled.p<{ color: string; textAlign: string }>`
  font-weight: 500;

  font-size: 1.6rem;
  line-height: 2rem;

  color: ${({ theme }) => theme.palette.neutral.main};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
`;

export const JobBadgeLabel = styled.p<{ color: string; textAlign: string }>`
  font-weight: 700;

  font-size: 1rem;
  line-height: 1.2rem;

  white-space: nowrap;

  color: ${({ theme }) => theme.palette.primary.main};
`;

export const JobButtonLabel = styled.p<{ color: string; textAlign: string }>`
  font-weight: 600;

  font-size: 1.6rem;
  line-height: 3.2rem;

  white-space: nowrap;

  color: ${({ theme }) => theme.palette.mono.main};
`;

export const RowItem = styled.p<{ color: string; textAlign: string }>`
  font-weight: 600;

  font-size: 2.4rem;
  line-height: 3rem;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-weight: 400;

    font-size: 1.8rem;
    line-height: 2.4rem;
  }
`;

export const ProgramSubtitle = styled.h4<{ color: string; textAlign: string }>`
  width: 100%;

  margin-bottom: 20px;

  font-weight: 500;

  font-size: 3.2rem;
  line-height: 4rem;

  letter-spacing: 0.1em;
  font-variant: small-caps;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 1.8rem;
    line-height: 2.4rem;

    max-width: 280px;
    margin-bottom: 0;
  }
`;

export const TitleBanner = styled.h1<{ color: string; textAlign: string }>`
  width: 100%;

  font-weight: 700;

  font-size: 4.8rem;
  line-height: 6.4rem;
  letter-spacing: -0.01em;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 2.4rem;
    line-height: 3.6rem;
  }
`;

export const TitleBannerH2 = styled.h2<{ color: string; textAlign: string }>`
  width: 100%;

  font-weight: 700;

  font-size: 4.8rem;
  line-height: 6.4rem;
  letter-spacing: -0.01em;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 2.4rem;
    line-height: 3.6rem;
  }
`;

export const BadgeClientText = styled.p<{ color: string; textAlign: string }>`
  font-weight: 500;

  font-size: 2rem;
  line-height: 2.4rem;

  color: #c5c7c8;

  white-space: nowrap;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
`;

export const SubscribeButtonLabel = styled.p<{ color: string; textAlign: string }>`
  font-weight: 500;

  font-size: 2rem;
  line-height: 2.6rem;

  letter-spacing: 0.1rem;
  font-variant: small-caps;
`;

export const SelectiveProcessStepText = styled.p<{ color: string; textAlign: string }>`
  font-weight: 700;

  font-size: 2rem;
  line-height: 2.6rem;

  @media (max-width: ${({ theme }) => `${theme.screensSize.fullHd}px`}) {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
`;

export const SubtitleBanner = styled.p<{ color: string; textAlign: string }>`
  font-weight: 400;

  font-size: 3.2rem;
  line-height: 4rem;

  font-variant: small-caps;

  letter-spacing: 0.01em;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 2.4rem;
    line-height: 3.6rem;
  }
`;

export const TitleBannerCta = styled.h4<{ color: string; textAlign: string }>`
  width: 100%;

  font-weight: 600;
  font-size: 6.4rem;
  line-height: 7.2rem;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 2.4rem;
    line-height: 3.6rem;
  }
`;

export const DescriptionBannerCta = styled.h4<{ color: string; textAlign: string }>`
  width: 100%;

  font-weight: 600;

  font-size: 2rem;
  line-height: 3.2rem;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`;

export const TextHighlight = styled.span`
  font-family: inherit;

  font-size: inherit;
  line-height: inherit;

  font-weight: 700;

  display: inline;

  color: ${({ theme }) => theme.palette.primary.main};
`;

export const FooterButton = styled.div<{ color: string; textAlign: string }>`
  width: 100%;

  font-size: 1.4rem;
  line-height: 1.8rem;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};
`;

export const FooterTitleP = styled.button<{ color: string; textAlign: string }>`
  font-weight: 700;

  font-size: 1.6rem;
  line-height: 2rem;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};
`;

export const LinkToTextA = styled.div<{ color: string; textAlign: string }>`
  font-size: 1.4rem;
  line-height: 1.8rem;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};
`;

export const FooterTextAccompanyP = styled.div<{ color: string; textAlign: string }>`
  font-weight: 700;

  font-size: 1.3rem;
  line-height: 2.4rem;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};
`;

export const FooterTextRubP = styled.p<{ color: string; textAlign: string }>`
  width: 100%;

  font-weight: 600;

  font-size: 1.1rem;
  line-height: 1.8rem;

  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    font-size: 1rem;
    line-height: 1.4rem;
  }
`;
