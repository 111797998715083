import React, { ReactNode, useEffect, useRef } from 'react';

import { Outlet, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import ScrollTopButton from 'components/ScrollTopButton';

import Flags from 'components/Flags';

import Header, { HeaderItem } from 'components/Header';
import Footer from 'components/Footer';

import Store from 'contexts/Store';

import { HOMEPAGE_SCREENS } from 'routes/screen-definitions';

import * as S from './styles';

interface BasicLayoutProps {
  children?: ReactNode;
}

function BasicLayout({ children }: BasicLayoutProps) {
  const { t: translator } = useTranslation();
  const refOutlet: any = useRef();

  const { setData } = Store.useStore();

  const items: HeaderItem[] = [
    {
      label: translator('header.site.home'),
      route: `${HOMEPAGE_SCREENS.home}`,
    },
    {
      label: translator('header.site.about'),
      route: `${HOMEPAGE_SCREENS.home}${HOMEPAGE_SCREENS.about}`,
    },
    {
      label: translator('header.site.services'),
      route: `${HOMEPAGE_SCREENS.home}${HOMEPAGE_SCREENS.services}`,
    },
    {
      label: translator('header.site.rubLabs'),
      route: `${HOMEPAGE_SCREENS.home}${HOMEPAGE_SCREENS.rublabs}`,
    },
    {
      label: translator('header.site.career'),
      route: `${HOMEPAGE_SCREENS.home}${HOMEPAGE_SCREENS.career}`,
    },
    {
      label: translator('header.site.contact'),
      route: `${HOMEPAGE_SCREENS.home}${HOMEPAGE_SCREENS.contact}`,
    },
  ];

  const { pathname } = useLocation();

  const handleClick = () => {
    setData({ isMobileMenuExpanded: false });
  };

  useEffect(() => {
    refOutlet?.current?.addEventListener('mousedown', handleClick);
    return () => refOutlet?.current?.removeEventListener('mousedown', handleClick);
  }, []);

  return (
    <S.Container>
      <Header items={items} actionButton={<Flags />} isHomePage />

      <S.OutletHOC ref={refOutlet}>
        <S.Motion key={pathname}>
          <main>{children || <Outlet />}</main>
        </S.Motion>
      </S.OutletHOC>

      <Footer />

      <ScrollTopButton />
    </S.Container>
  );
}

export default BasicLayout;
