import React from 'react';

import * as S from './styles';

interface StepIconProps {
  text: string;
  imageUrl: string;
  imageAlt: string;
}

function StepIcon({ text, imageUrl, imageAlt }: StepIconProps) {
  return (
    <S.Container>
      <S.Circle>
        <S.Icon src={imageUrl} alt={imageAlt} />
      </S.Circle>

      <S.Text>{text}</S.Text>
    </S.Container>
  );
}

export default StepIcon;
