import React from 'react';

import { useTranslation } from 'react-i18next';
import useWindowDimensions from 'hooks/window-dimensions';

import defaultTheme from 'styles/theme';

import { Formatter } from 'utils/formatter';

import { email, googleMapsUrl, phone } from '@static-files/about-rub';

import Text, { TextStyleType } from 'components/Text';

import * as S from './styles';

interface ContactItemProps {
  type: 'email' | 'phone' | 'location';
}

function ContactItem({ type }: ContactItemProps) {
  const { t: translator } = useTranslation();

  const { mobile } = defaultTheme.screensSize;
  const { width } = useWindowDimensions();

  const map: { [id: string]: { button: JSX.Element } } = {};

  map.email = {
    button: (
      <S.ButtonContainer>
        <S.Link href={`mailto:${email}`} target='_blank' rel='noopener noreferrer'>
          <S.ButtonsRow>
            <S.Row>
              <S.EmailIcon />
              <Text type={TextStyleType.ContactItemText} color={defaultTheme.palette.neutral.main}>
                {email}
              </Text>
            </S.Row>

            {width < mobile && <S.ArrowIcon />}
          </S.ButtonsRow>
        </S.Link>
      </S.ButtonContainer>
    ),
  };

  map.phone = {
    button: (
      <S.ButtonContainer>
        <S.Link href={`tel:+${Formatter.onlyNumbers(phone)}`} rel='noopener noreferrer'>
          <S.ButtonsRow>
            <S.Row>
              <S.PhoneIcon />
              <Text type={TextStyleType.ContactItemText} color={defaultTheme.palette.neutral.main}>
                {phone}
              </Text>
            </S.Row>

            {width < mobile && <S.ArrowIcon />}
          </S.ButtonsRow>
        </S.Link>
      </S.ButtonContainer>
    ),
  };

  map.location = {
    button: (
      <S.ButtonContainer>
        <S.Link href={googleMapsUrl} target='_blank' rel='noopener noreferrer'>
          <S.ButtonsRow>
            <S.Row>
              <S.MapIcon />
              <Text type={TextStyleType.ContactItemText} color={defaultTheme.palette.neutral.main}>
                {translator('contactUs.contact.office')}
              </Text>
            </S.Row>

            {width < mobile && <S.ArrowIcon />}
          </S.ButtonsRow>
        </S.Link>
      </S.ButtonContainer>
    ),
  };

  return map[type].button;
}

export default ContactItem;
