import styled from 'styled-components';

import TextBase, { TextStyleType } from 'components/Text';

export const Container = styled.div`
  width: 100%;

  align-items: center;
  justify-content: center;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;

  background-color: #c5c7c8;
`;

export const Label = styled(TextBase).attrs({
  type: TextStyleType.BadgeClientText,
})``;
