import React from 'react';

import defaultTheme from 'styles/theme';

import { useTranslation } from 'react-i18next';

import OurVisionImage from 'assets/images/about/our-vision.webp';

import ImageText from 'components/ImageText';
import { TextStyleType } from 'components/Text';

import * as S from './styles';

function OurVision() {
  const { t: translator } = useTranslation();

  return (
    <S.Container id='our-vision'>
      <ImageText
        image={OurVisionImage}
        alt={translator('about.ourVision.alt')}
        title={translator('about.ourVision.title')}
        text={translator('about.ourVision.text')}
        titleStyle={TextStyleType.TopPageTitleAbout}
        titleColor={defaultTheme.palette.secondary.main}
      />
    </S.Container>
  );
}

export default OurVision;
