import DigimaisUrl from 'assets/logos/clients/client-digimais.svg';
import ContabilizeiUrl from 'assets/logos/clients/client-contabilizei.svg';

export const clients = [
  {
    name: 'Digimais',
    logo: DigimaisUrl,
    text: '',
    badge: false,
  },
  {
    name: 'Contabilizei',
    logo: ContabilizeiUrl,
    text: '',
    badge: true,
  },
];
