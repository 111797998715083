import React from 'react';

import { useNavigate } from 'react-router-dom';

import Spacer from 'components/Spacer';
import ArrowButton, { ArrowButtonColor } from 'components/ArrowButton';

import Image from 'assets/images/home/our-mission-background.webp';

import defaultTheme from 'styles/theme';
import useWindowDimensions from 'hooks/window-dimensions';

import { useTranslation } from 'react-i18next';

import Text, { TextStyleType, TextAlign } from 'components/Text';

import { HOMEPAGE_SCREENS } from 'routes/screen-definitions';

import * as S from './styles';

function OurMission() {
  const navigate = useNavigate();
  const { t: translator } = useTranslation();

  const { width } = useWindowDimensions();
  const { mobile } = defaultTheme.screensSize;

  return (
    <S.Container>
      <S.Image src={Image} alt={translator('home.ourMission.alt')} />

      <S.Content>
        <Spacer height={width < mobile ? 30 : 80} />

        <Text type={TextStyleType.SectionTitle}>{translator('home.ourMission.title')}</Text>

        <S.SubtitleContainer>
          <Text
            type={TextStyleType.BodyParagraph}
            color={defaultTheme.palette.secondary.main}
            textAlign={width < mobile ? TextAlign.Start : TextAlign.Center}
          >
            {translator('home.ourMission.text')}
          </Text>
        </S.SubtitleContainer>

        <Spacer height={width < mobile ? 20 : 40} />

        <ArrowButton
          label={translator('home.ourMission.buttonLabel')}
          color={ArrowButtonColor.Blue}
          onPressed={() => navigate(`${HOMEPAGE_SCREENS.home}${HOMEPAGE_SCREENS.about}`)}
        />
      </S.Content>
    </S.Container>
  );
}

export default OurMission;
