import React, { ReactNode } from 'react';
import Spacer from 'components/Spacer';

import { Carousel } from 'components/Carousel';
import ArrowButton, { ArrowButtonColor } from 'components/ArrowButton';

import { TextHighlight } from 'components/Text/styles';

import { useTranslation } from 'react-i18next';
import useWindowDimensions from 'hooks/window-dimensions';

import { googleMapsUrl } from '@static-files/about-rub';

import CarouselImage01 from 'assets/images/office/rublabs-01.webp';
import CarouselImage02 from 'assets/images/office/rublabs-02.webp';
import CarouselImage03 from 'assets/images/office/rublabs-03.webp';
import CarouselImage04 from 'assets/images/office/rublabs-04.webp';

import defaultTheme from 'styles/theme';

import * as S from './styles';

function RubLabs() {
  const { t: translator } = useTranslation();

  const { width } = useWindowDimensions();
  const { mobile, hd } = defaultTheme.screensSize;

  const carouselItemWidth = width < mobile ? 332 : 558;
  const carouselItemHeight = width < mobile ? 220 : 368;

  const carouselContent: ReactNode[] = [
    <img src={CarouselImage01} alt={translator('rubLabs.office.alt1')} width={carouselItemWidth} height={carouselItemHeight} />,
    <img src={CarouselImage02} alt={translator('rubLabs.office.alt2')} width={carouselItemWidth} height={carouselItemHeight} />,
    <img src={CarouselImage03} alt={translator('rubLabs.office.alt3')} width={carouselItemWidth} height={carouselItemHeight} />,
    <img src={CarouselImage04} alt={translator('rubLabs.office.alt4')} width={carouselItemWidth} height={carouselItemHeight} />,
  ];
  const pageContent = (
    <>
      <S.Column>
        <S.Column>
          <S.Title>{translator('rubLabs.office.title')}</S.Title>

          <Spacer height={8} />

          <S.Text>
            {translator('rubLabs.office.text1')}
            <TextHighlight>{translator('rubLabs.office.highlight1')}</TextHighlight>
            {translator('rubLabs.office.text2')}
          </S.Text>
        </S.Column>

        {width < mobile && <Spacer height={20} />}

        <ArrowButton
          label={translator('rubLabs.office.buttonLabel')}
          color={ArrowButtonColor.Blue}
          onPressed={() => window.open(googleMapsUrl, '_blank')?.focus()}
        />

        {width < hd && <Spacer height={40} />}
      </S.Column>

      <Carousel items={carouselContent} carouselHeight={carouselItemHeight} itemWidth={carouselItemWidth} />
    </>
  );

  return (
    <S.Container>
      <Spacer height={width < mobile ? 40 : 80} />

      {width < hd ? <S.DefaultColumn>{pageContent}</S.DefaultColumn> : <S.Row>{pageContent}</S.Row>}

      <Spacer height={width < mobile ? 40 : 80} />
    </S.Container>
  );
}

export default RubLabs;
