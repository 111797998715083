import React from 'react';

import { useTranslation } from 'react-i18next';

import Image from 'assets/images/cards/subscribe.webp';

import { SectionsId } from 'pages/Rubcamp/sectionIds';
import { formRubcamp } from '@static-files/about-rub';

import * as S from './styles';

function Subscribe() {
  const { t: translator } = useTranslation();

  return (
    <S.Container id={SectionsId.Subscribe}>
      <S.CardJoinUs
        title={translator('rubcamp.subscribe.title')}
        alt={translator('rubcamp.subscribe.alt')}
        text={translator('rubcamp.subscribe.text')}
        color='white'
        image={Image}
        buttonLabel={translator('rubcamp.subscribe.buttonLabel')}
        onPressed={() => window.open(formRubcamp, '_blank')?.focus()}
      />
    </S.Container>
  );
}

export default Subscribe;
