import React from 'react';

import * as S from './styles';

interface CompanyCardProps {
  logo: string;
}

function CompanyCard({ logo }: CompanyCardProps) {
  return (
    <S.Container>
      <S.Logo src={logo} alt='' />
    </S.Container>
  );
}

export default CompanyCard;
