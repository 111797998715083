import React from 'react';

import { useTranslation } from 'react-i18next';
import useWindowDimensions from 'hooks/window-dimensions';
import defaultTheme from 'styles/theme';

import Spacer from 'components/Spacer';

import IconFormUri from 'assets/icons/selective-proccess/form.svg';
import IconDevelopmentUri from 'assets/icons/selective-proccess/development.svg';
import IconGroupUri from 'assets/icons/selective-proccess/group.svg';
import IconConversationUri from 'assets/icons/selective-proccess/conversation.svg';
import IconFitnessUri from 'assets/icons/selective-proccess/fitness.svg';

import Text, { TextStyleType } from 'components/Text';
import { SectionsId } from 'pages/Rubcamp/sectionIds';

import StepIcon from './StepIcon';

import * as S from './styles';

function SelectiveProcess() {
  const { t: translator } = useTranslation();

  const { width } = useWindowDimensions();
  const { mobile } = defaultTheme.screensSize;

  return (
    <S.Container id={SectionsId.SelectiveProcess}>
      <S.Content>
        <Spacer height={width < mobile ? 40 : 80} />

        <S.Row>
          <S.Column>
            <Text type={TextStyleType.SectionTitle}>{translator('rubcamp.selectiveProcess.title')}</Text>

            <S.StepsContainer>
              <S.Divider />

              <StepIcon text={translator('rubcamp.selectiveProcess.label1')} imageUrl={IconFormUri} imageAlt='' />

              <StepIcon text={translator('rubcamp.selectiveProcess.label2')} imageUrl={IconDevelopmentUri} imageAlt='' />

              <StepIcon text={translator('rubcamp.selectiveProcess.label3')} imageUrl={IconGroupUri} imageAlt='' />

              <StepIcon text={translator('rubcamp.selectiveProcess.label4')} imageUrl={IconConversationUri} imageAlt='' />

              <StepIcon text={translator('rubcamp.selectiveProcess.label5')} imageUrl={IconFitnessUri} imageAlt='' />
            </S.StepsContainer>
          </S.Column>
        </S.Row>

        <Spacer height={width < mobile ? 40 : 80} />
      </S.Content>
    </S.Container>
  );
}

export default SelectiveProcess;
