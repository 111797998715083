import React, { FormEvent, useState } from 'react';

import { useTranslation } from 'react-i18next';

import useApi from 'hooks/api';
import useForm from 'hooks/form';

import { isEmail, isEmpty } from 'utils/validate';

import * as S from './styles';

interface FromProps {
  setMessageSent: (sent: boolean) => void;
  title: string;
  text: string;
}

function Form({ setMessageSent, title, text }: FromProps) {
  const { t: translator } = useTranslation();

  const contactService = useApi('base', 'post', '/contact', {});

  const [form] = useForm({
    validations: { name: [isEmpty()], email: [isEmpty(), isEmail()], message: [isEmpty()] },
    initialValues: { name: '', email: '', message: '' },
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleOnSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsLoading(true);

    try {
      const response = await contactService.fetch({
        dynamicParams: { name: form.getValue('name'), email: form.getValue('email'), email_content: form.getValue('message') },
      });

      if (response?.is_valid) {
        setMessageSent(true);

        form.setValues({ name: '', email: '', message: '' });
      } else {
        setMessageSent(false);
      }
    } catch (err) {
      setMessageSent(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <S.Column>
        <S.Title>{title}</S.Title>

        <S.SubTitle>{text}</S.SubTitle>
      </S.Column>

      <S.Gap />

      <S.Column>
        <S.Form onSubmit={form.trySave(handleOnSubmit)}>
          <S.Row>
            <S.Input
              placeholder={translator('components.cardContact.form.name')}
              error={form.getError('name') !== null}
              noError={form.getError('name') === null && (form.getValue('name') as string)?.length > 0}
              value={(form.getValue('name') as string) ?? ''}
              onChange={(value) => form.setValues({ name: value.target.value })}
            />

            <S.Separator />

            <S.Input
              placeholder={translator('components.cardContact.form.email')}
              error={(form.getError('email') as string) !== null}
              noError={form.getError('email') === null && (form.getValue('email') as string)?.length > 0}
              value={(form.getValue('email') as string) ?? ''}
              onChange={(value) => form.setValues({ email: value.target.value })}
            />
          </S.Row>

          <S.TextInput
            placeholder={translator('components.cardContact.form.message')}
            error={(form.getError('message') as string) !== null}
            noError={form.getError('message') === null && (form.getValue('message') as string)?.length > 0}
            value={(form.getValue('message') as string) ?? ''}
            onChange={(value) => form.setValues({ message: value.target.value })}
          />

          <S.Row>
            <S.ArrowButton label={translator('components.cardContact.form.buttonLabel')} isLoading={isLoading} />
          </S.Row>
        </S.Form>
      </S.Column>
    </>
  );
}

export default Form;
