import React, { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import Spacer from 'components/Spacer';
import Text, { TextAlign, TextStyleType } from 'components/Text';
import useWindowDimensions from 'hooks/window-dimensions';
import defaultTheme from 'styles/theme';
import { Carousel } from 'components/Carousel';
import { SectionsId } from '../sectionIds';

import * as S from './styles';
import carouselItems from './CarouselItems';

function LastEdition() {
  const { width } = useWindowDimensions();
  const { mobile, ultraHd, fullHd } = defaultTheme.screensSize;

  let carouselItemWidth;

  if (width > ultraHd) {
    carouselItemWidth = 1600;
  } else if (width > fullHd) {
    carouselItemWidth = 1200;
  } else if (width > mobile) {
    carouselItemWidth = 750;
  } else if (width > 360) {
    carouselItemWidth = 360;
  } else {
    carouselItemWidth = 320;
  }
  const carouselItemHeight = 640;
  const carouselItemsDisplay: ReactNode[] = carouselItems(width);

  const { t: translator } = useTranslation();

  return (
    <S.Container id={SectionsId.LastEdition} draggable={false}>
      <Spacer height={width < mobile ? 40 : 80} />
      <S.Content>
        <Text type={TextStyleType.SectionTitle} textAlign={TextAlign.Center} paddingBottom>
          {translator('rubcamp.lastEdition.title')}
        </Text>
        <Carousel
          items={carouselItemsDisplay}
          itemWidth={carouselItemWidth}
          carouselHeight={carouselItemHeight}
          isCircular={false}
          showNextButtons={width > mobile}
          indicatorVariant={width > fullHd ? 'overlay-right' : 'default'}
          arrowType='highlight'
        />
      </S.Content>

      <Spacer height={width < mobile ? 40 : 80} />
    </S.Container>
  );
}

export default LastEdition;
