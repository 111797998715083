import { format } from 'date-fns';

const FORMATDEFAULT = 'dd/MM/yyyy';

export const formatDate = (date: string, FORMAT = FORMATDEFAULT): string => {
  return format(new Date(date), FORMAT);
};

const onlyNumbers = (data: string) => data?.replace(/[^0-9]/g, '');

const removeSpace = (value: string | number): string => {
  const stringValue = `${value || ''}`;

  return stringValue?.replace(/\s/g, '') || '';
};

const Formatter = {
  formatDate,
  removeSpace,
  onlyNumbers,
};

export { Formatter };
