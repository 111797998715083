import styled from 'styled-components';

import OurValuesUri from 'assets/images/about/our-values.webp';

import TextBase, { TextStyleType } from 'components/Text';

export const Container = styled.section`
  flex-direction: column;

  align-items: center;
  justify-content: center;

  width: 100%;

  background-color: ${({ theme }) => theme.palette.primary.background};
`;

export const ResponsiveContainer = styled.div`
  flex-direction: row;

  width: 100%;
  height: 100%;

  padding-top: 40px;
  padding-bottom: 60px;

  max-width: 1180px;

  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    flex-direction: column-reverse;
    padding-bottom: 40px;
  }
`;

export const Column = styled.div`
  width: 100%;

  flex-direction: column;
  justify-content: center;
`;

export const LinesColumn = styled.div`
  width: 100%;

  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;

  padding-right: 60px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding-right: 0;
  }
`;

export const Row = styled.div`
  width: 100%;
  flex-wrap: nowrap;
  align-items: flex-start;
`;

export const ImageContainer = styled.div`
  flex-direction: column;

  align-items: center;
  justify-content: center;

  width: 100%;

  align-self: center;
`;

export const OurValuesImage = styled.img.attrs(() => {
  return {
    src: OurValuesUri,
    alt: '',
  };
})`
  width: 100%;
  object-fit: cover;
  max-width: 560px;
  max-height: 604px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);

    max-height: 368px;
    width: 756px;

    max-width: 100%;

    padding-left: 14px;
    padding-right: 14px;
  }
`;

export const Title = styled(TextBase).attrs({
  type: TextStyleType.TopPageTitleAbout,
})`
  padding-top: 40px;
  padding-left: 14px;

  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const Number = styled(TextBase).attrs({
  type: TextStyleType.NumberEntryAboutH2,
})`
  padding-left: 20px;
  padding-right: 10px;
`;

export const Text = styled(TextBase).attrs({
  type: TextStyleType.BodyParagraph,
})`
  color: ${({ theme }) => theme.palette.secondary.main};

  padding-bottom: 20px;
  padding-right: 14px;
`;

export const OurValuesContainer = styled.div`
  flex-direction: column;
  width: 100%;

  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding-top: 20px;

    margin-left: 24px;
    margin-right: 24px;
  }
`;
