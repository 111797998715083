import styled from 'styled-components';

export const Image = styled.img`
  width: 100%;
  height: 100%;

  object-fit: cover;
`;

export const Content = styled.div`
  width: 574px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.fullHd - 1}px`}) {
    width: 332px;
  }
`;
