import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';

import Store from 'contexts/Store';

import { useTranslation } from 'react-i18next';

import JoinUs from './JoinUs';
import WhatWeDo from './WhatWeDo';
import WebMobile from './WebMobile';
import DataEngineer from './DataEngineer';
import UxUi from './UxUi';

import * as S from './styles';

function Services() {
  const { setData } = Store.useStore();

  const { t: translator } = useTranslation();

  useEffect(() => {
    setData({ index: 2 });

    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Container>
      <Helmet>
        <title>{translator('services.pageTitle')}</title>

        <meta name='description' content={translator('services.pageMetaTagDescription')} />
      </Helmet>

      <WhatWeDo />
      <WebMobile />
      <DataEngineer />
      <UxUi />
      <JoinUs />
    </S.Container>
  );
}

export default Services;
