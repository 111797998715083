import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;

  padding: 80px 0;

  justify-content: center;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding: 40px 0;
  }
`;

export const Message = styled.h6`
  width: 100%;

  color: ${({ theme }) => theme.palette.neutral.main};

  text-align: center;
`;
