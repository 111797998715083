import styled from 'styled-components';

import EmailIconUri from 'assets/icons/email.svg';
import PhoneIconUri from 'assets/icons/phone.svg';
import MapIconUri from 'assets/icons/job-blue.svg';

import ArrowIconUri from 'assets/icons/chevron-right-blue.svg';

export const Column = styled.div`
  width: 100%;

  flex-direction: column;
  justify-content: center;
`;

export const Row = styled.div`
  width: 100%;

  flex-wrap: nowrap;

  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    justify-content: flex-start;
  }
`;

export const ButtonsRow = styled.div`
  width: 100%;

  flex-wrap: nowrap;

  align-items: center;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  padding: 8px 2px;

  border-radius: 8px;

  justify-content: space-between;
  align-items: center;

  width: fit-content;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: 100%;

    padding: 16px 0;
  }

  :hover {
    cursor: pointer;

    transition: opacity 0.3s linear;
    background-color: ${({ theme }) => theme.palette.primary.background};
  }
`;

export const Link = styled.a`
  width: 100%;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    justify-content: space-between;
  }
`;

export const EmailIcon = styled.img.attrs(() => {
  return {
    src: EmailIconUri,
    alt: '',
  };
})`
  width: 30px;
  height: 21px;
  margin-right: 16px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: 21px;
    height: 21px;
  }
`;
export const PhoneIcon = styled.img.attrs(() => {
  return {
    src: PhoneIconUri,
    alt: '',
  };
})`
  width: 31.5px;
  height: 28.13px;
  margin-right: 16px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: 21px;
    height: 21px;
  }
`;
export const MapIcon = styled.img.attrs(() => {
  return {
    src: MapIconUri,
    alt: '',
  };
})`
  width: 30.6px;
  height: 30.6px;
  margin-right: 16px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: 21px;
    height: 21px;
  }
`;

export const ArrowIcon = styled.img.attrs(() => {
  return {
    src: ArrowIconUri,
    alt: '',
  };
})`
  margin-right: 10px;
  width: 8px;
  height: 14px;
`;
