import React from 'react';

import theme from 'styles/theme';

import { useTranslation } from 'react-i18next';

import { TextStyleType, TextAlign } from 'components/Text';

import * as S from './styles';

interface SuccessIconProps {
  label: string;
}

function SuccessIcon({ label }: SuccessIconProps) {
  const { t: translator } = useTranslation();

  return (
    <S.Container>
      <S.Image alt={translator('rubcamp.timeLine.finalIconAlt')} />

      <S.Text type={TextStyleType.TopPageTitle} textAlign={TextAlign.Center} color={theme.palette.neutral.main}>
        {label}
      </S.Text>
    </S.Container>
  );
}

export default SuccessIcon;
