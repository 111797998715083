import React from 'react';

import useWindowDimensions from 'hooks/window-dimensions';
import { useTranslation } from 'react-i18next';

import defaultTheme from 'styles/theme';

import Spacer from 'components/Spacer';

import { TextHighlight } from 'components/Text/styles';

import Line from './Line';

import * as S from './styles';

function OurValues() {
  const { t: translator } = useTranslation();

  const { fullHd } = defaultTheme.screensSize;
  const { width } = useWindowDimensions();

  const LineTexts = [
    <S.Text>
      <TextHighlight>{translator('about.ourValues.line1.highlight1')}</TextHighlight>
      {translator('about.ourValues.line1.text1')}
    </S.Text>,
    <S.Text>
      <TextHighlight>{translator('about.ourValues.line2.highlight1')}</TextHighlight>
      {translator('about.ourValues.line2.text1')}
    </S.Text>,
    <S.Text>
      <TextHighlight>{translator('about.ourValues.line3.highlight1')}</TextHighlight>
      {translator('about.ourValues.line3.text1')}
      <TextHighlight>{translator('about.ourValues.line3.highlight2')}</TextHighlight>
      {translator('about.ourValues.line3.text2')}
    </S.Text>,
    <S.Text>
      <TextHighlight>{translator('about.ourValues.line4.highlight1')}</TextHighlight>
      {translator('about.ourValues.line4.text1')}
      <TextHighlight>{translator('about.ourValues.line4.highlight2')}</TextHighlight>
      {translator('about.ourValues.line4.text2')}
      <TextHighlight>{translator('about.ourValues.line4.highlight3')}</TextHighlight>
      {translator('about.ourValues.line4.text3')}
    </S.Text>,
    <S.Text>
      <TextHighlight>{translator('about.ourValues.line5.highlight1')}</TextHighlight>
      {translator('about.ourValues.line5.text1')}
      <TextHighlight>{translator('about.ourValues.line5.highlight2')}</TextHighlight>
      {translator('about.ourValues.line5.text2')}
    </S.Text>,
  ];

  return (
    <S.Container id='our-values'>
      <S.OurValuesContainer>
        <S.ResponsiveContainer>
          <S.LinesColumn>
            <S.Column>
              <S.Title>{translator('about.ourValues.title')}</S.Title>

              <Spacer height={width < fullHd ? 20 : 40} />

              <S.Column>
                <Line number='01' styledText={LineTexts[0]} />
                <Line number='02' styledText={LineTexts[1]} />
                <Line number='03' styledText={LineTexts[2]} />
                <Line number='04' styledText={LineTexts[3]} />
                <Line number='05' styledText={LineTexts[4]} />
              </S.Column>
            </S.Column>
          </S.LinesColumn>

          <S.ImageContainer>
            <S.OurValuesImage />
          </S.ImageContainer>
        </S.ResponsiveContainer>
      </S.OurValuesContainer>
    </S.Container>
  );
}

export default OurValues;
