import styled from 'styled-components';

import TextBase from 'components/Text';

export const Row = styled.div`
  width: 100%;
  margin: 10px 0;

  justify-content: flex-start;
  align-items: center;
`;

export const Icon = styled.img`
  width: 48px;
  height: 48px;

  margin-right: 16px;

  ::-moz-selection {
    background: ${({ theme }) => theme.palette.mono.main};
  }

  ::selection {
    background: ${({ theme }) => theme.palette.mono.main};
  }
`;

export const Label = styled(TextBase)`
  ::-moz-selection {
    color: ${({ theme }) => theme.palette.primary.main};
    background: ${({ theme }) => theme.palette.mono.main};
  }

  ::selection {
    color: ${({ theme }) => theme.palette.primary.main};
    background: ${({ theme }) => theme.palette.mono.main};
  }
`;
