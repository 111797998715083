import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';

import Store from 'contexts/Store';

import { useTranslation } from 'react-i18next';

import NewTalents from './NewTalents';
import Jobs from './Jobs';

import * as S from './styles';

function Career() {
  const { setData } = Store.useStore();

  const { t: translator } = useTranslation();

  useEffect(() => {
    setData({ index: 4 });

    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Container>
      <Helmet>
        <title>{translator('career.pageTitle')}</title>

        <meta name='description' content={translator('career.pageMetaTagDescription')} />
      </Helmet>

      <NewTalents />
      <Jobs />
    </S.Container>
  );
}

export default Career;
