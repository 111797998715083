import styled from 'styled-components';

export const Container = styled.div`
  width: 235px;
  flex-direction: column;
  align-items: center;
  margin: 20px 10px;
  min-width: 235px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: calc(100% - 40px);

    width: 100%;
    margin: 0;
  }
`;

export const Logo = styled.img`
  width: 100%;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: 100%;
    height: 200px;
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.palette.secondary.main};
`;
