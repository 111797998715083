import styled from 'styled-components';

export const Container = styled.div``;

export const Flag = styled.img<{ isSelected: boolean }>`
  width: 25px;
  height: 25px;

  cursor: pointer;

  filter: ${({ isSelected }) => (isSelected ? 'none' : 'grayscale(100%)')};
  opacity: ${({ isSelected }) => (isSelected ? '1' : '0.7')};

  :hover {
    filter: none;

    opacity: 0.8;
  }
`;

export const Separator = styled.div`
  width: 6px;
`;
