export const availableJobs = [
  {
    name: 'staticFiles.jobs.job4.name',
    badges: ['Scrum', 'Jira', 'Visão estratégica'],
    location: 'staticFiles.jobs.job4.location',
    form: 'https://docs.google.com/forms/d/e/1FAIpQLSfw1m7tncvPV1IdZDxhoamFS9RB15tAr_ySbEDpBeOiU-famA/viewform?usp=sf_link',
  },
  {
    name: 'staticFiles.jobs.job1.name',
    badges: ['React Native', 'Typescript', 'RESTful APIs'],
    location: 'staticFiles.jobs.job1.location',
    form: 'https://docs.google.com/forms/d/e/1FAIpQLSej4ss6jKvy9eQvKNPuNVcHnTY51uMcAaCU_0h1O6pCJm6Nyw/viewform',
  },
  {
    name: 'staticFiles.jobs.job2.name',
    badges: ['Node.js', 'Typescript', 'RESTful APIs'],
    location: 'staticFiles.jobs.job2.location',
    form: 'https://docs.google.com/forms/d/e/1FAIpQLScNtrHfmJjLFHbpnSdgDqk82-zfqmeIzgvm_SPN_5eR81dTAg/viewform',
  },
  {
    name: 'staticFiles.jobs.job3.name',
    badges: ['Python', 'SQL', 'ETL'],
    location: 'staticFiles.jobs.job3.location',
    form: 'https://docs.google.com/forms/d/e/1FAIpQLSdAkAYIXJ_PtKj4zooZyCuX5768T2nl8YU3X64lJI4h-meJIw/viewform',
  },
];
