import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: column;

  align-items: center;
  justify-content: center;

  width: 100%;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    min-width: 100%;
  }
`;

export const Column = styled.div`
  position: relative;

  flex-direction: column;
`;
