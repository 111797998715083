/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useEffect, useState, useRef } from 'react';

import useWindowDimensions from 'hooks/window-dimensions';

import defaultTheme from 'styles/theme';

import DragToScroll from 'components/Carousel/DragToScroll';

import Spacer from 'components/Spacer';
import { Indicator, IndicatorEntry } from './Indicator';

import * as S from './styles';

type IndicatorVariant = 'none' | 'default' | 'overlay' | 'overlay-right';
export type ArrowType = 'default' | 'highlight';

interface CarouselProps {
  carouselHeight: number;
  itemWidth: number;
  items: ReactNode[];
  itemsPerPage?: number;
  showNextButtons?: boolean;
  isCircular?: boolean;
  isInfinite?: boolean;
  indicatorVariant?: IndicatorVariant;
  autoScroll?: boolean;
  autoScrollIntervalSeconds?: number;
  onChangeSlide?: (i: number) => void;
  itemsKeyConstant?: string;
  arrowType?: ArrowType;
}

function Carousel({
  carouselHeight,
  itemWidth,
  items,
  itemsPerPage = 1,
  showNextButtons = false,
  isCircular = true,
  isInfinite = false,
  autoScroll = false,
  autoScrollIntervalSeconds = 4,
  indicatorVariant = 'default',
  onChangeSlide,
  itemsKeyConstant = 'CAR',
  arrowType = 'default',
}: CarouselProps) {
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState<number>(0);

  const [applyAutoScroll, setApplyAutoScroll] = useState<boolean>(false);
  const [applySmoothBehavior, setApplySmoothBehavior] = useState<boolean>(true);

  const ref: any = useRef();

  const nextSlide = () => {
    if (onChangeSlide) {
      onChangeSlide(currentSelectedIndex === items.length - 1 ? 0 : currentSelectedIndex + 1);
    }

    if (isInfinite || isCircular) setCurrentSelectedIndex(currentSelectedIndex === items.length - 1 ? 0 : currentSelectedIndex + 1);
    else if (currentSelectedIndex !== items.length - 1) setCurrentSelectedIndex(currentSelectedIndex + 1);

    ref?.current.scrollTo({
      left: ref.current.scrollLeft + itemWidth,
      behavior: 'smooth',
    });
  };
  const prevSlide = () => {
    if (onChangeSlide) {
      onChangeSlide(currentSelectedIndex === 0 ? items.length - 1 : currentSelectedIndex - 1);
    }
    if (isInfinite || isCircular) setCurrentSelectedIndex(currentSelectedIndex === 0 ? items.length - 1 : currentSelectedIndex - 1);
    else if (currentSelectedIndex !== 0) setCurrentSelectedIndex(currentSelectedIndex - 1);

    ref?.current.scrollTo({
      left: ref.current.scrollLeft - itemWidth,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (onChangeSlide) {
      onChangeSlide(currentSelectedIndex);
    }
  }, [currentSelectedIndex]);

  useEffect(() => {
    if (autoScroll) {
      setApplySmoothBehavior(false);

      setTimeout(function f() {
        try {
          nextSlide();

          setApplyAutoScroll(!applyAutoScroll);
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }, autoScrollIntervalSeconds * 1000);
    }
  }, [applyAutoScroll]);

  const indicatorData: IndicatorEntry[] = [];

  const { width } = useWindowDimensions();
  const { mobile } = defaultTheme.screensSize;

  return (
    <S.Column>
      <S.Content width={itemsPerPage * itemWidth}>
        {showNextButtons && <S.ArrowLeft onClick={prevSlide} arrowStyle={arrowType} />}

        <DragToScroll
          isInfinite={isInfinite}
          itemWidth={itemWidth}
          itemsCountAfterTriplicate={items.length * 3}
          onChangeSlide={setCurrentSelectedIndex}
          setApplySmoothBehavior={setApplySmoothBehavior}
          componentToScrollRef={ref}
        >
          <S.ScrollableContainer
            applySmoothBehavior={applySmoothBehavior}
            ref={ref}
            height={carouselHeight}
            width={itemsPerPage * itemWidth}
          >
            {items.map((item: ReactNode, index: number) => {
              const entry = {
                onPress: () => {
                  if (onChangeSlide) {
                    onChangeSlide(index);
                  }

                  ref.current.scrollTo({
                    left: itemWidth * index,
                    behavior: 'smooth',
                  });
                  setCurrentSelectedIndex(index);
                },
                isEnabled: index === currentSelectedIndex,
              };

              indicatorData.push(entry);

              return (
                <S.CarouselItemWrapper
                  draggable='false'
                  // For Firefox:
                  //
                  // onDragStart={(e) => {
                  //   e.preventDefault();
                  // }}
                  key={`${itemsKeyConstant}-1-${index + 1}`}
                >
                  {item}
                </S.CarouselItemWrapper>
              );
            })}

            {isCircular &&
              items.map((item: ReactNode, index: number) => {
                return (
                  <S.CarouselItemWrapper
                    draggable='false'
                    // For Firefox:
                    //
                    // onDragStart={(e) => {
                    //   e.preventDefault();
                    // }}
                    key={`${itemsKeyConstant}-2-${index + 1}`}
                  >
                    {item}
                  </S.CarouselItemWrapper>
                );
              })}

            {isCircular &&
              items.map((item: ReactNode, index: number) => {
                return (
                  <S.CarouselItemWrapper
                    draggable='false'
                    // For Firefox:
                    //
                    // onDragStart={(e) => {
                    //   e.preventDefault();
                    // }}
                    key={`${itemsKeyConstant}-3-${index + 1}`}
                  >
                    {item}
                  </S.CarouselItemWrapper>
                );
              })}
          </S.ScrollableContainer>
        </DragToScroll>

        {showNextButtons && <S.ArrowRight onClick={nextSlide} arrowStyle={arrowType} />}
      </S.Content>

      {indicatorVariant === 'default' && (
        <>
          <Spacer height={width < mobile ? 20 : 40} />

          <S.IndicatorContainer>
            <Indicator data={indicatorData} />
          </S.IndicatorContainer>
        </>
      )}

      {indicatorVariant === 'overlay' && (
        <S.OverlayContainer bottom={50}>
          <S.IndicatorContainer>
            <Indicator data={indicatorData} />
          </S.IndicatorContainer>
        </S.OverlayContainer>
      )}

      {indicatorVariant === 'overlay-right' && (
        <S.OverlayContainer bottom={40} left={250}>
          <S.IndicatorContainer>
            <Indicator data={indicatorData} />
          </S.IndicatorContainer>
        </S.OverlayContainer>
      )}
    </S.Column>
  );
}

export { Carousel };
