import styled from 'styled-components';

import TextBase, { TextStyleType } from 'components/Text';

export const Container = styled.section`
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.mono.main};

  justify-content: center;
  align-items: center;

  position: relative;
`;

export const OurHistoryContainer = styled.div`
  width: 100%;

  justify-content: center;
  align-items: center;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const HorizontalSpacer = styled.div`
  flex: 1;
  width: 100%;
  height: 0;

  max-width: 250px;
`;

export const TimeLineContainer = styled.div`
  width: 100%;
  height: 100%;

  padding: 0 60px 0 60px;

  justify-content: center;
  align-items: center;

  flex-direction: column;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding: 14px;
    padding-left: 0;

    margin-left: 14px;
    padding-bottom: 0;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 2px;

  margin: 0 !important;
  padding: 0 !important;

  background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const ResponsiveContainer = styled.div`
  flex-direction: row;

  width: 100%;
  height: 100%;

  max-width: 1180px;

  align-items: center;
  justify-content: center;

  align-self: center;
`;

export const Column = styled.div`
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
`;

export const SuperiorRow = styled.div`
  width: 100%;
  flex-wrap: nowrap;
  align-items: flex-end;
`;

export const SuperiorColumn = styled.div`
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;

  justify-content: flex-start;
  align-items: center;

  flex: 1;

  height: 100%;
`;

export const InferiorRow = styled.div`
  width: 100%;
  flex-wrap: nowrap;
  align-items: flex-start;
`;

export const Row = styled.div`
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
`;

export const TextContainer = styled.div`
  flex-wrap: nowrap;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding: 0;
  }
`;

export const Title = styled(TextBase).attrs({
  type: TextStyleType.TopPageTitleAbout,
})`
  padding: 80px 0 60px 0;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding: 40px 20px;
  }

  color: ${({ theme }) => theme.palette.secondary.main};
`;
