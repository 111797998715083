import React, { ReactNode } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CarouselImage01Desktop from 'assets/images/home/banner-01-desktop.webp';
import CarouselImage01Mobile from 'assets/images/home/banner-01-mobile.webp';

import CarouselImage02Desktop from 'assets/images/home/banner-02-desktop.webp';
import CarouselImage02Mobile from 'assets/images/home/banner-02-mobile.webp';

import useWindowDimensions from 'hooks/window-dimensions';

import defaultTheme from 'styles/theme';

import { Carousel } from 'components/Carousel';
import { BannerEntry } from 'components/BannerEntry';

import { HOMEPAGE_SCREENS, RUBCAMP_SCREENS } from 'routes/screen-definitions';

import * as S from './styles';

function MainBanner() {
  const navigate = useNavigate();
  const { t: translator } = useTranslation();

  const { width, height } = useWindowDimensions();
  const { mobile } = defaultTheme.screensSize;

  const carouselItemWidth = width;
  const carouselItemHeight = height;

  const carouselContent: ReactNode[] = [
    <BannerEntry
      imageUrl={width < mobile ? CarouselImage01Mobile : CarouselImage01Desktop}
      title={translator('home.mainBanner.banner1.title')}
      text={translator('home.mainBanner.banner1.text')}
      isLarge={false}
      buttonOnPress={() => navigate(`${HOMEPAGE_SCREENS.home}${HOMEPAGE_SCREENS.rublabs}`)}
    />,
    <BannerEntry
      imageUrl={width < mobile ? CarouselImage02Mobile : CarouselImage02Desktop}
      title={translator('home.mainBanner.banner2.title')}
      text={translator('home.mainBanner.banner2.text')}
      isLarge
      buttonOnPress={() => window.open(`${RUBCAMP_SCREENS.home}`, '_blank')?.focus()}
    />,
  ];

  return (
    <S.Container>
      <S.Column>
        <Carousel
          items={carouselContent}
          carouselHeight={carouselItemHeight - 72}
          itemWidth={carouselItemWidth}
          indicatorVariant='overlay'
        />
      </S.Column>
    </S.Container>
  );
}

export default MainBanner;
