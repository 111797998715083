import styled from 'styled-components';

import TextBase, { TextStyleType } from 'components/Text';

import ArrowButtonBase from 'components/ArrowButton';

export const Form = styled.form`
  width: 100%;

  flex-direction: column;
  justify-content: center;
`;

export const Input = styled.input<{ error: boolean; noError: boolean }>`
  width: 100%;
  margin: 12px 0;

  color: ${({ theme }) => theme.palette.mono.main};
  border-bottom: 1px solid
    ${({ theme, error, noError }) => {
      if (error) {
        return theme.palette.contrast.main;
      }
      if (noError) {
        return theme.palette.primary.main;
      }

      return theme.palette.mono.main;
    }};
`;

export const TextInput = styled.textarea<{ error: boolean; noError: boolean }>`
  height: 230px;
  margin: 20px 0;

  color: ${({ theme }) => theme.palette.mono.main};
  border-bottom: 1px solid
    ${({ theme, error, noError }) => {
      if (error) {
        return theme.palette.contrast.main;
      }
      if (noError) {
        return theme.palette.primary.main;
      }

      return theme.palette.mono.main;
    }};
`;

export const Separator = styled.div`
  width: 50px;
`;

export const Row = styled.div`
  flex-direction: row;
  justify-content: flex-end;

  @media (max-width: ${({ theme }) => `${theme.screensSize.hd}px`}) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const Column = styled.div`
  width: 50%;
  position: relative;
  flex: 4;
  flex-direction: column;

  @media (max-width: ${({ theme }) => `${theme.screensSize.fullHd}px`}) {
    padding: 30px;
  }

  @media (max-width: ${({ theme }) => `${theme.screensSize.hd}px`}) {
    width: 100%;
    padding: 0;
  }
`;

export const Title = styled(TextBase).attrs({
  type: TextStyleType.SectionTitle,
})`
  color: ${({ theme }) => theme.palette.primary.main};

  max-width: 550px;
  white-space: normal;
`;

export const SubTitle = styled(TextBase).attrs({
  type: TextStyleType.CardJoinUsText,
})`
  white-space: normal;

  color: ${({ theme }) => theme.palette.mono.main};
  max-width: 550px;
  margin-top: 20px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.hd}px`}) {
    display: none;
  }
`;

export const Gap = styled.div`
  width: 60px;
  height: 100%;
`;

export const ArrowButton = styled(ArrowButtonBase)``;
