import React from 'react';

import { useTranslation } from 'react-i18next';
import WhatIsImage from 'assets/images/rublabs/what-is.webp';
import ImageText from 'components/ImageText';

import Text, { TextStyleType } from 'components/Text';

import * as S from './styles';

function WhatIs() {
  const { t: translator } = useTranslation();

  return (
    <S.Container>
      <ImageText flipImage image={WhatIsImage} alt={translator('rubLabs.whatIs.alt')} title={translator('rubLabs.whatIs.title')}>
        <Text type={TextStyleType.BodyParagraph}>
          {translator('rubLabs.whatIs.text1')}
          <Text type={TextStyleType.Highlight}>{translator('rubLabs.whatIs.highlight1')}</Text>
          {translator('rubLabs.whatIs.text2')}
          <Text type={TextStyleType.Highlight}>{translator('rubLabs.whatIs.highlight2')}</Text>
          {translator('rubLabs.whatIs.text3')}
          <Text type={TextStyleType.Highlight}>{translator('rubLabs.whatIs.highlight3')}</Text>
          {translator('rubLabs.whatIs.text4')}
          <Text type={TextStyleType.Highlight}>{translator('rubLabs.whatIs.highlight4')}</Text>
          {translator('rubLabs.whatIs.text5')}
          <Text type={TextStyleType.Highlight}>{translator('rubLabs.whatIs.highlight5')}</Text>
          {translator('rubLabs.whatIs.text6')}
        </Text>
      </ImageText>
    </S.Container>
  );
}

export default WhatIs;
