import React from 'react';

import { TextStyleType } from 'components/Text';
import defaultTheme from 'styles/theme';

import * as S from './styles';

interface RowItemProps {
  icon: string;
  text: string;
}

function RowItem({ icon, text }: RowItemProps) {
  return (
    <S.Row>
      <S.Icon alt='' src={icon} />

      <S.Label type={TextStyleType.RowItem} color={defaultTheme.palette.mono.main}>
        {text}
      </S.Label>
    </S.Row>
  );
}

export default RowItem;
