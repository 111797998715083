import React from 'react';

import { useTranslation } from 'react-i18next';

import * as S from './styles';

interface MessageSentProps {
  onPressedNewMessage: () => void;
}

function MessageSent({ onPressedNewMessage }: MessageSentProps) {
  const { t: translator } = useTranslation();

  return (
    <S.Container>
      <S.Logo alt={translator('components.cardContact.messageSent.alt')} />

      <S.Title>{translator('components.cardContact.messageSent.title')}</S.Title>

      <S.Description>
        {translator('components.cardContact.messageSent.description')}
        <S.Button onClick={onPressedNewMessage}>
          <S.Highlight>{translator('components.cardContact.messageSent.highlight')}</S.Highlight>
        </S.Button>
      </S.Description>
    </S.Container>
  );
}

export default MessageSent;
