import styled from 'styled-components';

export const Container = styled.section`
  flex-direction: column;

  align-items: center;
  justify-content: center;

  width: 100%;

  background-color: ${({ theme }) => theme.palette.mono.background};
`;

export const Content = styled.div`
  flex-direction: column;

  width: 100%;
  max-width: 1208px;

  padding: 80px 14px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding: 40px 14px;
  }
`;

export const ItemsContainer = styled.div`
  width: 100%;

  justify-content: space-between;
  flex-wrap: wrap;

  margin-top: 20px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    flex-direction: column;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;

  background-color: ${({ theme }) => theme.palette.neutral.main};
`;
