import React from 'react';

import { useTranslation } from 'react-i18next';

import UxUiImage from 'assets/images/features-cards/ux-ui.webp';
import ImageText from 'components/ImageText';

import * as S from './styles';

function UxUi() {
  const { t: translator } = useTranslation();

  return (
    <S.Container>
      <ImageText
        image={UxUiImage}
        alt={translator('services.uiUx.alt')}
        title={translator('services.uiUx.title')}
        subtitle={translator('services.uiUx.subtitle')}
        text={translator('services.uiUx.text')}
      />
    </S.Container>
  );
}

export default UxUi;
