import React from 'react';

import { useTranslation } from 'react-i18next';
import defaultTheme from 'styles/theme';
import useWindowDimensions from 'hooks/window-dimensions';

import Spacer from 'components/Spacer';
import CardContact from 'components/CardContact';

import * as S from './styles';

function ContactUs() {
  const { t: translator } = useTranslation();

  const { width } = useWindowDimensions();
  const { hd } = defaultTheme.screensSize;

  return (
    <S.Container>
      <Spacer height={width < hd ? 40 : 80} />

      <CardContact title={translator('home.contactUs.title')} text={translator('home.contactUs.text')} />

      <Spacer height={width < hd ? 40 : 80} />
    </S.Container>
  );
}

export default ContactUs;
