import styled from 'styled-components';

export const Container = styled.section`
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary.background};
  padding: 0 14px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    align-items: flex-start;
  }
`;

export const Content = styled.div`
  flex-direction: row;
  justify-content: space-evenly;

  width: 100%;
  max-width: 1300px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    flex-direction: column;
  }
`;

export const SubtitleContainer = styled.div`
  width: 100%;
  max-width: 600px;
`;
