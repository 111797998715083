import React from 'react';

import { useNavigate } from 'react-router-dom';

import bannerImageDesktopUri from 'assets/images/rubcamp/banner-principal-desktop.webp';
import bannerImageMobileUri from 'assets/images/rubcamp/banner-principal-mobile.webp';

import { useTranslation } from 'react-i18next';
import useWindowDimensions from 'hooks/window-dimensions';

import defaultTheme from 'styles/theme';

import { SectionsId } from 'pages/Rubcamp/sectionIds';

import SubscribeButton from 'components/SubscribeButton';

import { BannerEntry } from 'components/BannerEntry';

import { HOMEPAGE_SCREENS } from 'routes/screen-definitions';

import * as S from './styles';

function MainBanner() {
  const { t: translator } = useTranslation();

  const navigate = useNavigate();

  const { width } = useWindowDimensions();
  const { mobile } = defaultTheme.screensSize;

  return (
    <S.Container id={SectionsId.Start}>
      <S.Content>
        <S.Column>
          <S.ImageContainer>
            <BannerEntry
              imageUrl={width < mobile ? bannerImageMobileUri : bannerImageDesktopUri}
              title={translator('rubcamp.mainBanner.title')}
              text={translator('rubcamp.mainBanner.text')}
              isLarge={false}
              customButton={<SubscribeButton label={translator('rubcamp.mainBanner.buttonLabel')} />}
              buttonOnPress={() => navigate(`${HOMEPAGE_SCREENS.home}${HOMEPAGE_SCREENS.rublabs}`)}
            />
          </S.ImageContainer>
        </S.Column>
      </S.Content>
    </S.Container>
  );
}

export default MainBanner;
