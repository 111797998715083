import styled from 'styled-components';

import JobIcon from 'assets/icons/job.svg';
import ArrowRightWhite from 'assets/icons/arrow-right-white.svg';
import ChevronRightBlue from 'assets/icons/chevron-right-blue.svg';

export const Container = styled.div`
  width: calc(100% - 40px);

  align-items: center;
  justify-content: center;

  margin: 20px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    margin: 0;
    padding-right: 8px;

    width: 100%;

    cursor: pointer;
  }
`;

export const Column = styled.div`
  flex-direction: column;
  width: fit-content;
`;

export const Row = styled.div`
  width: 100%;
  align-items: center;
`;

export const RowLabel = styled.div`
  width: 100%;

  align-items: center;
  margin-bottom: 4px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.hd}px`}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const RowBadges = styled.div`
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
`;

export const Spacer = styled.div`
  width: 10px;
  flex: 1;
`;

export const Icon = styled.img.attrs({
  alt: '',
  src: JobIcon,
})`
  width: 26px;
  margin-right: 12px;
`;

export const Badge = styled.div`
  width: fit-content;
  border-radius: 4px;

  background-color: #e1e4fa;

  margin-left: 12px;
  margin-bottom: 4px;
  padding: 4px 8px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.hd}px`}) {
    margin: 4px 12px 4px 0px;
  }
`;

export const Button = styled.button`
  width: fit-content;

  align-items: center;
  justify-content: center;

  padding: 2px 8px;

  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 8px;

  :hover {
    transition: opacity 0.3s linear;
    opacity: 0.6;
  }

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    border-radius: 0;
    padding: 4px;
    background-color: transparent;
  }
`;

export const ArrowIcon = styled.img.attrs({
  alt: '',
  src: ArrowRightWhite,
})`
  width: 16px;
  margin-left: 8px;
`;

export const ChevronIcon = styled.img.attrs({
  alt: '',
  src: ChevronRightBlue,
})`
  width: 12px;
`;
