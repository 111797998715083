import styled from 'styled-components';

import TextBase, { TextStyleType } from 'components/Text';

export const Container = styled.section`
  width: 100%;
  height: fit-content;

  flex-direction: column;
  align-items: center;

  background-color: ${({ theme }) => theme.palette.secondary.background};

  padding: 0 14px;
`;

export const Content = styled.div`
  width: auto;

  @media (max-width: ${({ theme }) => `${theme.screensSize.hd}px`}) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  width: 100%;

  flex-direction: column;
  justify-content: space-evenly;

  margin-right: 60px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.fullHd}px`}) {
    align-items: center;
    margin-right: 20px;
  }

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    align-items: flex-start;
    margin-right: 0;
  }
`;

export const Row = styled.div`
  width: 100%;
  max-width: 1180px;
`;

export const DefaultColumn = styled.div`
  width: 100%;

  flex-direction: column;
  flex-wrap: nowrap;
`;

export const TextParagraph = styled(TextBase).attrs(({ theme }) => {
  return { type: TextStyleType.BodyParagraph, color: theme.palette.secondary.main };
})`
  max-width: 600px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.fullHd}px`}) {
    text-align: center;
  }

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    text-align: start;
  }
`;

export const Image = styled.img`
  width: 100%;

  position: absolute;

  left: 0px;
  top: 0px;

  z-index: 0;

  object-fit: cover;
  object-position: 80% 0;

  @media (max-width: ${({ theme }) => `${theme.screensSize.smallMobile}px`}) {
    object-position: 0 90%;
    height: 220px;
  }
`;
