import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';

import { useTranslation } from 'react-i18next';

import Store from 'contexts/Store';

import Contact from './Contact';
import JoinUs from './JoinUs';

import * as S from './styles';

function ContactUs() {
  const { setData } = Store.useStore();

  const { t: translator } = useTranslation();

  useEffect(() => {
    setData({ index: 5 });

    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Container itemScope itemType='http://schema.org/ContactPage'>
      <Helmet>
        <title>{translator('contactUs.pageTitle')}</title>
      </Helmet>

      <Contact />
      <JoinUs />
    </S.Container>
  );
}

export default ContactUs;
