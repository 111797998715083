import styled from 'styled-components';

export const Container = styled.section`
  flex-direction: column;
  align-items: center;
  width: 100%;

  background-color: ${({ theme }) => theme.palette.mono.main};

  padding: 80px 14px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding: 40px 14px;
  }
`;

export const Content = styled.div`
  flex-direction: column;

  width: 100%;
  max-width: 1208px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;

  margin: 20px 0;

  background-color: ${({ theme }) => theme.palette.neutral.main};

  opacity: 0.5;
`;
