import React, { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import ArrowButton from 'components/ArrowButton';

import * as S from './styles';

interface BannerEntryProps {
  imageUrl: string;
  title: string;
  text: string;
  isLarge: boolean;
  buttonOnPress: () => void;
  customButton?: ReactNode;
  showKnowMoreButton?: boolean;
}

function BannerEntry({ imageUrl, title, text, isLarge, buttonOnPress, customButton, showKnowMoreButton = true }: BannerEntryProps) {
  const { t: translator } = useTranslation();

  return (
    <S.Column imageUri={imageUrl}>
      <S.Title>{title}</S.Title>

      <S.IsLargeContainer isLarge={isLarge}>
        <S.Text>{text}</S.Text>
      </S.IsLargeContainer>

      {customButton || (showKnowMoreButton && <ArrowButton label={translator('home.mainBanner.buttonLabel')} onPressed={buttonOnPress} />)}
    </S.Column>
  );
}

export { BannerEntry };
