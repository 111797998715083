import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';

import Store from 'contexts/Store';

import { useTranslation } from 'react-i18next';

import OurMission from './OurMission';
import OurVision from './OurVision';
import OurValues from './OurValues';
import JoinUs from './JoinUs';
import OurHistory from './OurHistory';
// import OurLeadership from './OurLeadership';

import * as S from './styles';

function AboutUs() {
  const { setData } = Store.useStore();

  const { t: translator } = useTranslation();

  useEffect(() => {
    setData({ index: 1 });

    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Container itemScope itemType='http://schema.org/AboutPage'>
      <Helmet>
        <title>{translator('about.pageTitle')}</title>

        <meta name='description' content={translator('about.pageMetaTagDescription')} />
      </Helmet>

      <OurMission />
      <OurVision />
      <OurValues />
      {/* <OurLeadership /> */}
      <OurHistory />
      <JoinUs />
    </S.Container>
  );
}

export default AboutUs;
