import styled from 'styled-components';

import TextBase, { TextStyleType } from 'components/Text';

import LogoSuccessUri from 'assets/icons/timeline-success.svg';

export const Container = styled.div`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 48px 0;

  @media (max-width: ${({ theme }) => `${theme.screensSize.hd}px`}) {
    padding: 100px 0;
  }
`;

export const Logo = styled.img.attrs(() => {
  return {
    src: LogoSuccessUri,
  };
})`
  margin-bottom: 20px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: 50px;
    height: 50px;

    margin-bottom: 10px;
  }
`;

export const Title = styled(TextBase).attrs({
  type: TextStyleType.TitleBannerCta,
})`
  color: ${({ theme }) => theme.palette.primary.main};
  text-align: center;

  margin-bottom: 20px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    margin-bottom: 10px;
  }
`;

export const Description = styled(TextBase).attrs({
  type: TextStyleType.DescriptionBannerCta,
})`
  color: ${({ theme }) => theme.palette.mono.main};
  text-align: center;
`;

export const Button = styled.button`
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
`;

export const Highlight = styled(TextBase).attrs({
  type: TextStyleType.Highlight,
})``;
