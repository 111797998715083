import styled from 'styled-components';

import TextBase, { TextStyleType } from 'components/Text';

import CarouselImage02Desktop from 'assets/images/home/banner-02-desktop.webp';

export const Column = styled.div<{ imageUri: string }>`
  height: 100%;
  width: 100vw;

  flex-direction: column;
  flex-wrap: wrap;

  align-items: flex-start;
  justify-content: flex-start;

  padding-left: 60px;
  padding-top: 80px;

  min-width: 260px;

  background-image: ${({ imageUri }) => `url(${imageUri})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: ${({ imageUri }) => (imageUri === CarouselImage02Desktop ? `0 40%` : `0 80%`)};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    background-position: 80% 0;

    padding-left: 14px;
    padding-right: 14px;
  }

  @media (max-width: ${({ theme }) => `${theme.screensSize.smallMobile}px`}) {
    overflow-y: auto;
  }
`;

export const IsLargeContainer = styled.div<{ isLarge: boolean }>`
  max-width: 780px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.ultraHd}px`}) {
    max-width: ${({ isLarge }) => (isLarge ? '999px' : '837px')};
  }
`;

export const Title = styled(TextBase).attrs(({ theme }) => {
  return { type: TextStyleType.SubtitleBanner, color: theme.palette.mono.main };
})``;

export const Text = styled(TextBase).attrs(({ theme }) => {
  return { type: TextStyleType.TitleBanner, color: theme.palette.mono.main };
})`
  width: 100%;

  margin-bottom: 30px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding-bottom: 20px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;

  object-fit: cover;

  z-index: 10;
`;
