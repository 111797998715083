import styled from 'styled-components';

export const Container = styled.section`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: fit-content;
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;

  @media (max-width: 380px) {
    height: 85vh;
  }

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding: 14px 14px;
  }
`;

export const Column = styled.div`
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
`;
