import React from 'react';

import { useTranslation } from 'react-i18next';
import useWindowDimensions from 'hooks/window-dimensions';

import defaultTheme from 'styles/theme';

import Text, { TextStyleType } from 'components/Text';

import ContactItem from './ContactItem';

import * as S from './styles';

function Contact() {
  const { t: translator } = useTranslation();

  const { mobile } = defaultTheme.screensSize;
  const { width } = useWindowDimensions();

  return (
    <S.Container>
      <S.Content>
        <Text type={TextStyleType.TopPageTitle}>{translator('contactUs.contact.title')}</Text>

        <Text type={TextStyleType.BodyParagraph}>{translator('contactUs.contact.text')}</Text>

        <Text type={TextStyleType.SubtitleContacts}>{translator('contactUs.contact.subtitle')}</Text>

        <S.ItemsContainer>
          {width < mobile && <S.Divider />}
          <ContactItem type='email' />

          {width < mobile && <S.Divider />}
          <ContactItem type='phone' />

          {width < mobile && <S.Divider />}
          <ContactItem type='location' />

          {width < mobile && <S.Divider />}
        </S.ItemsContainer>
      </S.Content>
    </S.Container>
  );
}

export default Contact;
