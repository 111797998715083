import axios, { AxiosInstance } from 'axios';

export type MSApiTypes = 'base';

const API_HOSTS = {
  base: 'https://ed3kwnik2c.execute-api.us-east-1.amazonaws.com/lp',
} as { [key: string]: string };

const instances = {} as { [key: string]: AxiosInstance };
const CreateApi = {
  getApi: (type: MSApiTypes) => {
    if (!instances[type]) {
      Object.keys(API_HOSTS).forEach(function f1(key) {
        instances[key] = axios.create({ baseURL: API_HOSTS[key] });
      });
    }

    return instances[type];
  },
};

export default CreateApi;
