import React from 'react';

import useWindowDimensions from 'hooks/window-dimensions';
import defaultTheme from 'styles/theme';

import * as S from './styles';

function Badge() {
  const { width } = useWindowDimensions();
  const { mobile } = defaultTheme.screensSize;

  return (
    <S.Container>
      <S.Label>Joint Venture Bank Factory</S.Label>
      {width < mobile && <S.Line />}
    </S.Container>
  );
}

export default Badge;
