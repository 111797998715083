import { Formatter } from './formatter';

export type TValidate = [(value: any, form?: any) => any, string];

const isEmpty = (message?: string): TValidate => [
  (value: string) => Formatter.removeSpace(value).length < 3,
  message || 'Campo deve ser preenchido',
];

const isEmail = (message?: string): TValidate => [
  (value) => !`${value}`.match(/^[a-z0-9._-]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i)?.length,
  message || 'Email inválido',
];

export { isEmpty, isEmail };
