import styled from 'styled-components';

export const Container = styled.div<{ isReversed: boolean }>`
  width: 100%;
  height: fit-content;

  position: relative;
  flex-direction: ${({ isReversed }) => (isReversed ? 'row-reverse' : 'row')};

  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding-left: 14px;
    padding-right: 14px;
  }
`;

export const ColumnTexts = styled.div`
  width: 42%;
  padding-bottom: 90px;

  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ColumnIcons = styled.div<{ isReversed: boolean }>`
  width: 42%;
  height: 100%;
  padding-top: 44px;
  padding-bottom: 90px;

  flex-direction: column;
  align-items: ${({ isReversed }) => (isReversed ? 'flex-end' : 'flex-start')};
`;

export const Row = styled.div`
  width: fit-content;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: 100%;
  }
`;

export const LineColumn = styled.div`
  width: 30px;
  height: 100%;

  position: absolute;
  left: calc(50% - 15px);

  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: 25px;
    left: 0;
    margin-top: 16px;
  }
`;

export const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;

  background-color: ${({ theme }) => theme.palette.primary.main};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: 25px;
    height: 25px;
  }
`;

export const Line = styled.div`
  width: 2px;
  height: 100%;

  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

export const SmallLine = styled.div<{ isFirst: boolean }>`
  width: 2px;
  height: 20px;

  background-color: ${({ theme, isFirst }) => (isFirst ? '' : theme.palette.secondary.main)};
`;

export const Icon = styled.img`
  width: 140px;
  height: 140px;

  margin: 20px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.hd}px`}) {
    width: 130px;
    height: 130px;

    margin: 16px;
  }

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: 48px;
    height: 48px;

    margin-left: 0;
  }
`;

export const Column = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-left: 18px;
  margin-bottom: 20px;

  flex-direction: column;
  align-items: flex-start;
`;

export const RowItem = styled.div`
  width: 100%;
  margin: 8px 0;
`;
