import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;

  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.palette.mono.main};
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1180px;
  padding: 80px 14px;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding-top: 40px;
    padding-bottom: 40px;

    align-items: start;
  }
`;

export const TimeLine = styled.div`
  width: 100%;
  margin: 60px 0;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    margin: 10px 0;
  }
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.palette.neutral.main};

  text-align: center;
`;

export const Column = styled.div`
  width: 100%;

  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

export const Row = styled.div`
  width: 100%;
  flex-wrap: nowrap;
`;
