import styled from 'styled-components';

import TextBase, { TextStyleType } from 'components/Text';

export const Container = styled.div`
  width: 100%;
  max-width: 200px;

  flex-direction: column;

  justify-content: center;
  align-items: center;

  @media (max-width: ${({ theme }) => `${theme.screensSize.fullHd}px`}) {
    flex-direction: row;
    justify-content: flex-start;

    max-width: none;
  }
`;

export const Circle = styled.div`
  width: 102px;
  height: 102px;

  display: flex;

  justify-content: center;
  align-items: center;

  border-radius: 64px;

  background-color: ${({ theme }) => theme.palette.primary.main};

  @media (max-width: ${({ theme }) => `${theme.screensSize.fullHd}px`}) {
    width: 82px;
    height: 82px;

    min-width: 82px;
    min-height: 82px;
  }
`;

export const Icon = styled.img`
  width: 50px;
  height: 50px;

  justify-content: center;
  align-items: center;

  @media (max-width: ${({ theme }) => `${theme.screensSize.fullHd}px`}) {
    width: 35px;
    height: 35px;
  }
`;

export const Text = styled(TextBase).attrs({
  type: TextStyleType.SelectiveProcessStepText,
})`
  padding-top: 12px;

  text-align: center;

  color: ${({ theme }) => theme.palette.neutral.main};

  @media (max-width: ${({ theme }) => `${theme.screensSize.fullHd}px`}) {
    padding-top: 0;

    margin-left: 20px;
    text-align: start;
  }
`;
