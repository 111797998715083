import React from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import BasicLayout from 'styles/layout/BasicLayout';
import RubcampPageLayout from 'styles/layout/RubcampPageLayout';

import Home from 'pages/Home';
import AboutUs from 'pages/AboutUs';
import Services from 'pages/Services';
import RubLabs from 'pages/RubLabs';
import Career from 'pages/Career';
import LandingPage from 'pages/Rubcamp';
import ContactUs from 'pages/ContactUs';

import NotFound from 'pages/NotFound';

import { HOMEPAGE_SCREENS, RUBCAMP_SCREENS, FALLBACK_404_SCREEN } from './screen-definitions';

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <AnimatePresence>
        <Routes>
          <Route path={HOMEPAGE_SCREENS.home} element={<BasicLayout />}>
            <Route path='' element={<Home />} />
            <Route path={HOMEPAGE_SCREENS.about} element={<AboutUs />} />
            <Route path={HOMEPAGE_SCREENS.services} element={<Services />} />
            <Route path={HOMEPAGE_SCREENS.rublabs} element={<RubLabs />} />
            <Route path={HOMEPAGE_SCREENS.career} element={<Career />} />
            <Route path={HOMEPAGE_SCREENS.contact} element={<ContactUs />} />
          </Route>

          <Route path={RUBCAMP_SCREENS.home} element={<RubcampPageLayout />}>
            <Route path='' element={<LandingPage />} />
          </Route>

          <Route
            path={FALLBACK_404_SCREEN}
            element={
              <BasicLayout>
                <NotFound />
              </BasicLayout>
            }
          />
        </Routes>
      </AnimatePresence>
    </BrowserRouter>
  );
}
