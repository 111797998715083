import styled from 'styled-components';

import { motion } from 'framer-motion';

export const Container = styled.div`
  flex-direction: column;

  width: 100%;

  position: 'relative';
`;

export const Motion = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 1 },
})`
  flex-direction: column;

  width: 100%;

  position: 'relative';
`;

export const ContainerActionButton = styled.div`
  align-items: center;

  gap: 20px;
`;

export const OutletHOC = styled.div`
  width: 100%;
`;
