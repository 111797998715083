import React from 'react';

import { useTranslation } from 'react-i18next';
import RubLabsImage from 'assets/images/rublabs/banner.webp';
import Banner from 'components/Banner';

import * as S from './styles';

function RubLabsBanner() {
  const { t: translator } = useTranslation();

  return (
    <S.Container>
      <Banner
        title={translator('rubLabs.rubLabsBanner.title')}
        subtitle={translator('rubLabs.rubLabsBanner.subtitle')}
        imageUri={RubLabsImage}
        imageAlt={translator('rubLabs.rubLabsBanner.alt')}
      />
    </S.Container>
  );
}

export default RubLabsBanner;
