export const email = 'contato@rubcube.com';
export const phone = '(18) 3222-6805';

export const googleMapsUrl =
  'https://www.google.com/maps/place/R.+Dr.+Jo%C3%A3o+Gon%C3%A7alves+Foz,+1147+-+Jardim+Marupiara,+Pres.+Prudente+-+SP,+19060-050/@-22.1227986,-51.406476,18z/data=!3m1!4b1!4m5!3m4!1s0x9493f440b0ad8841:0x6a4529e5dc1b08c7!8m2!3d-22.1228011!4d-51.4053587';

export const instagramUrl = 'https://www.instagram.com/rubcube_';
export const linkedinUrl = 'https://www.linkedin.com/company/rubcube';

export const formRubLabs = 'https://docs.google.com/forms/d/e/1FAIpQLSf6Xj44KNfDSI22l55MeHqq97t5N70_GB_AMXYdOlF9k9lbQA/viewform';
export const formRubcamp = 'https://forms.gle/CxDfBXFHNGc3fUTd7';
