export default {
  translations: {
    staticFiles: {
      featuresCards: {
        card1: {
          title: 'Desenvolvimento de Software',
          subtitle: 'Web & Mobile',
        },
        card2: {
          title: 'Engenharia de Dados',
          subtitle: 'Data Engineer',
        },
        card3: {
          title: 'Experiência do usuário e interface do usuário',
          subtitle: 'Ux / Ui',
        },
      },
      jobs: {
        job1: { name: 'Banco de Talentos - Dev Mobile React Native', location: 'Presidente Prudente / Remoto' },
        job2: { name: 'Banco de Talentos - Dev Backend Node.js', location: 'Presidente Prudente / Remoto' },
        job3: { name: 'Banco de Talentos - Engenheiro de Dados', location: 'Presidente Prudente / Remoto' },
        job4: { name: 'Product Owner - Pleno', location: 'Presidente Prudente / Remoto' },
      },
    },
    components: {
      scrollToTop: {
        alt: 'Voltar para o topo',
      },
      cardContact: {
        form: {
          name: 'Comece nos dizendo seu nome',
          email: 'Seu e-mail',
          message: 'Conte-nos um pouco sobre seu negócio e sobre o objetivo a ser alcançado...',
          buttonLabel: 'SOLICITAR CONTATO',
        },
        messageSent: {
          title: 'Solicitação Enviada',
          description:
            'Obrigado por nos escrever. Iremos analisar sua solicitação e entraremos em contato o mais rápido possível. Caso precise nos enviar uma nova mensagem, ',
          highlight: 'clique aqui.',
          alt: 'Ícone de sucesso',
        },
        errorMessage: 'Ocorreu um erro inesperado. Tente novamente!',
      },
    },
    header: {
      logoAlt: 'Logo da Rubcube',
      openMenuAlt: 'Abrir Menu',
      closeMenuAlt: 'Fechar Menu',
      site: {
        home: 'INÍCIO',
        about: 'SOBRE',
        services: 'SERVIÇOS',
        rubLabs: 'RUB LABS',
        career: 'CARREIRA',
        contact: 'CONTATO',
        buttonLabel: 'inscreva-se',
      },
      rubcamp: {
        home: 'INÍCIO',
        rubCamp: 'O RUB CAMP',
        preRequisites: 'PRÉ-REQUISITOS',
        program: 'O PROGRAMA',
        buttonLabel: 'inscreva-se',
      },
    },
    footer: {
      accompanyUs: 'Acompanhe nossas redes',
      instagramAlt: 'Logo do Instagram',
      linkedinAlt: 'Logo do Linkedin',
      whoWeAre: {
        title: 'Quem somos',
        ourMission: 'Nossa missão',
        ourVision: 'Nossa visão',
        ourValues: 'Nossos valores',
      },
      fastLinks: {
        title: 'Links rápidos',
        services: 'Serviços',
        rubLabs: 'Rub Labs',
        workWithUs: 'Trabalhe conosco',
      },
      contactUs: {
        title: 'Contate-nos',
        phone: 'Fone:',
        email: 'E-mail:',
      },
    },
    notFound: {
      pageTitle: 'Página não encontrada · Rubcube',
      title: 'Desculpe, mas esta página não existe.',
      text: 'Tente encontrar o que busca em nossa ',
      textLink: 'página inicial',
    },
    home: {
      mainBanner: {
        banner1: {
          title: 'rub labs is coming',
          text: 'Nosso laboratório de tecnologia e inovação, focado em criação de novos produtos, serviços e formação de pessoas',
        },
        banner2: {
          title: 'rub camp will accelerate your learning',
          text: 'Formação acadêmica + cursos rápidos + projetos práticos + time colaborativo. Nossa fórmula para aceleração da formação de profissionais',
        },
        buttonLabel: 'SAIBA MAIS',
      },
      ourMission: {
        title: 'Nossa Missão',
        text: 'É construir uma comunidade com os melhores profissionais de TI, para compartilhar conhecimento, experiências e valores',
        buttonLabel: 'SAIBA MAIS',
        alt: 'Pessoas celebrando',
      },
      whatWeDo: {
        title: 'O que fazemos:',
        text: 'Trabalhamos com as melhores práticas e ferramentas do mercado, ajudando nossos clientes nas áreas',
        buttonLabel: 'SAIBA MAIS',
      },
      successCase: {
        title: 'Nossos Clientes',
      },
      rubLabs: {
        title: '#1 Escritório',
        text1: 'O Rub Labs, nosso laboratório de ',
        highlight1: 'tecnologia',
        text2: ' e ',
        highlight2: 'inovação',
        text3: ', ganhou seu primeiro escritório localizado a 50m da Unesp de Presidente Prudente.',
        buttonLabel: 'SAIBA MAIS',
      },
      contactUs: {
        title: 'Quer alavancar seu negócio?',
        text: 'Conte conosco para te ajudar com qualquer desafio de desenvolvimento, engenharia de dados e UX/UI. Não se preocupe com quais ferramentas usar, infraestrutura e time, se preocupe apenas em crescer!',
      },
      socialMedia: {
        title: 'Acompanhe nossa jornada',
        instagramAlt: 'Logo do Instagram',
        linkedinAlt: 'Logo do Linkedin',
      },
    },
    about: {
      pageTitle: 'Sobre',
      pageMetaTagDescription:
        'É construir uma comunidade com os melhores profissionais de TI, para compartilhar conhecimento, experiências e valores.',
      ourMission: {
        title: 'É construir uma comunidade com os melhores profissionais de TI, para compartilhar conhecimento, experiências e valores',
        subtitle: 'nossa missão',
        alt: 'Pessoas celebrando',
      },
      ourVision: {
        title: 'Nossa Visão',
        text: 'Queremos ser reconhecida como uma empresa dos sonhos de qualquer profissional de TI, em qualquer momento da sua carreira. Não importa se começando ou com alguns anos de estrada, queremos que todos saibam que vão crescer conosco.',
        alt: 'Homem usando capacete de realidade virtual',
      },
      ourValues: {
        title: 'Nossos Valores',
        line1: {
          highlight1: 'Não esperamos',
          text1: ' as coisas acontecerem.',
        },
        line2: { highlight1: 'Buscamos', text1: ' o nosso melhor em tudo o que fazemos.' },
        line3: { highlight1: 'Falamos', text1: ' o que é preciso… ', highlight2: 'Sempre', text2: ' que preciso.' },
        line4: {
          highlight1: 'Cremos',
          text1: ' que quanto mais problemas ',
          highlight2: 'resolvemos',
          text2: ', mais problemas ',
          highlight3: 'sabemos',
          text3: ' resolver.',
        },
        line5: { highlight1: 'Acreditamos', text1: ' que pessoas ', highlight2: 'precisam', text2: ' de pessoas.' },
      },
      ourHistory: {
        title: 'Nossa História',
        text1: 'Nascemos oferecendo serviços de engenharia de dados, ETL e dashboards automatizados.',
        text2: 'Adicionamos desenvolvimento WEB e Mobile ao nosso portfólio.',
        text3: 'Criação da Joint Venture BankFactory para a construção de novas soluções financeiras.',
        text4: 'Criação do Rub Labs para inovação, fortalecimento da cultura e descobertas de novos talentos.',
      },
      joinUs: {
        title: 'Junte-se a nós',
        text: 'Queremos pessoas que compartilham dos nossos valores e que queiram dar o seu melhor todos os dias, incentivando o time a dar o seu melhor também. Que valorizam a sinceridade, que entendam a importância de comemorar as pequenas e grandes vitórias, que motivam seus pares, que compartilham conhecimento e principalmente, que acreditam na força de uma comunidade.',
        alt: 'Pessoas estudando',
        buttonLabel: 'SAIBA MAIS',
      },
    },
    services: {
      pageTitle: 'Serviços',
      pageMetaTagDescription: 'Entendemos a necessidade dos nossos clientes e construímos juntos, soluções de forma ágil e cross-platform.',
      whatWeDo: {
        title:
          'Somos uma agência de desenvolvimento que trabalha com estreita colaboração para ajudar nossos clientes a construir novas soluções',
        subtitle: 'o que fazemos',
        alt: 'Pessoa digitando',
      },
      webMobile: {
        title: 'Desenvolvimento de Software',
        subtitle: 'web & mobile',
        text: 'Entendemos a necessidade dos nossos clientes e construímos juntos, soluções de forma ágil e cross-platform.',
        alt: 'Homem usando celular',
      },
      dataEngineer: {
        title: 'Engenharia de Dados',
        subtitle: 'data engineer',
        text: 'Mapeamos todas as fontes de dados do negócio e construímos o fluxo de integração, tratamento e armazenamento necessário para apoiar as tomadas de decisão do time executivo, no tempo certo e de acordo com as métricas mais importantes.',
        alt: 'Dados',
      },
      uiUx: {
        title: 'Experiência do Usuário e Interface de Usuário',
        subtitle: 'ui/ux',
        text: 'Apoiamos nossos clientes no processo criativo de construção de layout e navegação para garantir a fidelidade à marca e a melhor experiência possível.',
        alt: 'Celular',
      },
      joinUs: {
        title: 'Junte-se a nós',
        text: 'Queremos pessoas que compartilham dos nossos valores e que queiram dar o seu melhor todos os dias, incentivando o time a dar o seu melhor também. Que valorizam a sinceridade, que entendam a importância de comemorar as pequenas e grandes vitórias, que motivam seus pares, que compartilham conhecimento e principalmente, que acreditam na força de uma comunidade.',
        alt: 'Pessoas estudando',
        buttonLabel: 'SAIBA MAIS',
      },
    },
    rubLabs: {
      pageMetaTagDescription: 'Criamos o Rub Labs para acelerar projetos de inovação, fortalecer nossa cultura e descobrir novos talentos.',
      rubLabsBanner: {
        title: 'Criamos o Rub Labs para acelerar projetos de inovação, fortalecer nossa cultura e descobrir novos talentos',
        subtitle: 'rub labs',
        alt: 'Pessoas estudando',
      },
      whatIs: {
        title: 'O que é',
        alt: 'Pessoas em grupo conversando',
        text1: 'O Rub Labs nasceu para ser um lugar onde podemos ',
        highlight1: 'incubar',
        text2: ' projetos de inovação, ',
        highlight2: 'testar',
        text3: ' novos processos, ',
        highlight3: 'promover',
        text4: ' eventos e treinamentos para o time, ',
        highlight4: 'treinar',
        text5: ' novos membros e ',
        highlight5: 'disseminar',
        text6: ' a cultura da nossa comunidade.',
      },
      office: {
        title: '#1 Escritório',
        text1: 'Já temos nosso ',
        highlight1: 'primeiro endereço',
        text2: ', localizado a 50m da Unesp de Presidente Prudente/SP na Rua João Gonçalves Fóz - 1147, Sala 01.',
        buttonLabel: 'VER NO MAPA',
        alt1: 'Entrada',
        alt2: 'Estações de trabalho',
        alt3: 'Auditório',
        alt4: 'Cozinha',
      },
      rubCamp: {
        title: 'Rub Camp',
        alt: 'Pessoas fazendo fogueira',
        text1: 'O Rub Camp é um ',
        highlight1: 'programa de treinamento',
        text2: ' que acontecerá dentro do nosso Lab para ',
        highlight2: 'acelerar a capacitação',
        text3: ' de profissionais de tecnologia somando formação acadêmica, cursos rápidos, projetos reais e time um colaborativo.',
        buttonLabel: 'SAIBA MAIS',
      },
    },
    career: {
      pageTitle: 'Carreira',
      pageMetaTagDescription: 'Estamos sempre em busca dos melhores profissionais de tecnologia para fortalecer nossa comunidade.',
      newTalents: {
        title: 'Estamos sempre em busca dos melhores profissionais tecnologia para fortalecer nossa comunidade',
        subtitle: 'novos talentos',
        alt: 'Pessoas conversando ao redor de uma mesa',
      },
      jobs: {
        title: 'Vagas Abertas',
        buttonLabel: 'Candidate-se',
      },
    },
    contactUs: {
      pageTitle: 'Contato',
      contact: {
        title: 'Entre em contato com a Rubcube',
        text: 'Apreciamos o seu interesse em nos contatar. Estamos disponíveis nos canais abaixo.',
        subtitle: 'Consultas gerais',
        office: 'Nosso escritório',
      },
      joinUs: {
        title: 'Como podemos te ajudar?',
        text: 'Conte conosco para te ajudar com qualquer desafio de desenvolvimento, engenharia de dados e UX/UI. Não se preocupe com quais ferramentas usar, infraestrutura e time, se preocupe apenas em crescer!',
      },
    },
    rubcamp: {
      mainBanner: {
        title: 'rub camp',
        text: 'Acelerar a capacitação de profissionais de tecnologia em início de carreira e fortalecer a comunidade Rub com a troca de conhecimentos',
        buttonLabel: 'inscreva-se',
      },
      whoWeAre: {
        title: 'Quem somos',
        text: 'Somos uma agência de desenvolvimento que trabalha com estreita colaboração para ajudar nossos clientes a construir novas soluções.',
        alt: 'Pessoa digitando',
        buttonLabel: 'SAIBA MAIS',
      },
      whatWeDo: {
        title: 'O que fazemos',
        subtitle: 'Trabalhamos com as melhores práticas e ferramentas do mercado, ajudando nossos clientes nas áreas',
        buttonLabel: 'SAIBA MAIS',
      },
      whatIs: {
        title: 'O que é o Rub Camp',
        text: 'O Rub Camp é um programa de treinamento para estudantes de tecnologia que acontecerá presencialmente em nosso laboratório de inovação em Presidente Prudente/SP.',
        alt: 'Pessoas conversando',
        text1:
          'A Brasscom (Associação das Empresas de Tecnologia da Informação e Comunicação e de Tecnologias Digitais) estimou que entre 2019 e 2024 seriam demandados 420 mil profissionais de tecnologia no Brasil, e que considerando a capacidade de formação nessa área do país, teríamos um déficit de 260 mil vagas não preenchidas. Esse número, que já era alto, foi revisto em 2021 após o cenário de pandemia que acelerou a transformação digital das empresas, e a demanda de 420 mil posições saltou para 797 mil de 2021 a 2025. O racional é que seriam demandadas 159 mil vagas por ano, porém a capacidade de formação do país é de apenas 53 mil profissionais, tendo um déficit de 106 mil posições/ano.',
        text2:
          'Nossa proposta com o Rub Camp considerando o cenário acima, é acelerar a capacitação de profissionais que estejam se formando na área. Queremos trazer estudantes de tecnologia para dentro do nosso time, treiná-los nas ferramentas de mercado e em projetos reais. Somar formação acadêmica, cursos rápidos, projetos e time colaborativo. Acreditamos que essa é uma fórmula promissora para colaborar com a redução do gap de profissionais no país e ainda oferecer uma ótima oportunidade para quem está em início de carreira.',
      },
      cards: {
        greenCard: {
          title: 'Problema',
          text1: 'Demanda por profissionais de TI crescente;',
          text2: 'Gap entre o que é visto em sala de aula e o que é aplicado no mercado;',
          text3: 'Dificuldade de montar uma boa trilha de aprendizado sem acompanhamento, dada a quantidade de cursos online;',
          text4:
            'Falta de oportunidade na região em comparação com grandes centros, ou posições remotas que limitam a troca de experiência no início de carreira.',
        },
        blueCard: {
          title: 'Nossa Proposta',
          text1: 'Time experiente e colaborativo para suporte e troca de conhecimento;',
          text2: 'Treinamentos e dia a dia em contato com ferramentas de desenvolvimento atuais no mercado;',
          text3: 'Exposição a problemas e projetos reais para exercitar a capacidade analítica;',
          text4: 'Ambiente descontraído e focado na evolução dos participantes;',
          text5: 'Infraestrutura para a melhor experiência durante o programa.',
        },
      },
      prerequisites: {
        title: 'Pré-requisitos',
        alt: 'Pessoa digitando',
        text1: 'Cursando faculdade de tecnologia;',
        text2: 'Conhecimento básico em qualquer linguagem de programação;',
        text3: 'Disponibilidade de 30h semanais;',
        text4: 'Participar presencialmente no Labs em Presidente Prudente.',
      },
      benefits: {
        title: 'Benefícios',
        alt: 'Homens conversando',
        text1: 'Vale refeição de R$ 20,95/dia;',
        text2: 'Local para refeições;',
        text3: 'Bolsa estágio - mês 1: R$ 300,00;',
        text4: 'Bolsa estágio - mês 2: R$ 600,00;',
        text5: 'Bolsa estágio - mês 3: R$ 900,00;',
        text6: 'Bolsa estágio - contratados após encerramento: R$ 1.200,00.',
      },
      selectiveProcess: {
        title: 'Processo seletivo',
        label1: '1. Inscrição pelo form',
        label2: '2. Teste de lógica de programação',
        label3: '3. Dinâmica em grupo',
        label4: '4. Entrevista',
        label5: '5. Fit cultural',
      },
      lastEdition: {
        title: 'Últimas edições',
        item: {
          description1: 'O Rub Camp ',
          description2: ' aconteceu em ',
          description3: ' , confira como foram as etapas do processo!',
          label1: '1. Inscrição pelo form',
          data1: ' inscritos',
          label2: '2. Teste de lógica de programação',
          data2: ' Testes enviados',
          label3: '3. Dinâmica em Grupo',
          data3: ' dias de dinâmica em grupo com ',
          data4: ' participantes',
          label4: '4. Entrevista com sócios',
          data5: ' entrevistas realizadas',
          selected: ' selecionados',
          date: {
            month: {
              1: 'Janeiro de ',
              2: 'Fevereiro de ',
              3: 'Março de ',
              4: 'Abril de ',
              5: 'Maio de ',
              6: 'Junho de ',
              7: 'Julho de ',
              8: 'Agosto de ',
              9: 'Setembro de ',
              10: 'Outubro de ',
              11: 'Novembro de ',
              12: 'Dezembro de ',
            },
          },
        },
      },
      timeLine: {
        title: 'Formato do programa',
        text1:
          'Nosso treinamento será realizado através de um projeto integrado, passando pelas principais áreas da Rub: backend, desenvolvimento WEB, desenvolvimento mobile e engenharia de dados. Vamos passo a passo entender as etapas do desenvolvimento de uma aplicação completa. O programa será classificatório, ou seja, os participantes com maior destaque seguirão para os próximos meses.',
        finishLabel: 'Encerramento',
        text2:
          'Ao final do programa, queremos convidar os participantes com maior aproveitamento e fit cultural a fazer parte da nossa comunidade!',
        month1: {
          title: 'Mês #1',
          subtitle: 'planning projeto integrado + backend',
          text1: 'Apresentação do projeto;',
          text2: 'Definição de histórias;',
          text3: 'Arquitetura do BD;',
          text4: 'Arquitetura da aplicação;',
          text5: 'Desenvolvimento das features no backend;',
          text6: 'Workshop - publicação do backend em Cloud.',
        },
        month2: {
          title: 'Mês #2',
          subtitle: 'front + mobile',
          text1: 'Criação da Landing Page de divulgação do projeto;',
          text2: 'Início do desenvolvimento mobile;',
          text3: 'Construção das telas do app;',
          text4: 'Integração com APIs do backend;',
          text5: 'Workshop - publicação de aplicativos nas lojas (play store e app store).',
        },
        month3: {
          title: 'Mês #3',
          subtitle: 'mobile + análise de dados',
          text1: 'Apresentação do app final;',
          text2: 'Brainstorm de quais KPIs acompanhar;',
          text3: 'Construção de dashboards;',
          text4: 'Apresentação dos dashboards.',
        },
        finalIconAlt: 'Ícone de Sucesso',
      },
      subscribe: {
        title: 'Participe',
        alt: 'Pessoa digitando',
        text: 'Queremos pessoas com sede de aprender, que queiram dar o seu melhor todos os dias e que acreditem na força de uma comunidade. Se você compartilha dos nossos valores e enxerga valor na nossa proposta, venha fazer parte dessa jornada com a gente!',
        buttonLabel: 'INSCREVA-SE',
      },
    },
  },
};
