import React, { ReactNode } from 'react';

import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import useWindowDimensions from 'hooks/window-dimensions';
import defaultTheme from 'styles/theme';

import { featuresCards } from '@static-files/features-cards';

import { SectionsId } from 'pages/Rubcamp/sectionIds';

import Text, { TextAlign, TextStyleType } from 'components/Text';
import ArrowButton, { ArrowButtonColor } from 'components/ArrowButton';
import Spacer from 'components/Spacer';
import FeatureCard from 'components/FeatureCard';

import { HOMEPAGE_SCREENS } from 'routes/screen-definitions';

import * as S from './styles';

function WhatWeDo() {
  const { t: translator } = useTranslation();

  const navigate = useNavigate();

  const { width } = useWindowDimensions();
  const { mobile } = defaultTheme.screensSize;

  const featureCards: ReactNode[] = featuresCards.map((item, index) => {
    return <FeatureCard key={index} title={item.title} subTitle={item.subtitle} image={item.image} alt={item.alt} />;
  });

  return (
    <S.Container id={SectionsId.WhatWeDo}>
      <Spacer height={width < mobile ? 40 : 80} />

      <Text type={TextStyleType.SectionTitle}>{translator('rubcamp.whatWeDo.title')}</Text>

      <S.SubtitleContainer>
        <Text
          type={TextStyleType.BodyParagraph}
          color={defaultTheme.palette.neutral.main}
          textAlign={width < mobile ? TextAlign.Start : TextAlign.Center}
        >
          {translator('rubcamp.whatWeDo.subtitle')}
        </Text>
      </S.SubtitleContainer>

      <Spacer height={width < mobile ? 20 : 40} />

      <S.Content>{featureCards}</S.Content>

      <Spacer height={width < mobile ? 20 : 40} />

      <ArrowButton
        label={translator('rubcamp.whatWeDo.buttonLabel')}
        color={ArrowButtonColor.Blue}
        onPressed={() => navigate(`${HOMEPAGE_SCREENS.home}${HOMEPAGE_SCREENS.services}`)}
      />

      <Spacer height={width < mobile ? 40 : 80} />
    </S.Container>
  );
}

export default WhatWeDo;
