import styled from 'styled-components';

import TextBase, { TextStyleType, TextAlign } from 'components/Text';

export const Container = styled.div`
  width: 30%;
  max-width: 340px;

  flex-direction: column;

  margin: 0px 10px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: 100%;
    max-width: 800px;

    margin: 0;
    margin-bottom: 40px;
  }
`;

export const Title = styled(TextBase).attrs({
  type: TextStyleType.FeatureCardTitleH3,
  textAlign: TextAlign.Center,
})`
  color: ${({ theme }) => theme.palette.secondary.main};

  max-width: 550px;
  white-space: normal;

  margin-top: 6px;

  max-width: 340px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    max-width: 800px;
  }
`;

export const SubTitle = styled(TextBase).attrs({
  type: TextStyleType.FeatureCardSubtitleH3,
  textAlign: TextAlign.Center,
})`
  color: ${({ theme }) => theme.palette.secondary.main};

  margin-top: 8px;
`;

export const Image = styled.img`
  width: 100%;

  margin-bottom: 16px;
`;
