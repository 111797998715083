import i18n, { InitOptions } from 'i18next';

// import LanguageDetector from 'i18next-browser-languagedetector';

import { initReactI18next } from 'react-i18next';

import translations from './locales';

const i18nConfig: InitOptions = {
  resources: translations,
  fallbackLng: 'pt-BR',
  lng: 'pt-BR',
  defaultNS: 'translations',
};

i18n.use(initReactI18next).init(i18nConfig);

export default i18n;
