import React, { ReactNode, useEffect } from 'react';

import defaultTheme from 'styles/theme';
import BeatLoader from 'react-spinners/BeatLoader';

import * as S from './styles';

interface ModalProps {
  children: ReactNode;
  handleClose: () => void;
  isLoading?: boolean;
}

function Modal({ handleClose, children, isLoading }: ModalProps) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  const onClickCloseButton = () => {
    document.body.style.overflow = '';
    handleClose();
  };

  return (
    <S.Container>
      {isLoading ? (
        <BeatLoader color={defaultTheme.palette.mono.main} />
      ) : (
        <S.Modal>
          {children}
          <S.CloseButton onClick={onClickCloseButton}>X</S.CloseButton>
        </S.Modal>
      )}
    </S.Container>
  );
}

export default Modal;
