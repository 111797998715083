import React from 'react';

import { useTranslation } from 'react-i18next';

import NewTalentsImage from 'assets/images/career/new-talents.webp';
import Banner from 'components/Banner';

import * as S from './styles';

function NewTalents() {
  const { t: translator } = useTranslation();

  return (
    <S.Container>
      <Banner
        title={translator('career.newTalents.title')}
        subtitle={translator('career.newTalents.subtitle')}
        imageUri={NewTalentsImage}
        imageAlt={translator('career.newTalents.alt')}
      />
    </S.Container>
  );
}

export default NewTalents;
