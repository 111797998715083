import styled from 'styled-components';

import FlagsBase from 'components/Flags';

import TextBase, { TextStyleType, TextAlign } from 'components/Text';

export const Container = styled.footer`
  display: flex;

  flex-direction: column;
  align-items: center;

  width: 100%;

  padding: 0 14px;

  background-color: ${({ theme }) => theme.palette.mono.background};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    align-items: flex-start;
  }
`;

export const Column = styled.div`
  flex-direction: column;

  justify-content: flex-start;
`;

export const ColumnAboutCompany = styled.div`
  flex-direction: column;

  justify-content: space-between;
`;

export const Row = styled.div<{ center: boolean }>`
  width: 100%;
  max-width: 1180px;

  align-items: ${({ center }) => (center ? 'center' : 'space-between')};

  justify-content: space-between;
`;

export const Logo = styled.img`
  width: 110px;
  height: 22px;
`;

export const IconButton = styled.button`
  margin-right: 6px;

  :hover {
    transition: opacity 0.3s linear;

    opacity: 0.4;
  }
`;

export const Icon = styled.img`
  width: 25px;
  height: 25px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    margin-top: 10px;
  }
`;

export const RowIcons = styled.div`
  width: 100%;

  justify-content: start;
`;

export const Button = styled.button``;

export const ButtonText = styled(TextBase).attrs(({ theme }) => {
  return { type: TextStyleType.FooterButton, color: theme.palette.neutral.main };
})`
  width: fit-content;

  margin-top: 8px;

  :hover {
    transition: opacity 0.3s linear;

    opacity: 0.4;
  }
`;

export const Title = styled(TextBase).attrs(({ theme }) => {
  return { type: TextStyleType.FooterTitleP, color: theme.palette.neutral.main };
})``;

export const LinkTo = styled.a``;

export const LinkToText = styled(TextBase).attrs(({ theme }) => {
  return { type: TextStyleType.LinkToTextA, color: theme.palette.neutral.main };
})`
  max-width: 250px;

  margin-top: 8px;

  :hover {
    transition: opacity 0.3s linear;

    opacity: 0.4;
  }
`;

export const TextAccompany = styled(TextBase).attrs(({ theme }) => {
  return { type: TextStyleType.FooterTextAccompanyP, color: theme.palette.secondary.main };
})`
  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    margin-top: 10px;
  }
`;

export const TextRub = styled(TextBase).attrs(({ theme }) => {
  return { type: TextStyleType.FooterTextRubP, color: theme.palette.secondary.main, align: TextAlign.Center };
})`
  text-align: center;
`;

export const Flags = styled(FlagsBase)``;

export const FlagsPlaceholderRight = styled.div`
  display: none;

  height: 25px;
  width: 56px;

  min-width: 56px !important;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    display: block;
  }
`;
