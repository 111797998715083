import React from 'react';

import * as S from './styles';

interface SpacerProps {
  height: number;
}

function Spacer({ height }: SpacerProps) {
  return <S.Container height={height} />;
}

export default Spacer;
