import React from 'react';

import Instagram from 'assets/logos/social-media/logo-instagram-blue.svg';
import Linkedin from 'assets/logos/social-media/logo-linkedin-blue.svg';

import { instagramUrl, linkedinUrl } from '@static-files/about-rub';

import { useTranslation } from 'react-i18next';

import * as S from './styles';

function SocialMedia() {
  const { t: translator } = useTranslation();

  return (
    <S.Container>
      <S.Row>
        <S.IconButton onClick={() => window.open(instagramUrl, '_blank')?.focus()}>
          <S.Icon src={Instagram} alt={translator('home.socialMedia.instagramAlt')} />
        </S.IconButton>

        <S.IconButton onClick={() => window.open(linkedinUrl, '_blank')?.focus()}>
          <S.Icon src={Linkedin} alt={translator('home.socialMedia.linkedinAlt')} />
        </S.IconButton>
      </S.Row>

      <S.Title>{translator('home.socialMedia.title')}</S.Title>
    </S.Container>
  );
}

export default SocialMedia;
