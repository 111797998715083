import React from 'react';

import BenefitsImage from 'assets/images/rubcamp/benefits.webp';

import TicketIcon from 'assets/icons/ticket.svg';
import CutleryIcon from 'assets/icons/cutlery.svg';
import MoneyBagIcon from 'assets/icons/money-bag.svg';

import { useTranslation } from 'react-i18next';
import defaultTheme from 'styles/theme';

import { TextStyleType } from 'components/Text';
import Spacer from 'components/Spacer';
import RowItem from 'components/RowItem';

import * as S from './styles';

function Benefits() {
  const { t: translator } = useTranslation();

  const listEntries = [
    { icon: TicketIcon, text: translator('rubcamp.benefits.text1') },
    { icon: CutleryIcon, text: translator('rubcamp.benefits.text2') },
    { icon: MoneyBagIcon, text: translator('rubcamp.benefits.text3') },
    { icon: MoneyBagIcon, text: translator('rubcamp.benefits.text4') },
    { icon: MoneyBagIcon, text: translator('rubcamp.benefits.text5') },
    { icon: MoneyBagIcon, text: translator('rubcamp.benefits.text6') },
  ];

  return (
    <S.Container id='benefits'>
      <S.ImageText
        image={BenefitsImage}
        alt={translator('rubcamp.benefits.alt')}
        title={translator('rubcamp.benefits.title')}
        titleStyle={TextStyleType.SectionTitle}
        titleColor={defaultTheme.palette.mono.main}
      >
        <Spacer height={24} />

        {listEntries.map((item, index) => {
          return <RowItem key={index} {...item} />;
        })}
      </S.ImageText>
    </S.Container>
  );
}

export default Benefits;
