import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: row;
  width: fit-content;

  height: 40px;
  padding: 8px;

  gap: 12px;

  background: rgba(107, 122, 229, 0.3);
  border-radius: 24px;

  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    height: 20px;
    padding: 4px;

    gap: 6px;

    border-radius: 12px;
  }
`;

export const ArrowButton = styled.div`
  width: 70px;
  height: 70px;

  background-color: black;
`;

interface CircularButtonProps {
  isEnabled: boolean;
}
export const CircularButton = styled.button<CircularButtonProps>`
  border: none;

  width: ${({ isEnabled }) => (isEnabled ? `24px` : `16px`)};
  height: ${({ isEnabled }) => (isEnabled ? `24px` : `16px`)};

  border-radius: 24px;

  background-color: ${({ theme, isEnabled }) => (isEnabled ? theme.palette.primary.main : theme.palette.mono.main)};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: ${({ isEnabled }) => (isEnabled ? `12px` : `8px`)};
    height: ${({ isEnabled }) => (isEnabled ? `12px` : `8px`)};

    border-radius: 12px;
  }
`;
