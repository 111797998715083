import React, { ReactNode } from 'react';

import defaultTheme from 'styles/theme';

import * as S from './styles';

export enum TextStyleType {
  Highlight = 'highlight',
  HighlightParagraphTitle = 'highlightParagraphTitle',
  CampInfoItemLabel = 'campInfoItemLabel',
  CampInfoItemHighlight = 'campInfoItemHighlight',
  BodyParagraph = 'bodyParagraph',
  TitleStrongCard = 'titleStrongCard',
  TopPageTitle = 'topPageTitle',
  TopPageTitleAbout = 'TopPageTitleAbout',
  TopPageTitleNotFound = 'TopPageTitleNotFound',
  BodyParagraphNotFoundP = 'BodyParagraphNotFoundP',
  NumberEntryAboutH2 = 'NumberEntryAboutH2',
  TextYearAboutH2 = 'TextYearAboutH2',
  TextDescriptionAboutH2 = 'TextDescriptionAboutH2',
  Tagline = 'tagline',
  FeatureCardTitleH3 = 'FeatureCardTitleH3',
  FeatureCardSubtitleH3 = 'FeatureCardSubtitleH3',
  SectionTitle = 'sectionTitle',
  SubtitleContacts = 'subtitleContacts',
  ContactItemText = 'contactItemText',
  RowItem = 'rowItem',
  ProgramSubtitle = 'programSubtitle',
  TitleBannerCta = 'titleBannerCta',
  DescriptionBannerCta = 'descriptionBannerCta',
  JobsSectionTitle = 'jobsSectionTitle',
  JobLabel = 'jobLabel',
  JobLocationLabel = 'jobLocationLabel',
  JobBadgeLabel = 'jobBadgeLabel',
  JobButtonLabel = 'jobButtonLabel',
  TitleBanner = 'titleBanner',
  SubtitleBanner = 'subtitleBanner',
  BadgeClientText = 'BadgeClientText',
  SubscribeButtonLabel = 'SubscribeButtonLabel',
  SelectiveProcessStepText = 'SelectiveProcessStepText',
  KnowMoreButtonLabel = 'knowMoreButtonLabel',
  ListTitleH3 = 'ListTitleH3',
  ListItemP = 'ListItemP',
  CardJoinUsText = 'CardJoinUsText',
  FooterButton = 'FooterButton',
  FooterTitleP = 'FooterTitleP',
  LinkToTextA = 'LinkToTextA',
  FooterTextAccompanyP = 'FooterTextAccompanyP',
  FooterTextRubP = 'FooterTextRubP',
}

export enum TextAlign {
  Start = 'start',
  Center = 'center',
  End = 'end',
  Left = 'left',
  Right = 'right',
}

interface TextProps {
  type: TextStyleType;
  color?: string;
  textAlign?: TextAlign;
  children: ReactNode;
  className?: string;
  paddingBottom?: boolean;
  marginLeft?: number;
}

function Text({
  type,
  color = defaultTheme.palette.secondary.main,
  textAlign = TextAlign.Start,
  children,
  className,
  paddingBottom = false,
  marginLeft = 0,
}: TextProps) {
  const map: { [id: string]: { style: JSX.Element } } = {};

  map.highlight = {
    style: <S.TextHighlight>{children}</S.TextHighlight>,
  };

  map.highlightParagraphTitle = {
    style: (
      <S.HighlightParagraphTitle className={className} color={color} textAlign={textAlign}>
        {children}
      </S.HighlightParagraphTitle>
    ),
  };

  map.campInfoItemLabel = {
    style: (
      <S.CampInfoItemLabel className={className} color={color} textAlign={textAlign} marginLeft={marginLeft}>
        {children}
      </S.CampInfoItemLabel>
    ),
  };

  map.campInfoItemHighlight = {
    style: (
      <S.CampInfoItemHighlight className={className} color={color} textAlign={textAlign}>
        {children}
      </S.CampInfoItemHighlight>
    ),
  };

  map.bodyParagraph = {
    style: (
      <S.BodyParagraph className={className} color={color} textAlign={textAlign}>
        {children}
      </S.BodyParagraph>
    ),
  };
  map.titleStrongCard = {
    style: (
      <S.TitleStrongCard className={className} color={color} textAlign={textAlign}>
        {children}
      </S.TitleStrongCard>
    ),
  };
  map.topPageTitle = {
    style: (
      <S.TopPageTitle className={className} color={color} textAlign={textAlign}>
        {children}
      </S.TopPageTitle>
    ),
  };
  map.TopPageTitleAbout = {
    style: (
      <S.TopPageTitleAbout className={className} color={color} textAlign={textAlign}>
        {children}
      </S.TopPageTitleAbout>
    ),
  };
  map.tagline = {
    style: (
      <S.Tagline className={className} color={color} textAlign={textAlign}>
        {children}
      </S.Tagline>
    ),
  };
  map.sectionTitle = {
    style: (
      <S.SectionTitle className={className} color={color} textAlign={textAlign} paddingBottom={paddingBottom}>
        {children}
      </S.SectionTitle>
    ),
  };
  map.rowItem = {
    style: (
      <S.RowItem className={className} color={color} textAlign={textAlign}>
        {children}
      </S.RowItem>
    ),
  };
  map.programSubtitle = {
    style: (
      <S.ProgramSubtitle className={className} color={color} textAlign={textAlign}>
        {children}
      </S.ProgramSubtitle>
    ),
  };
  map.titleBannerCta = {
    style: (
      <S.TitleBannerCta className={className} color={color} textAlign={textAlign}>
        {children}
      </S.TitleBannerCta>
    ),
  };
  map.descriptionBannerCta = {
    style: (
      <S.DescriptionBannerCta className={className} color={color} textAlign={textAlign}>
        {children}
      </S.DescriptionBannerCta>
    ),
  };

  map.subtitleContacts = {
    style: (
      <S.SubtitleContacts className={className} color={color} textAlign={textAlign}>
        {children}
      </S.SubtitleContacts>
    ),
  };

  map.contactItemText = {
    style: (
      <S.ContactItemText className={className} color={color} textAlign={textAlign}>
        {children}
      </S.ContactItemText>
    ),
  };

  map.jobsSectionTitle = {
    style: (
      <S.JobsSectionTitle className={className} color={color} textAlign={textAlign}>
        {children}
      </S.JobsSectionTitle>
    ),
  };

  map.jobLabel = {
    style: (
      <S.JobLabel className={className} color={color} textAlign={textAlign}>
        {children}
      </S.JobLabel>
    ),
  };

  map.jobLocationLabel = {
    style: (
      <S.JobLocationLabel className={className} color={color} textAlign={textAlign}>
        {children}
      </S.JobLocationLabel>
    ),
  };

  map.jobBadgeLabel = {
    style: (
      <S.JobBadgeLabel className={className} color={color} textAlign={textAlign}>
        {children}
      </S.JobBadgeLabel>
    ),
  };

  map.jobButtonLabel = {
    style: (
      <S.JobButtonLabel className={className} color={color} textAlign={textAlign}>
        {children}
      </S.JobButtonLabel>
    ),
  };

  map.titleBanner = {
    style: (
      <S.TitleBanner className={className} color={color} textAlign={textAlign}>
        {children}
      </S.TitleBanner>
    ),
  };

  map.subtitleBanner = {
    style: (
      <S.SubtitleBanner className={className} color={color} textAlign={textAlign}>
        {children}
      </S.SubtitleBanner>
    ),
  };

  map.knowMoreButtonLabel = {
    style: (
      <S.KnowMoreButtonLabel className={className} color={color} textAlign={textAlign}>
        {children}
      </S.KnowMoreButtonLabel>
    ),
  };

  map.ListTitleH3 = {
    style: (
      <S.ListTitleH3 className={className} color={color} textAlign={textAlign}>
        {children}
      </S.ListTitleH3>
    ),
  };

  map.ListItemP = {
    style: (
      <S.ListItemP className={className} color={color} textAlign={textAlign}>
        {children}
      </S.ListItemP>
    ),
  };

  map.CardJoinUsText = {
    style: (
      <S.CardJoinUsText className={className} color={color} textAlign={textAlign}>
        {children}
      </S.CardJoinUsText>
    ),
  };

  map.NumberEntryAboutH2 = {
    style: (
      <S.NumberEntryAboutH2 className={className} color={color} textAlign={textAlign}>
        {children}
      </S.NumberEntryAboutH2>
    ),
  };

  map.TextYearAboutH2 = {
    style: (
      <S.TextYearAboutH2 className={className} color={color} textAlign={textAlign}>
        {children}
      </S.TextYearAboutH2>
    ),
  };

  map.TextDescriptionAboutH2 = {
    style: (
      <S.TextDescriptionAboutH2 className={className} color={color} textAlign={textAlign}>
        {children}
      </S.TextDescriptionAboutH2>
    ),
  };

  map.FeatureCardTitleH3 = {
    style: (
      <S.FeatureCardTitleH3 className={className} color={color} textAlign={textAlign}>
        {children}
      </S.FeatureCardTitleH3>
    ),
  };

  map.FeatureCardSubtitleH3 = {
    style: (
      <S.FeatureCardSubtitleH3 className={className} color={color} textAlign={textAlign}>
        {children}
      </S.FeatureCardSubtitleH3>
    ),
  };

  map.BadgeClientText = {
    style: (
      <S.BadgeClientText className={className} color={color} textAlign={textAlign}>
        {children}
      </S.BadgeClientText>
    ),
  };

  map.SubscribeButtonLabel = {
    style: (
      <S.SubscribeButtonLabel className={className} color={color} textAlign={textAlign}>
        {children}
      </S.SubscribeButtonLabel>
    ),
  };

  map.SelectiveProcessStepText = {
    style: (
      <S.SelectiveProcessStepText className={className} color={color} textAlign={textAlign}>
        {children}
      </S.SelectiveProcessStepText>
    ),
  };

  map.TopPageTitleNotFound = {
    style: (
      <S.TopPageTitleNotFound className={className} color={color} textAlign={textAlign}>
        {children}
      </S.TopPageTitleNotFound>
    ),
  };

  map.BodyParagraphNotFoundP = {
    style: (
      <S.BodyParagraphNotFoundP className={className} color={color} textAlign={textAlign}>
        {children}
      </S.BodyParagraphNotFoundP>
    ),
  };

  map.FooterButton = {
    style: (
      <S.FooterButton className={className} color={color} textAlign={textAlign}>
        {children}
      </S.FooterButton>
    ),
  };

  map.FooterTitleP = {
    style: (
      <S.FooterTitleP className={className} color={color} textAlign={textAlign}>
        {children}
      </S.FooterTitleP>
    ),
  };

  map.LinkToTextA = {
    style: (
      <S.LinkToTextA className={className} color={color} textAlign={textAlign}>
        {children}
      </S.LinkToTextA>
    ),
  };

  map.FooterTextAccompanyP = {
    style: (
      <S.FooterTextAccompanyP className={className} color={color} textAlign={textAlign}>
        {children}
      </S.FooterTextAccompanyP>
    ),
  };

  map.FooterTextRubP = {
    style: (
      <S.FooterTextRubP className={className} color={color} textAlign={textAlign}>
        {children}
      </S.FooterTextRubP>
    ),
  };

  return map[type].style;
}

export default Text;
