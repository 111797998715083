import styled from 'styled-components';

export const Content = styled.div`
  flex-direction: column;
  width: 1600px;
  justify-content: center;

  @media (max-width: ${({ theme }) => `${theme.screensSize.ultraHd}px`}) {
    flex-direction: column;
    width: 1200px;
  }

  @media (max-width: ${({ theme }) => `${theme.screensSize.fullHd}px`}) {
    flex-direction: column;
    width: 750px;
  }

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    flex-direction: column;
    width: 360px;
  }
`;

export const Item = styled.div`
  flex-direction: row;

  width: 100%;
  justify-content: center;
`;

export const ItemInfo = styled.div`
  flex-direction: column;
  height: 445px;
  margin-left: 20px;
  padding: 28px 0px;

  justify-content: space-between;
`;

export const ItemInfoTopic = styled.div`
  flex-direction: column;
`;

export const FunnelContent = styled.div`
  flex-direction: column;
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;

  max-width: 563px;
  max-height: 480px;

  object-fit: cover;
`;
