import styled from 'styled-components';

export const Container = styled.section`
  flex-direction: column;

  width: 100%;

  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.palette.mono.main};
`;

export const Content = styled.div`
  flex-direction: column;

  width: 100%;

  align-items: center;
  justify-content: center;

  max-width: 1180px;
`;

export const TextsContainer = styled.div`
  width: 100%;

  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 20px;
`;

export const Column = styled.div`
  width: 100%;

  flex-direction: column;

  gap: 20px;

  justify-content: flex-start;
  align-items: center;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    align-items: flex-start;
  }
`;

export const Row = styled.div`
  width: 100%;

  flex-wrap: nowrap;

  justify-content: center;
  align-items: center;

  padding-left: 14px;
  padding-right: 14px;
`;

export const StepsContainer = styled.div`
  width: 100%;
  margin-top: 40px;

  justify-content: space-between;
  align-items: flex-start;

  position: relative;

  @media (max-width: ${({ theme }) => `${theme.screensSize.fullHd}px`}) {
    flex-direction: column;
    margin-top: 20px;

    gap: 32px;

    align-items: flex-start;
  }
`;

export const Divider = styled.div`
  width: calc(100% - 200px);

  height: 4px;

  position: absolute;

  top: 48px;
  right: 0;

  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  background-color: ${({ theme }) => theme.palette.primary.main};

  @media (max-width: ${({ theme }) => `${theme.screensSize.fullHd}px`}) {
    width: 4px;

    height: calc(100% - 100px);

    margin-left: 38px;
    margin-right: 0;
  }
`;
