import styled from 'styled-components';

export const Container = styled.section`
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100vh;

  background-color: ${({ theme }) => theme.palette.mono.main};

  min-height: 860px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    align-items: flex-start;
    height: 80vh;

    min-height: 200px;
  }
`;

export const Content = styled.div`
  flex-direction: column;

  padding: 0 14px;

  align-items: center;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    align-items: flex-start;
  }
`;

export const SubtitleContainer = styled.div`
  width: 100%;
  max-width: 600px;
`;

export const Image = styled.img`
  position: absolute;

  left: 0px;
  top: 0px;

  z-index: 0;

  width: 100%;
  height: 116vh;

  object-fit: cover;
  min-height: 860px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    height: 80vh;
    min-height: 200px;
  }
`;
