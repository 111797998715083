import React from 'react';

import defaultTheme from 'styles/theme';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import WhoWeAreImage from 'assets/images/services/what-we-do.webp';

import { SectionsId } from 'pages/Rubcamp/sectionIds';
import { TextStyleType } from 'components/Text';

import ImageText from 'components/ImageText';

import { HOMEPAGE_SCREENS } from 'routes/screen-definitions';

import * as S from './styles';

function WhoWeAre() {
  const { t: translator } = useTranslation();

  const navigate = useNavigate();

  return (
    <S.Container id={SectionsId.WhoWeAre}>
      <ImageText
        image={WhoWeAreImage}
        alt={translator('rubcamp.whoWeAre.alt')}
        title={translator('rubcamp.whoWeAre.title')}
        text={translator('rubcamp.whoWeAre.text')}
        titleStyle={TextStyleType.SectionTitle}
        textColor={defaultTheme.palette.neutral.main}
      >
        <S.ArrowButton
          label={translator('rubcamp.whoWeAre.buttonLabel')}
          onPressed={() => navigate(`${HOMEPAGE_SCREENS.home}${HOMEPAGE_SCREENS.about}`)}
        />
      </ImageText>
    </S.Container>
  );
}

export default WhoWeAre;
