import styled from 'styled-components';

import TextBase, { TextStyleType } from 'components/Text';

export const Container = styled.section`
  width: 100%;

  padding-bottom: 80px;

  flex-direction: column;
  align-items: center;

  background-color: ${({ theme }) => theme.palette.mono.main};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding-bottom: 40px;
  }
`;

export const Title = styled(TextBase).attrs({
  type: TextStyleType.BodyParagraph,
})`
  font-weight: 700;
  text-align: center;

  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const Row = styled.div`
  justify-content: center;
`;

export const IconButton = styled.button`
  margin: 16px 12px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    margin: 8px 6px;
  }

  :hover {
    transition: opacity 0.3s linear;
    opacity: 0.4;
  }
`;

export const Icon = styled.img`
  width: 72px;
  height: 74px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: 40px;
  }
`;
