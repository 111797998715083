export enum SectionsId {
  Start = 'start',
  WhoWeAre = 'who-we-are',
  WhatWeDo = 'what-we-do',
  WhatIs = 'what-is',
  PreRequisites = 'pre-requisites',
  Benefits = 'benefits',
  SelectiveProcess = 'selective-process',
  LastEdition = 'last-edition',
  Format = 'format',
  Subscribe = 'subscribe',
}
