import styled from 'styled-components';

export const Container = styled.section`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  padding: 80px 14px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding: 40px 14px;
  }
`;

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  max-width: 1180px;

  align-items: center;
  justify-content: center;
`;

export const TextsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 24px;

  padding-bottom: 80px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    flex-direction: column;

    padding: 0;

    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const Column = styled.div`
  width: 100%;

  flex-direction: column;

  justify-content: center;
  align-items: center;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const Row = styled.div`
  width: 100%;

  flex-wrap: nowrap;

  justify-content: center;
`;

export const CardsContainer = styled.div`
  width: 100%;

  justify-content: center;

  gap: 60px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    flex-direction: column;

    justify-content: center;
    align-items: center;

    padding-top: 80px;

    gap: 20px;
  }
`;
