import React from 'react';

import ArrowRightBlue from 'assets/icons/arrow-right-blue.svg';
import ArrowRightGreen from 'assets/icons/arrow-right-green.svg';
import ArrowRightWhite from 'assets/icons/arrow-right-white.svg';

import defaultTheme from 'styles/theme';

import Text, { TextStyleType } from 'components/Text';

import * as S from './styles';

export enum ArrowButtonColor {
  Blue = 'blue',
  Green = 'green',
  White = 'white',
}

interface ArrowButtonProps {
  label: string;
  color?: ArrowButtonColor;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  onPressed?: () => void;
}

function ArrowButton({
  onPressed,
  color = ArrowButtonColor.White,
  label,
  className,
  isLoading = false,
  disabled = false,
}: ArrowButtonProps) {
  const map: { [id: string]: { color: string; arrowImgPath: string } } = {};

  map.blue = { color: defaultTheme.palette.primary.main, arrowImgPath: ArrowRightBlue };
  map.green = { color: defaultTheme.palette.contrast.main, arrowImgPath: ArrowRightGreen };
  map.white = { color: defaultTheme.palette.mono.main, arrowImgPath: ArrowRightWhite };

  return (
    <S.Button className={className} onClick={onPressed} disabled={isLoading || disabled}>
      <Text type={TextStyleType.KnowMoreButtonLabel} color={map[color].color}>
        {label}
      </Text>
      {isLoading ? <S.Loader /> : <S.ButtonIcon src={map[color].arrowImgPath} alt='' />}
    </S.Button>
  );
}

export default ArrowButton;
