import styled from 'styled-components';

export const Card = styled.div<{ color: string }>`
  width: 100%;
  background-color: ${({ color }) => color};
  flex-direction: row;
  max-width: 1180px;
  height: fit-content;

  @media (max-width: ${({ theme }) => `${theme.screensSize.hd}px`}) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  position: relative;

  flex: 4;
  flex-direction: column;

  padding: 60px;

  width: 100%;

  @media (max-width: ${({ theme }) => `${theme.screensSize.fullHd}px`}) {
    padding: 30px;
  }

  @media (max-width: ${({ theme }) => `${theme.screensSize.hd}px`}) {
    padding: 20px;
  }
`;

export const Image = styled.img`
  position: relative;

  flex: 3;

  height: 100%;
  min-width: 500px;

  object-fit: cover;

  @media (max-width: ${({ theme }) => `${theme.screensSize.hd}px`}) {
    min-width: 200px;
    max-height: 300px;
  }
`;
