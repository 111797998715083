import styled from 'styled-components';

export const Container = styled.div<{ isSuperior: boolean; isLast: boolean }>`
  flex-direction: column;

  justify-content: flex-end;
  align-items: center;

  width: fit-content;
  height: fit-content;

  position: absolute;

  left: 0;

  bottom: ${({ isSuperior }) => (isSuperior ? `0;` : `auto`)};
  top: ${({ isSuperior }) => (!isSuperior ? `0;` : `auto`)};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    position: static;

    height: 100%;

    margin-bottom: ${({ isLast }) => (isLast ? `40px;` : `0`)};

    justify-content: flex-start;

    min-height: 130px;
  }

  @media (max-width: 375px) {
    min-height: 156px;
  }
`;

export const Circle = styled.div`
  height: 25px;
  width: 25px;

  background-color: ${({ theme }) => theme.palette.mono.main};

  border-radius: 50%;
  border: ${({ theme }) => `2px solid ${theme.palette.primary.main}`};
`;

export const Line = styled.div`
  height: 47px;
  width: 2px;
  background-color: ${({ theme }) => theme.palette.primary.main};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    height: 100%;
  }
`;

export const Column = styled.div`
  width: 100%;
  height: 100%;

  flex-direction: column;
  flex-wrap: nowrap;

  justify-content: flex-start;
  align-items: center;
`;

export const LineContainer = styled.div`
  width: 100%;
  height: 100%;

  flex-direction: column;
  flex-wrap: nowrap;

  justify-content: flex-start;
  align-items: flex-start;
`;
