import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';

import MainBanner from './MainBanner';
import WhatWeDo from './WhatWeDo';
import WhoWeAre from './WhoWeAre';
import WhatIs from './WhatIs';
import PreRequisites from './PreRequisites';
import Benefits from './Benefits';
import SelectiveProcess from './SelectiveProcess';
import TimeLineMap from './TimeLineMap';
import Subscribe from './Subscribe';

import * as S from './styles';
import LastEdition from './LastEdition';

function LandingPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Container>
      <Helmet>
        <title>Rubcamp</title>
      </Helmet>

      <MainBanner />
      <WhoWeAre />
      <WhatWeDo />
      <WhatIs />
      <PreRequisites />
      <Benefits />
      <SelectiveProcess />
      <LastEdition />
      <TimeLineMap />
      <Subscribe />
    </S.Container>
  );
}

export default LandingPage;
