import styled from 'styled-components';

export const Container = styled.section`
  flex-direction: column;

  align-items: center;

  height: fit-content;
  width: 100%;

  background-color: ${({ theme }) => theme.palette.secondary.background};

  padding: 14px;
  padding-top: 0;
`;
