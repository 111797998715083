import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  #root {
    display: flex;
  }

  * {
    box-sizing: border-box;

    font-weight: 300;
    font-size: 12px;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;

    margin:0;
    padding:0;

    font-family: 'Montserrat', sans-serif;

    position: relative;

    ::-moz-selection { 
      background: #6B7AE5;
      color: #fff;
    }

    ::selection {
      color: #fff;
      background: #6B7AE5;
    }
  }

  html {
    font-size: 62.5%; 
  }
  
  button {
    display: inline-block;

    border: none;
    background-color: transparent;
    
    cursor: pointer;    
  }

  div, span, section, header, footer, main, nav {
    display: flex;

    flex-direction: row;
  }

  h1, h2, h3, h4, h5, h6, p, input, textarea, li, nav {
    font-family: 'Montserrat', sans-serif;
  }

  nav {
    width: 100%;
  }
  
  input, textarea {
    width: 100%;
    padding: 10px;

    resize: none;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #FFF;

    font-size: 1.4rem;
    line-height: 2rem;

    ::placeholder {
      color: #bdbbff;
    }

    &:focus {
      outline: none;
    }
  }

  li {
    list-style: none;
  }

  a {
  :link {
    text-decoration: none !important;
  }

  :visited {
    text-decoration: none !important;
  }

  :hover {
    text-decoration: none !important;
  }

  :active {
    text-decoration: none !important;
  }
}

`;
