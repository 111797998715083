import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import defaultTheme from 'styles/theme';

import Text, { TextStyleType } from 'components/Text';

import { SectionsId } from 'pages/Rubcamp/sectionIds';
import ImageText from 'components/ImageText';
import Card from './Card';
import campersCarousel from './CampersCarousel';

import * as S from './styles';

function WhatIs() {
  const { t: translator } = useTranslation();
  const campersData = campersCarousel();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Container id={SectionsId.WhatIs}>
      <S.Content>
        <S.Column>
          <S.TextsContainer>
            <ImageText
              alt='campers'
              title={translator('rubcamp.whatIs.title')}
              titlePaddingBottom
              reverse
              text={translator('rubcamp.whatIs.text')}
              titleStyle={TextStyleType.SectionTitle}
              textColor={defaultTheme.palette.neutral.main}
              imageCarousel={campersData}
            />

            <Text type={TextStyleType.BodyParagraph} color={defaultTheme.palette.neutral.main}>
              {translator('rubcamp.whatIs.text1')}
            </Text>

            <Text type={TextStyleType.BodyParagraph} color={defaultTheme.palette.neutral.main}>
              {translator('rubcamp.whatIs.text2')}
            </Text>
          </S.TextsContainer>

          <S.CardsContainer>
            <Card
              color='green'
              title={translator('rubcamp.cards.greenCard.title')}
              texts={[
                translator('rubcamp.cards.greenCard.text1'),
                translator('rubcamp.cards.greenCard.text2'),
                translator('rubcamp.cards.greenCard.text3'),
                translator('rubcamp.cards.greenCard.text4'),
              ]}
            />

            <Card
              color='blue'
              title={translator('rubcamp.cards.blueCard.title')}
              texts={[
                translator('rubcamp.cards.blueCard.text1'),
                translator('rubcamp.cards.blueCard.text2'),
                translator('rubcamp.cards.blueCard.text3'),
                translator('rubcamp.cards.blueCard.text4'),
                translator('rubcamp.cards.blueCard.text5'),
              ]}
            />
          </S.CardsContainer>
        </S.Column>
      </S.Content>
    </S.Container>
  );
}

export default WhatIs;
