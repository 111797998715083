import React from 'react';

import Spacer from 'components/Spacer';
import CardJoinUs from 'components/CardJoinUs';

import { useTranslation } from 'react-i18next';
import useWindowDimensions from 'hooks/window-dimensions';
import defaultTheme from 'styles/theme';

import * as S from './styles';

function JoinUs() {
  const { t: translator } = useTranslation();

  const { width } = useWindowDimensions();
  const { hd } = defaultTheme.screensSize;

  return (
    <S.Container>
      <Spacer height={width < hd ? 40 : 80} />

      <CardJoinUs
        route='/career'
        title={translator('about.joinUs.title')}
        text={translator('about.joinUs.text')}
        alt={translator('about.joinUs.alt')}
        buttonLabel={translator('about.joinUs.buttonLabel')}
      />

      <Spacer height={width < hd ? 40 : 80} />
    </S.Container>
  );
}

export default JoinUs;
