import React from 'react';

import { useTranslation } from 'react-i18next';

import WhatWeDoImage from 'assets/images/services/what-we-do.webp';
import Banner from 'components/Banner';

import * as S from './styles';

function WhatWeDo() {
  const { t: translator } = useTranslation();

  return (
    <S.Container>
      <Banner
        title={translator('services.whatWeDo.title')}
        subtitle={translator('services.whatWeDo.subtitle')}
        imageUri={WhatWeDoImage}
        imageAlt={translator('services.whatWeDo.alt')}
      />
    </S.Container>
  );
}

export default WhatWeDo;
