import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';

import Store from 'contexts/Store';

import { useTranslation } from 'react-i18next';

import RubLabsBanner from './RubLabsBanner';
import WhatIs from './WhatIs';
import Office from './Office';
import RubCamp from './RubCamp';
import JoinUs from './JoinUs';

import * as S from './styles';

function RubLabs() {
  const { setData } = Store.useStore();

  const { t: translator } = useTranslation();

  useEffect(() => {
    setData({ index: 3 });

    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Container itemScope itemType='http://schema.org/Place'>
      <Helmet>
        <title>Rub labs</title>

        <meta name='description' content={translator('rubLabs.pageMetaTagDescription')} />
      </Helmet>

      <RubLabsBanner />
      <WhatIs />
      <Office />
      <RubCamp />
      <JoinUs />
    </S.Container>
  );
}

export default RubLabs;
