/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { ReactNode } from 'react';
import { rubcampEditions } from '@static-files/rubcamp-editions';

import * as S from './styles';

function campersCarousel() {
  const data = rubcampEditions;
  const RubCampPhotos: ReactNode[] = [];

  data.forEach((rubcamp) => {
    RubCampPhotos.push(
      <S.Content>
        <S.Image src={require(`assets/images/rubcamp/campers/campers-${rubcamp.edition}.webp`)} alt={`turma do camp ${rubcamp.edition}`} />
      </S.Content>
    );
  });
  return RubCampPhotos;
}

export default campersCarousel;
