export const HOMEPAGE_SCREENS = {
  home: '/',
  about: 'about',
  services: 'services',
  rublabs: 'rublabs',
  career: 'career',
  contact: 'contact',
};

export const RUBCAMP_SCREENS = {
  home: '/rubcamp',
};

export const FALLBACK_404_SCREEN = '/*';
