import styled from 'styled-components';

import LogoSuccessUri from 'assets/icons/timeline-success.svg';

import TextBase from 'components/Text';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    flex-direction: row;

    align-items: center;
    justify-content: flex-start;

    margin-bottom: 20px;
  }
`;

export const Image = styled.img.attrs(() => {
  return {
    src: LogoSuccessUri,
  };
})`
  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: 28px;
    height: 28px;
  }
`;

export const Text = styled(TextBase)`
  color: ${({ theme }) => theme.palette.neutral.main};

  padding-top: 20px;

  text-align: center;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding-top: 0;
    padding-left: 10px;
  }
`;
