import styled from 'styled-components';

import ArrowButtonBase, { ArrowButtonColor } from 'components/ArrowButton';

export const Container = styled.section`
  flex-direction: column;

  align-items: center;
  justify-content: center;

  width: 100%;

  background-color: ${({ theme }) => theme.palette.mono.main};

  padding: 80px 14px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding: 40px 14px;
  }
`;

export const ArrowButton = styled(ArrowButtonBase).attrs({
  color: ArrowButtonColor.Blue,
})`
  margin-top: 24px;
`;
