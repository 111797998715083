import React from 'react';

import { useState } from 'hooks/state';

interface IGenericType {
  [key: string]: any;
  key?: string;
}

interface IStoreValues {
  setData: (value: IGenericType) => void;
  data: IGenericType;
}

const StoreContext = React.createContext<IStoreValues | null>(null);

function StoreProvider({ children }: any) {
  const [state, setState] = useState<IGenericType>();

  const value = {
    setData: (data: IGenericType) => setState(data),
    data: state,
  };

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
}

export default {
  Provider: StoreProvider,
  Context: StoreContext,
  useStore: (): IStoreValues => React.useContext(StoreContext) as IStoreValues,
};
