import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { HOMEPAGE_SCREENS } from 'routes/screen-definitions';

import * as S from './styles';

function NotFound() {
  const { t: translator } = useTranslation();

  const navigate = useNavigate();

  return (
    <S.Container>
      <S.ResponsiveContainer>
        <S.Column>
          <S.Title>{translator('notFound.title')}</S.Title>

          <S.Text>
            {translator('notFound.text')}
            <S.Button onClick={() => navigate(`${HOMEPAGE_SCREENS.home}`, { replace: true })}>
              <S.Text>
                <S.TextLink>{translator('notFound.textLink')}</S.TextLink>
              </S.Text>
            </S.Button>
          </S.Text>
        </S.Column>
      </S.ResponsiveContainer>
    </S.Container>
  );
}

export default NotFound;
