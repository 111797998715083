import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import ResponseError from 'components/ResponseError';

import MessageSent from './MessageSent';
import Form from './Form';

import * as S from './styles';

interface CardContactProps {
  title: string;
  text: string;
}

function CardContact({ title, text }: CardContactProps) {
  const { t: translator } = useTranslation();

  const [messageSent, setMessageSent] = useState<boolean | null>(null);

  return (
    <>
      <S.BlueCard>
        {messageSent ? (
          <MessageSent onPressedNewMessage={() => setMessageSent(null)} />
        ) : (
          <Form setMessageSent={(sent: boolean) => setMessageSent(sent)} title={title} text={text} />
        )}
      </S.BlueCard>

      {messageSent === false && (
        <Modal handleClose={() => setMessageSent(null)}>
          <ResponseError message={translator('components.cardContact.errorMessage')} />
        </Modal>
      )}
    </>
  );
}

export default CardContact;
