import styled from 'styled-components';

import TextBase from 'components/Text';

export const Container = styled.div<{ reverse: boolean }>`
  width: 100%;
  max-width: 1180px;

  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  justify-content: space-between;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  flex-direction: column;
  justify-content: center;

  width: 50%;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: 100%;
    max-width: none;

    padding-top: 12px;
  }
`;

export const Gap = styled.div`
  width: 60px;
  height: 100%;
`;

export const Image = styled.img<{ flipImage: boolean }>`
  height: 100%;
  max-height: 480px;
  max-width: 560px;

  object-fit: cover;

  -webkit-transform: ${({ flipImage }) => (flipImage ? 'scaleX(-1)' : 'scaleX(1)')};
  transform: ${({ flipImage }) => (flipImage ? 'scaleX(-1)' : 'scaleX(1)')};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: 100%;
    height: 368px;

    max-height: 750px;
    max-width: 750px;
  }
`;

export const Title = styled(TextBase)``;

export const Subtitle = styled(TextBase)``;

export const Text = styled(TextBase)``;
