import styled from 'styled-components';

import TextBase, { TextStyleType } from 'components/Text';

export const Button = styled.button`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  width: fit-content;

  cursor: pointer;

  white-space: nowrap;

  :hover {
    transition: opacity 0.3s linear;
    opacity: 0.6;
  }
`;

export const ButtonLabel = styled(TextBase).attrs({
  type: TextStyleType.SubscribeButtonLabel,
})`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.mono.main};

  border-radius: 6.87764px;

  padding: 6.88px 13.75px;
`;
