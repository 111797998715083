import styled from 'styled-components';

export const Container = styled.section`
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

export const Content = styled.div`
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  padding: 0 !important;
  margin: 0 !important;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    justify-content: center;

    padding-top: 0;
  }
`;

export const Column = styled.div`
  width: 100%;

  flex-direction: column;
  flex-wrap: nowrap;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100vh;

  object-fit: cover;

  padding-top: 72px;
`;
