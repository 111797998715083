import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: column;
  display: flex;

  align-items: center;
  justify-content: center;

  width: 100%;
`;
