import React from 'react';

import { useTranslation } from 'react-i18next';

import DataEngineeringImage from 'assets/images/features-cards/data-engineering.webp';
import ImageText from 'components/ImageText';

import * as S from './styles';

function DataEngineer() {
  const { t: translator } = useTranslation();

  return (
    <S.Container>
      <ImageText
        image={DataEngineeringImage}
        alt={translator('services.dataEngineer.alt')}
        title={translator('services.dataEngineer.title')}
        subtitle={translator('services.dataEngineer.subtitle')}
        text={translator('services.dataEngineer.text')}
        reverse
        flipImage
      />
    </S.Container>
  );
}

export default DataEngineer;
