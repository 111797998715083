import React from 'react';

import { HashLink } from 'react-router-hash-link';

import Logo from 'assets/logos/logo-rub-dark.svg';

import Instagram from 'assets/logos/social-media/logo-instagram.svg';
import Linkedin from 'assets/logos/social-media/logo-linkedin.svg';

import { Formatter } from 'utils/formatter';
import { email, instagramUrl, linkedinUrl, phone } from '@static-files/about-rub';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import defaultTheme from 'styles/theme';
import useWindowDimensions from 'hooks/window-dimensions';

import { HOMEPAGE_SCREENS } from 'routes/screen-definitions';

import Spacer from 'components/Spacer';
import Collapsible from './Collapsible';

import * as S from './styles';

function Footer() {
  const { t: translator } = useTranslation();

  const { width } = useWindowDimensions();
  const { mobile } = defaultTheme.screensSize;

  const actualYear = new Date().getFullYear();

  const navigate = useNavigate();

  const aboutCompany = (
    <S.ColumnAboutCompany>
      <S.Logo src={Logo} alt={translator('header.logoAlt')} />

      <S.TextAccompany>{translator('footer.accompanyUs')}</S.TextAccompany>

      <S.RowIcons>
        <S.IconButton onClick={() => window.open(instagramUrl, '_blank')?.focus()}>
          <S.Icon src={Instagram} alt={translator('footer.instagramAlt')} />
        </S.IconButton>

        <S.IconButton onClick={() => window.open(linkedinUrl, '_blank')?.focus()}>
          <S.Icon src={Linkedin} alt={translator('footer.linkedinAlt')} />
        </S.IconButton>
      </S.RowIcons>
    </S.ColumnAboutCompany>
  );

  const contact = (
    <>
      <S.LinkTo href={`tel:+${Formatter.onlyNumbers(phone)}`} rel='noopener noreferrer'>
        <S.LinkToText>{`${translator('footer.contactUs.phone')} ${phone}`}</S.LinkToText>
      </S.LinkTo>

      <S.LinkTo href={`mailto:${email}`} target='_blank' rel='noopener noreferrer'>
        <S.LinkToText>{`${translator('footer.contactUs.email')} ${email}`}</S.LinkToText>
      </S.LinkTo>
    </>
  );

  const weAre = (
    <>
      <HashLink to={`${HOMEPAGE_SCREENS.home}${HOMEPAGE_SCREENS.about}#our-mission`} smooth>
        <S.Button>
          <S.ButtonText>{translator('footer.whoWeAre.ourMission')}</S.ButtonText>
        </S.Button>
      </HashLink>

      <HashLink to={`${HOMEPAGE_SCREENS.home}${HOMEPAGE_SCREENS.about}#our-vision`} smooth>
        <S.Button>
          <S.ButtonText>{translator('footer.whoWeAre.ourVision')}</S.ButtonText>
        </S.Button>
      </HashLink>

      <HashLink to={`${HOMEPAGE_SCREENS.home}${HOMEPAGE_SCREENS.about}#our-values`} smooth>
        <S.Button>
          <S.ButtonText>{translator('footer.whoWeAre.ourValues')}</S.ButtonText>
        </S.Button>
      </HashLink>
    </>
  );

  const links = (
    <>
      <S.Button onClick={() => navigate(`${HOMEPAGE_SCREENS.home}${HOMEPAGE_SCREENS.services}`)}>
        <S.ButtonText>{translator('footer.fastLinks.services')}</S.ButtonText>
      </S.Button>

      <S.Button onClick={() => navigate(`${HOMEPAGE_SCREENS.home}${HOMEPAGE_SCREENS.rublabs}`)}>
        <S.ButtonText>{translator('footer.fastLinks.rubLabs')}</S.ButtonText>
      </S.Button>

      <S.Button onClick={() => navigate(`${HOMEPAGE_SCREENS.home}${HOMEPAGE_SCREENS.career}`)}>
        <S.ButtonText>{translator('footer.fastLinks.workWithUs')}</S.ButtonText>
      </S.Button>
    </>
  );

  return (
    <S.Container itemScope itemType='http://schema.org/WPFooter'>
      <Spacer height={width < mobile ? 20 : 40} />

      {width < mobile ? (
        <>
          {aboutCompany}

          <Spacer height={20} />

          <Collapsible label={translator('footer.whoWeAre.title')}>{weAre}</Collapsible>

          <Spacer height={20} />

          <Collapsible label={translator('footer.fastLinks.title')}>{links}</Collapsible>

          <Spacer height={20} />

          <Collapsible label={translator('footer.contactUs.title')}>{contact}</Collapsible>
        </>
      ) : (
        <S.Row center={false}>
          {aboutCompany}

          <S.Column>
            <S.Title>{translator('footer.whoWeAre.title')}</S.Title>

            {weAre}
          </S.Column>

          <S.Column>
            <S.Title>{translator('footer.fastLinks.title')}</S.Title>

            {links}
          </S.Column>

          <S.Column>
            <S.Title>{translator('footer.contactUs.title')}</S.Title>

            {contact}
          </S.Column>
        </S.Row>
      )}

      <Spacer height={width < mobile ? 20 : 40} />

      <S.Row center>
        <S.Flags />

        <S.TextRub>®️ Rubcube {actualYear}</S.TextRub>

        <S.FlagsPlaceholderRight />
      </S.Row>

      <Spacer height={width < mobile ? 20 : 40} />
    </S.Container>
  );
}

export default Footer;
