import styled from 'styled-components';

export const Container = styled.div<{ color: string; backgroundColor: string }>`
  width: 100%;
  height: 100%;

  max-width: 553px;

  display: flex;
  flex-direction: column;

  flex-wrap: wrap;

  align-items: flex-start;
  justify-content: flex-start;

  padding: 40px;

  border-radius: 12px;
  border: ${({ color }) => `2px solid ${color}`};

  background-color: ${({ backgroundColor }) => backgroundColor};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding: 20px;
  }
`;
