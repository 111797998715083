import React from 'react';

import { useTranslation } from 'react-i18next';

import SoftwareDevelopmentImage from 'assets/images/features-cards/software-development.webp';
import ImageText from 'components/ImageText';

import * as S from './styles';

function WebMobile() {
  const { t: translator } = useTranslation();

  return (
    <S.Container>
      <ImageText
        image={SoftwareDevelopmentImage}
        alt={translator('services.webMobile.alt')}
        title={translator('services.webMobile.title')}
        subtitle={translator('services.webMobile.subtitle')}
        text={translator('services.webMobile.text')}
        flipImage
      />
    </S.Container>
  );
}

export default WebMobile;
