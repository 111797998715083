import React from 'react';

import * as S from './styles';

interface ResponseErrorProps {
  message: string;
}

function ResponseError({ message }: ResponseErrorProps) {
  return (
    <S.Content>
      <S.Message>{message}</S.Message>
    </S.Content>
  );
}

export default ResponseError;
