import React, { Fragment } from 'react';

import theme from 'styles/theme';

import Text, { TextStyleType } from 'components/Text';

import * as S from './styles';

interface CardProps {
  title: string;
  texts: string[];
  color: 'blue' | 'green';
}

function Card({ title, texts, color }: CardProps) {
  const map: { [id: string]: { color: string; background: string } } = {};

  map.green = { color: theme.palette.contrast.main, background: theme.palette.contrast.background || '#FFFFFF' };
  map.blue = { color: theme.palette.primary.main, background: theme.palette.primary.background || '#FFFFFF' };

  return (
    <S.Container color={map[color].color} backgroundColor={map[color].background}>
      <Text type={TextStyleType.ListTitleH3} color={map[color].color}>
        {title}
      </Text>

      {texts.map((item, index) => {
        return (
          <Fragment key={index}>
            <Text type={TextStyleType.ListItemP} color={theme.palette.neutral.main}>{`• ${item}`}</Text>
          </Fragment>
        );
      })}
    </S.Container>
  );
}

export default Card;
