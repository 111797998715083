export default {
  translations: {
    staticFiles: {
      featuresCards: {
        card1: {
          title: 'Software development',
          subtitle: 'Web & Mobile',
        },
        card2: {
          title: 'Data Engineering',
          subtitle: 'Data Engineer',
        },
        card3: {
          title: 'User Experience and User Interface',
          subtitle: 'Ux / Ui',
        },
      },
      jobs: {
        job1: { name: 'Talent Bank - Dev Mobile React Native', location: 'Presidente Prudente / Remote' },
        job2: { name: 'Talent Bank - Dev Backend Node.js', location: 'Presidente Prudente / Remote' },
        job3: { name: 'Talent Bank - Data Engineer', location: 'Presidente Prudente / Remote' },
        job4: { name: 'Product Owner', location: 'Presidente Prudente / Remote' },
      },
    },
    components: {
      scrollToTop: {
        alt: 'Scroll to the top',
      },
      cardContact: {
        form: {
          name: 'Start by telling us your name',
          email: 'Your email',
          message: 'Tell us a little about your business and the goal to be achieved...',
          buttonLabel: 'REQUEST CONTACT',
        },
        messageSent: {
          title: 'Request sent',
          description:
            'Thanks for writing us. We will review your request and get back to you as soon as possible. If you need to send us a new message, ',
          highlight: 'click here.',
          alt: 'Success Icon',
        },
        errorMessage: 'Unexpected error has occurred. Try again!',
      },
    },
    header: {
      logoAlt: 'Rubcube Logo',
      openMenuAlt: 'Open Menu',
      closeMenuAlt: 'Close Menu',
      site: {
        home: 'HOME',
        about: 'ABOUT',
        services: 'SERVICES',
        rubLabs: 'RUB LABS',
        career: 'CAREER',
        contact: 'CONTACT',
        buttonLabel: 'subscribe',
      },
      rubcamp: {
        home: 'HOME',
        rubCamp: 'RUB CAMP',
        preRequisites: 'PREREQUISITES',
        program: 'THE PROGRAM',
        buttonLabel: 'subscribe',
      },
    },
    footer: {
      accompanyUs: 'Follow our social medias',
      instagramAlt: 'Instagram Logo',
      linkedinAlt: 'Linkedin Logo',
      whoWeAre: {
        title: 'Who we are',
        ourMission: 'Our mission',
        ourVision: 'Our vision',
        ourValues: 'Our values',
      },
      fastLinks: {
        title: 'Fast links',
        services: 'Services',
        rubLabs: 'Rub Labs',
        workWithUs: 'Work with us',
      },
      contactUs: {
        title: 'Contact us',
        phone: 'Phone:',
        email: 'E-mail:',
      },
    },
    notFound: {
      pageTitle: 'Page not found · Rubcube',
      title: 'Sorry, this page does not exist.',
      text: 'Try to find what you are looking for in our ',
      textLink: 'homepage',
    },
    home: {
      mainBanner: {
        banner1: {
          title: 'rub labs is coming',
          text: 'Our technology and innovation laboratory, focused on creating new products, services and training people',
        },
        banner2: {
          title: 'rub camp will accelerate your learning',
          text: 'Academic background + short courses + practical projects + collaborative team. Our formula for training of professionals',
        },
        buttonLabel: 'LEARN MORE',
      },
      ourMission: {
        title: 'Our Mission',
        text: 'Build a community with the best IT professionals, share knowledge, experiences and values',
        buttonLabel: 'LEARN MORE',
        alt: 'People celebrating',
      },
      whatWeDo: {
        title: 'What we do:',
        text: 'We work with the best practices and tools in the market, helping our customers in their needs',
        buttonLabel: 'LEARN MORE',
      },
      successCase: {
        title: 'Our Clients',
      },
      rubLabs: {
        title: '#1 Office',
        text1: 'Rub Labs, our lab for ',
        highlight1: 'technology',
        text2: ' and ',
        highlight2: 'innovation',
        text3: ', gained its first office located 50m from Unesp in Presidente Prudente.',
        buttonLabel: 'LEARN MORE',
      },
      contactUs: {
        title: 'Want to leverage your business?',
        text: 'Count on us to help you with any development, data engineering and UX/UI challenge. Do not worry about which tools to use, infrastructure and team, just worry about growing!',
      },
      socialMedia: {
        title: 'Follow our journey',
        instagramAlt: 'Instagram Logo',
        linkedinAlt: 'Linkedin Logo',
      },
    },
    about: {
      pageTitle: 'About',
      pageMetaTagDescription: 'It is to build a community with the best IT professionals, to share knowledge, experiences and values.',
      ourMission: {
        title: 'Build a community with the best IT professionals, share knowledge, experiences and values',
        subtitle: 'our mission',
        alt: 'People celebrating',
      },
      ourVision: {
        title: 'Our Vision',
        text: "Be recognized as the dream company of any IT professional, at any point in their career. Doesn't matter if it's starting or with a few years down the road, we want everyone to know that they will grow with us.",
        alt: 'Man wearing virtual reality helmet',
      },
      ourValues: {
        title: 'Our Values',
        line1: {
          highlight1: "We don't wait",
          text1: ' for things to happen.',
        },
        line2: { highlight1: 'We seek', text1: ' our best in everything we do.' },
        line3: { highlight1: 'We say', text1: ' what is needed, ', highlight2: 'whenever', text2: ' it needs.' },
        line4: {
          highlight1: 'We believe',
          text1: ' that the more problems ',
          highlight2: 'we solve',
          text2: ', the better ',
          highlight3: 'we know',
          text3: ' how to them.',
        },
        line5: { highlight1: 'We believe', text1: ' that people ', highlight2: 'need', text2: ' people.' },
      },
      ourHistory: {
        title: 'Our History',
        text1: 'Born offering data engineering services, ETL and automated dashboards.',
        text2: 'Added WEB and Mobile development to our portfolio.',
        text3: 'Creation of the Joint Venture BankFactory for the construction of new financial solutions.',
        text4: 'Creation of Rub Labs for innovation, strengthening the culture and discovering new talents.',
      },
      joinUs: {
        title: 'Join us',
        text: 'We want people who share our values ​​and give their best every day, encouraging the team to give their best too. Who value sincerity, understand the importance of celebrating small and large victories, motivate their peers, share knowledge and, above all, believe in the strength of a community.',
        alt: 'People studying',
        buttonLabel: 'LEARN MORE',
      },
    },
    services: {
      pageTitle: 'Services',
      pageMetaTagDescription:
        'We understand the needs of our customers and together we build solutions in an agile and cross-platform way.',
      whatWeDo: {
        title: 'We are a development agency that works together to help our clients build new solutions',
        subtitle: 'what we do',
        alt: 'Person typing',
      },
      webMobile: {
        title: 'Software Development',
        subtitle: 'web & mobile',
        text: 'Understand the needs of our customers and build solutions in an agile and cross-platform way.',
        alt: 'Man using cell phone',
      },
      dataEngineer: {
        title: 'Data Engineering',
        subtitle: 'data engineer',
        text: "Map all the business data sources and build the integration, treatment and storage flow necessary to support the executive team's decision making, at the right time and according to the most important metrics.",
        alt: 'Data',
      },
      uiUx: {
        title: 'User Experience and User Interface',
        subtitle: 'ui/ux',
        text: 'Support our clients in the creative process of building layout and navigation, ensure brand loyalty and the best possible experience.',
        alt: 'Cell',
      },
      joinUs: {
        title: 'Join us',
        text: 'We want people who share our values ​​and want to give their best every day, encouraging the team to give their best too. Someone that value sincerity, understand the importance of celebrating small and large victories, motivate their peers, share knowledge and, above all, believe in the strength of a community.',
        alt: 'People studying',
        buttonLabel: 'LEARN MORE',
      },
    },
    rubLabs: {
      pageMetaTagDescription: 'We created Rub Labs to accelerate innovation projects, strengthen our culture and discover new talent.',
      rubLabsBanner: {
        title: 'We created Rub Labs to accelerate innovation projects, strengthen our culture and discover new talents',
        subtitle: 'rub labs',
        alt: 'People studying',
      },
      whatIs: {
        title: 'What is',
        alt: 'Group people talking',
        text1: 'Rub Labs was born to be a place where we can ',
        highlight1: 'incubate',
        text2: ' innovation projects, ',
        highlight2: 'test',
        text3: ' new processes, ',
        highlight3: 'promote',
        text4: ' events and training for the team, ',
        highlight4: 'train',
        text5: ' new members and ',
        highlight5: 'spread',
        text6: ' the culture of our community.',
      },
      office: {
        title: '#1 Office',
        text1: 'We already have our ',
        highlight1: 'first address',
        text2: ', located 50m from Unesp in Presidente Prudente/SP at Rua João Gonçalves Fóz - 1147, Room 01.',
        buttonLabel: 'SEE ON MAP',
        alt1: 'Entrance',
        alt2: 'Work stations',
        alt3: 'Auditorium',
        alt4: 'Kitchen',
      },
      rubCamp: {
        title: 'Rub Camp',
        alt: 'People making bonfire',
        text1: 'The Rub Camp is a ',
        highlight1: 'training program',
        text2: ' that happens inside our Lab to ',
        highlight2: 'accelerate the learning process',
        text3: ' of technology professionals, adding academic training, short courses, real projects and a collaborative team.',
        buttonLabel: 'LEARN MORE',
      },
    },
    career: {
      pageTitle: 'Career',
      pageMetaTagDescription: 'We are always looking for the best technology professionals to strengthen our community.',
      newTalents: {
        title: 'We are always looking for the best technology professionals to strengthen our community',
        subtitle: 'new talents',
        alt: 'People talking around a table',
      },
      jobs: {
        title: 'Open Jobs',
        buttonLabel: 'Apply',
      },
    },
    contactUs: {
      pageTitle: 'Contact',
      contact: {
        title: 'Get in touch with Rubcube',
        text: 'We appreciate your interest in contacting us. We are available on the channels below.',
        subtitle: 'General inquiries',
        office: 'Our office',
      },
      joinUs: {
        title: 'How can we help you?',
        text: 'Count on us to help you with any development, data engineering and UX/UI challenge. Do not worry about which tools to use, infrastructure and team, just worry about growing!',
      },
    },
    rubcamp: {
      mainBanner: {
        title: 'rub camp',
        text: 'Accelerate the learning process of technology professionals at the beginning of their careers and strengthen the Rub community with the exchange of knowledge',
        buttonLabel: 'subscribe',
      },
      whoWeAre: {
        title: 'Who we are',
        text: 'We are a development agency that works closely together to help our clients build new solutions.',
        alt: 'Person typing',
        buttonLabel: 'LEARN MORE',
      },
      whatWeDo: {
        title: 'What we do',
        subtitle: 'We work with the best practices and tools in the market, helping our customers in their needs',
        buttonLabel: 'LEARN MORE',
      },
      whatIs: {
        title: 'What is Rub Camp',
        text: 'Rub Camp is a training program for technology students that will take place at our innovation laboratory in Presidente Prudente/SP.',
        alt: 'People talking',
        text1:
          "Brasscom (Association of Information, Communication Technology and Digital Technologies Companies) estimated that between 2019 and 2024, 420,000 technology professionals will be required in Brazil, and considering the training capacity of ​​the country, we would have a deficit of 260 thousand unfilled spots. This number, which was already higher, was revised in 2021 after the pandemic scenario that accelerated the digital transformation of companies, and the demand for 420 thousand positions jumped to 797 thousand from 2021 to 2025. The ratio is that 159 thousand spots will be demanded per year, but the country's training capacity is only 53 thousand professionals, with a deficit of 106 thousand positions/year.",
        text2:
          'Our proposal with Rub Camp, considering the above scenario, is to accelerate the training of professionals who are graduating in the area. We want to bring technology students into our team, train them with market tools and real projects. Add academic training, short courses, projects and collaborative team. We believe that this is a promising formula to collaborate with the reduction of the gap of professionals in the country and still offer a great opportunity for those who are starting their careers.',
      },
      cards: {
        greenCard: {
          title: 'Problem',
          text1: 'Demand for IT professionals growing;',
          text2: 'Gap between what is seen in the classroom and what is applied in the market;',
          text3: 'Difficulty in setting up a good learning path without monitoring, given the number of online courses;',
          text4:
            'Lack of opportunity in the region compared to large centers, or remote positions that limit the exchange of experience at the beginning of a career.',
        },
        blueCard: {
          title: 'Our Proposal',
          text1: 'Experienced and collaborative team for support and knowledge exchange;',
          text2: 'Training and day-to-day contact with current development tools on the market;',
          text3: 'Exposure to real problems and projects to exercise analytical capacity;',
          text4: 'Relaxed atmosphere focused on the evolution of the participants;',
          text5: 'Infrastructure for the best experience during the program.',
        },
      },
      prerequisites: {
        title: 'Prerequisites',
        alt: 'Person typing',
        text1: 'Studying college/university of technology;',
        text2: 'Basic knowledge of any programming language;',
        text3: 'Availability of 30 hours per week;',
        text4: 'Participate in person at the Labs in Presidente Prudente.',
      },
      benefits: {
        title: 'Benefits',
        alt: 'Men talking',
        text1: 'Meal voucher of R$ 20.95/day;',
        text2: 'Dining place;',
        text3: 'Paid Internship - month 1: R$ 300.00;',
        text4: 'Paid Internship - month 2: R$ 600.00;',
        text5: 'Paid Internship - month 3: R$ 900.00;',
        text6: 'Paid Internship - hired after closing: R$ 1,200.00.',
      },
      selectiveProcess: {
        title: 'Selective process',
        label1: '1. Registration through the form',
        label2: '2. Programming logic test',
        label3: '3. Group dynamics',
        label4: '4. Interview',
        label5: '5. Cultural fit',
      },
      lastEdition: {
        title: 'Last editions',
        item: {
          description1: 'The Rub Camp ',
          description2: ' happened in ',
          description3: ' , check out the stages of the process!',
          label1: '1. Registration via form',
          data1: ' registered',
          label2: '2. Programming Logic Test',
          data2: ' Tests sent',
          label3: '3. Group Dynamics',
          data3: ' days of group dynamics with ',
          data4: ' participants',
          label4: '4. Interview with partners',
          data5: ' interviews carried out',
          selected: ' selected',
          date: {
            month: {
              1: 'January ',
              2: 'February ',
              3: 'March ',
              4: 'April ',
              5: 'May ',
              6: 'June ',
              7: 'July ',
              8: 'August ',
              9: 'September ',
              10: 'October ',
              11: 'November ',
              12: 'December ',
            },
          },
        },
      },
      timeLine: {
        title: 'Program format',
        text1:
          'Our training will be carried out through an integrated project, passing through the main areas of Rub: backend, WEB development, mobile development and data engineering. Step by step to understand the stages of developing a complete application. The program will be classified, the most prominent participants will move on to the coming months.',
        finishLabel: 'Closing',
        text2:
          'At the end of the program, we want to invite the participants with greater use and cultural fit to be part of our community!',
        month1: {
          title: 'Month #1',
          subtitle: 'integrated project planning + backend',
          text1: 'Project presentation;',
          text2: 'Definition of stories;',
          text3: 'DB architecture;',
          text4: 'Application architecture;',
          text5: 'Development of features in the backend;',
          text6: 'Workshop - backend publishing in the Cloud',
        },
        month2: {
          title: 'Month #2',
          subtitle: 'front + mobile',
          text1: 'Creation of the Landing Page to publicize the project;',
          text2: 'Start of mobile development;',
          text3: 'Construction of app screens;',
          text4: 'Integration with backend APIs;',
          text5: 'Workshop - publishing applications in stores (play store and app store).',
        },
        month3: {
          title: 'Month #3',
          subtitle: 'mobile + data analysis',
          text1: 'Presentation of the final app;',
          text2: 'Brainstorm which KPIs to track;',
          text3: 'Dashboard construction;',
          text4: 'Dashboard presentation.',
        },
        finalIconAlt: 'Success Icon',
      },
      subscribe: {
        title: 'Participate',
        alt: 'Person typing',
        text: 'We want people who are thirsty to learn, who want to give their best every day and who believe in the strength of a community. If you share our values ​​and see value in our proposal, come be part of this journey with us!',
        buttonLabel: 'SUBSCRIBE',
      },
    },
  },
};
