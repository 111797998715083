import React from 'react';

import TimeLineMarker from './TimeLineMarker';

import * as S from './styles';

interface TimeLineItemProps {
  year: string;
  description: string;
  isSuperior: boolean;
  isLast?: boolean;
}

function TimeLineItem({ year, description, isSuperior, isLast = false }: TimeLineItemProps) {
  return (
    <S.Container>
      <S.TimeLineItemContainer>
        {isSuperior ? (
          <S.Row>
            <S.TimeLineMarkerRelativeContainer>
              <TimeLineMarker isSuperior isLast={isLast} />
            </S.TimeLineMarkerRelativeContainer>

            <S.TextContainer>
              <S.Column>
                <S.TextDescription>{description}</S.TextDescription>

                <S.TextYear>{year}</S.TextYear>
              </S.Column>
            </S.TextContainer>
          </S.Row>
        ) : (
          <S.Row>
            <S.TimeLineMarkerRelativeContainer>
              <TimeLineMarker isSuperior={false} isLast={isLast} />
            </S.TimeLineMarkerRelativeContainer>

            <S.TextContainer>
              <S.Column>
                <S.TextInvertedContainer>
                  <S.TextYear>{year}</S.TextYear>
                </S.TextInvertedContainer>
                <S.TextDescription>{description}</S.TextDescription>
              </S.Column>
            </S.TextContainer>
          </S.Row>
        )}
      </S.TimeLineItemContainer>
    </S.Container>
  );
}

export default TimeLineItem;
