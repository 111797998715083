import React, { useEffect, useRef } from 'react';

import { Outlet } from 'react-router-dom';

import defaultTheme from 'styles/theme';

import useWindowDimensions from 'hooks/window-dimensions';
import { useTranslation } from 'react-i18next';
import Store from 'contexts/Store';

import ScrollTopButton from 'components/ScrollTopButton';
import SubscribeButton from 'components/SubscribeButton';
import Header, { HeaderItem } from 'components/Header';
import Footer from 'components/Footer';
import Flags from 'components/Flags';

import { SectionsId } from 'pages/Rubcamp/sectionIds';

import * as S from './styles';

function RubcampPageLayout() {
  const refOutlet: any = useRef();

  const { t: translator } = useTranslation();

  const { setData } = Store.useStore();

  const { width } = useWindowDimensions();
  const { hd } = defaultTheme.screensSize;

  const items: HeaderItem[] = [
    {
      label: translator('header.rubcamp.home'),
      scrollTargetId: SectionsId.Start,
    },
    {
      label: translator('header.rubcamp.rubCamp'),
      scrollTargetId: SectionsId.WhatIs,
    },
    {
      label: translator('header.rubcamp.preRequisites'),
      scrollTargetId: SectionsId.PreRequisites,
    },
    {
      label: translator('header.rubcamp.program'),
      scrollTargetId: SectionsId.Benefits,
    },
  ];

  const handlePageScroll = () => {
    const sections = document.querySelectorAll('section');

    if (sections) {
      let currentSelectedSectionId: string | null = '';

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        if (window.scrollY >= sectionTop - sectionHeight / 5.0) {
          currentSelectedSectionId = section.getAttribute('id');

          if (currentSelectedSectionId) {
            switch (currentSelectedSectionId) {
              case SectionsId.Start:
              case SectionsId.WhoWeAre:
              case SectionsId.WhatWeDo:
                setData({ index: 0 });

                break;
              case SectionsId.WhatIs:
                setData({ index: 1 });

                break;
              case SectionsId.PreRequisites:
                setData({ index: 2 });

                break;
              case SectionsId.Benefits:
              case SectionsId.SelectiveProcess:
              case SectionsId.Format:
              case SectionsId.Subscribe:
                setData({ index: 3 });

                break;

              default:
                setData({ index: 0 });

                break;
            }
          }
        }
      });
    }
  };

  const handleClick = () => {
    setData({ isMobileMenuExpanded: false });
  };

  useEffect(() => {
    window.addEventListener('scroll', handlePageScroll);
    return () => window.removeEventListener('scroll', handlePageScroll);
  }, []);

  useEffect(() => {
    refOutlet?.current?.addEventListener('mousedown', handleClick);
    return () => refOutlet?.current?.removeEventListener('mousedown', handleClick);
  }, []);

  return (
    <S.Container>
      <Header
        isFixed
        items={items}
        actionButton={
          <S.ContainerActionButton>
            <Flags />
            {hd < width && <SubscribeButton label={translator('header.rubcamp.buttonLabel')} />}
          </S.ContainerActionButton>
        }
      />

      <main>
        <S.OutletHOC ref={refOutlet}>
          <Outlet />
        </S.OutletHOC>
      </main>

      <Footer />

      <ScrollTopButton />
    </S.Container>
  );
}

export default RubcampPageLayout;
