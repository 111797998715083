import styled from 'styled-components';

import TextBase, { TextStyleType } from 'components/Text';

export const Container = styled.div`
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 1246px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    flex-direction: column-reverse;
  }
`;

export const Column = styled.div`
  flex-direction: column;
  justify-content: center;

  width: 50%;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: 100%;
  }
`;

export const Gap = styled.div`
  width: 60px;
  height: 100%;
`;

export const Title = styled(TextBase).attrs({
  type: TextStyleType.TopPageTitle,
})`
  max-width: 700px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding: 0 14px;
  }
`;

export const SubTitle = styled(TextBase).attrs({
  type: TextStyleType.Tagline,
})`
  max-width: 700px;
  margin-bottom: 6px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    margin-top: 30px;
    padding: 0 14px;
  }
`;

export const Image = styled.img`
  height: 100%;

  max-width: 595px;
  max-height: 480px;

  object-fit: cover;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: 100%;
    height: 100%;

    max-height: 750px;
    max-width: 750px;
  }
`;
