import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import * as S from './styles';

function ScrollTopButton() {
  const [visible, setVisible] = useState(false);

  const { t: translator } = useTranslation();

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;

    if (scrolled > 1000) {
      setVisible(true);
    } else if (scrolled <= 1000) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <S.Button isVisible={visible} onClick={scrollToTop}>
      <S.UpIcon alt={translator('components.scrollToTop.alt')} />
    </S.Button>
  );
}

export default ScrollTopButton;
