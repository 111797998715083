import styled from 'styled-components';

export const Container = styled.section`
  display: flex;

  width: 100%;
  height: calc(100vh - 243px - 80px); // Screen height, Footer height, Header height

  flex-direction: column;

  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    height: calc(100vh - 296px - 80px); // Screen height, Footer height, Header height
  }
`;
