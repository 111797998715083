import { DefaultTheme } from 'styled-components';

const defaultTheme: DefaultTheme = {
  borderRadius: '4px',
  palette: {
    primary: {
      main: '#6B7AE5',
      background: '#EEEFFC',
    },
    secondary: {
      main: '#1D1C3E',
      background: '#F1F1F9',
    },
    neutral: {
      main: '#3E4348',
    },
    contrast: {
      main: '#B1C65C',
      background: '#F8FAF0',
    },
    mono: {
      main: '#FFFFFF',
      background: '#F5F5F5',
    },
  },
  screensSize: {
    smallMobile: 400,
    mobile: 750,
    tablet: 768,
    hd: 1024,
    fullHd: 1200,
    ultraHd: 1600,
  },
};

export default defaultTheme;
