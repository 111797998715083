import React from 'react';

import defaultTheme from 'styles/theme';
import useWindowDimensions from 'hooks/window-dimensions';

import * as S from './styles';

interface TimeLineMarkerProps {
  isSuperior: boolean;
  isLast?: boolean;
}

function TimeLineMarker({ isSuperior, isLast = false }: TimeLineMarkerProps) {
  const { width } = useWindowDimensions();
  const { mobile } = defaultTheme.screensSize;

  function renderDesktopMarker() {
    return isSuperior || width < mobile ? (
      <S.Container isSuperior={isSuperior} isLast={isLast}>
        <S.Circle />
        <S.Line />
      </S.Container>
    ) : (
      <S.Container isSuperior={isSuperior} isLast={isLast}>
        <S.Line />
        <S.Circle />
      </S.Container>
    );
  }

  function renderMobileMarker() {
    return width < mobile ? (
      <S.Container isSuperior={isSuperior || width < mobile} isLast={isLast}>
        <S.Column>
          <S.Circle />
          <S.Line />
        </S.Column>
      </S.Container>
    ) : (
      <S.Container isSuperior={isSuperior} isLast={isLast}>
        <S.Line />
        <S.Circle />
      </S.Container>
    );
  }

  return width < mobile ? renderMobileMarker() : renderDesktopMarker();
}

export default TimeLineMarker;
