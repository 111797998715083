import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';

import Store from 'contexts/Store';
import { useTranslation } from 'react-i18next';

import NotFoundContent from './NotFoundContent';

import * as S from './styles';

function NotFound() {
  const { setData } = Store.useStore();

  const { t: translator } = useTranslation();

  useEffect(() => {
    setData({ index: -1 });

    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Container>
      <Helmet>
        <title>{translator('notFound.pageTitle')}</title>

        <meta name='robots' content='noindex' />
      </Helmet>

      <NotFoundContent />
    </S.Container>
  );
}

export default NotFound;
