import React from 'react';

import { useTranslation } from 'react-i18next';

import RubCampImage from 'assets/images/rublabs/rub-camp.webp';
import ImageText from 'components/ImageText';

import Text, { TextStyleType } from 'components/Text';
import ArrowButton, { ArrowButtonColor } from 'components/ArrowButton';
import Spacer from 'components/Spacer';

import { RUBCAMP_SCREENS } from 'routes/screen-definitions';

import * as S from './styles';

function RubCamp() {
  const { t: translator } = useTranslation();

  return (
    <S.Container>
      <ImageText image={RubCampImage} alt={translator('rubLabs.rubCamp.alt')} title={translator('rubLabs.rubCamp.title')}>
        <Text type={TextStyleType.BodyParagraph}>
          {translator('rubLabs.rubCamp.text1')}
          <Text type={TextStyleType.Highlight}>{translator('rubLabs.rubCamp.highlight1')}</Text>
          {translator('rubLabs.rubCamp.text2')}
          <Text type={TextStyleType.Highlight}>{translator('rubLabs.rubCamp.highlight2')}</Text>
          {translator('rubLabs.rubCamp.text3')}
        </Text>

        <Spacer height={24} />

        <ArrowButton
          label={translator('rubLabs.rubCamp.buttonLabel')}
          color={ArrowButtonColor.Blue}
          onPressed={() => window.open(`${RUBCAMP_SCREENS.home}`, '_blank')?.focus()}
        />
      </ImageText>
    </S.Container>
  );
}

export default RubCamp;
