import React, { ReactNode, useState } from 'react';

import ChevronDown from 'assets/icons/chevron-down.svg';

import * as S from './styles';

interface CollapsibleProps {
  label: string;
  children: ReactNode;
}

function Collapsible({ label, children }: CollapsibleProps) {
  const [expand, setExpand] = useState(false);

  return (
    <>
      <S.Collapsible onClick={() => setExpand(!expand)}>
        {label}
        <S.CollapsibleIcon expand={expand} src={ChevronDown} />
      </S.Collapsible>
      <S.CollapsibleContent expand={expand}>{children}</S.CollapsibleContent>
    </>
  );
}

export default Collapsible;
