import styled from 'styled-components';

import { TextHighlight } from 'components/Text/styles';
import TextBase, { TextStyleType } from 'components/Text';

export const Container = styled.section`
  width: 100%;
  height: 100%;

  flex-direction: column;

  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.palette.mono.main};
`;

export const ResponsiveContainer = styled.div`
  flex-direction: column;

  width: 100%;
  height: 100%;

  padding: 80px 40px;

  max-width: 1180px;

  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    gap: 14px;
    padding: 40px 20px;

    align-items: flex-start;
  }
`;

export const Column = styled.div`
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  gap: 12px;
`;

export const Title = styled(TextBase).attrs({
  type: TextStyleType.TopPageTitleNotFound,
})``;

export const Text = styled(TextBase).attrs({
  type: TextStyleType.BodyParagraphNotFoundP,
})``;

export const TextLink = styled(TextHighlight)`
  text-decoration: underline;
`;

export const Button = styled.button`
  :hover {
    transition: opacity 0.3s linear;

    opacity: 0.4;
  }
`;
