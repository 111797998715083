import React from 'react';

import Store from 'contexts/Store';
import defaultTheme from 'styles/theme';

import './i18n';

import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from 'global-styles';

import AppRoutes from './routes';

export default function App() {
  return (
    <>
      <GlobalStyle />

      <ThemeProvider theme={defaultTheme}>
        <Store.Provider>
          <AppRoutes />
        </Store.Provider>
      </ThemeProvider>
    </>
  );
}
