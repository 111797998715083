import styled from 'styled-components';

interface ContainerProps {
  height: number;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: ${({ height }) => `${height}px`};
`;
