import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';

import scrollSnapPolyfill from 'css-scroll-snap-polyfill';

import Store from 'contexts/Store';

import OurMission from 'pages/Home/OurMission';
import MainBanner from './MainBanner';
import WhatWeDo from './WhatWeDo';
import RubLabs from './RubLabs';
import SuccessCases from './SuccessCases';
import SocialMedia from './SocialMedia';
import ContactUs from './ContactUs';

import * as S from './styles';

function Home() {
  const { setData } = Store.useStore();

  useEffect(() => {
    setData({ index: 0 });
    window.scrollTo(0, 0);
  }, []);

  window.addEventListener('load', function f() {
    scrollSnapPolyfill();
  });

  return (
    <S.Container>
      <Helmet>
        <title>Rubcube</title>
      </Helmet>

      <MainBanner />
      <OurMission />
      <WhatWeDo />
      <SuccessCases />
      <RubLabs />
      <ContactUs />
      <SocialMedia />
    </S.Container>
  );
}

export default Home;
