import React from 'react';

import { useTranslation } from 'react-i18next';

import OurMissionImage from 'assets/images/about/our-mission.webp';
import Banner from 'components/Banner';

import * as S from './styles';

function OurMission() {
  const { t: translator } = useTranslation();

  return (
    <S.Container id='our-mission'>
      <Banner
        title={translator('about.ourMission.title')}
        subtitle={translator('about.ourMission.subtitle')}
        imageUri={OurMissionImage}
        imageAlt={translator('about.ourMission.alt')}
      />
    </S.Container>
  );
}

export default OurMission;
