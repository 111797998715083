import styled from 'styled-components';

import TextBase, { TextStyleType } from 'components/Text';

export const Container = styled.div`
  flex-direction: column;

  width: 100%;
  flex: 1;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    flex-direction: column;

    align-items: flex-start;
  }
`;

export const TimeLineItemContainer = styled.div`
  width: 100%;

  justify-content: center;

  position: relative;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    flex-direction: column;

    align-items: flex-start;

    padding-left: 0;
    padding-right: 14px;
  }
`;

export const Column = styled.div`
  width: 100%;

  flex-direction: column;
  flex-wrap: nowrap;
`;

export const Row = styled.div`
  width: 100%;
  flex-wrap: nowrap;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding: 0;
    margin: 0;
    margin-bottom: 0;

    align-items: flex-start;
  }
`;

export const TextContainer = styled.div`
  flex-wrap: nowrap;

  justify-content: flex-start;
  align-items: flex-start;

  margin-bottom: 47px;
  margin-top: 0;
  margin-left: 40px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding: 0;
    margin: 0;
  }
`;

export const TextYear = styled(TextBase).attrs(({ theme }) => {
  return { type: TextStyleType.TextYearAboutH2, color: theme.palette.primary.main };
})`
  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding: 0;
    margin: 0;

    margin-left: 14px;
    margin-bottom: 26px;
  }
`;

export const TextDescription = styled(TextBase).attrs({
  type: TextStyleType.TextDescriptionAboutH2,
})`
  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    margin-left: 14px;
  }
`;

export const TextInvertedContainer = styled.div`
  margin-top: 47px !important;
  margin-bottom: 0 !important;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    margin-top: 0 !important;
  }
`;

export const TimeLineMarkerRelativeContainer = styled.div`
  align-items: center;
  justify-content: center;

  position: relative;

  width: fit-content;
  height: 100%;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    position: static;

    align-items: flex-start;
    justify-content: flex-start;

    margin-top: 0 !important;
  }
`;
