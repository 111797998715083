import React, { ReactNode } from 'react';

import * as S from './styles';

import { Row, Number } from '../styles';

interface LineProps {
  number: string;
  styledText: ReactNode;
}

function Line({ number, styledText }: LineProps) {
  return (
    <S.Container>
      <Row>
        <Number>{`${number}.`}</Number>
        {styledText}
      </Row>
    </S.Container>
  );
}

export default Line;
