import React, { ReactNode } from 'react';

import defaultTheme from 'styles/theme';
import { TextStyleType } from 'components/Text';
import { Carousel } from 'components/Carousel';
import useWindowDimensions from 'hooks/window-dimensions';

import * as S from './styles';

interface ImageTextProps {
  image?: string;
  alt: string;
  title: string;
  titlePaddingBottom?: boolean;
  text?: string;
  subtitle?: string;
  reverse?: boolean;
  flipImage?: boolean;
  children?: ReactNode;
  titleStyle?: TextStyleType;
  subtitleStyle?: TextStyleType;
  textStyle?: TextStyleType;
  textColor?: string;
  titleColor?: string;
  className?: string;
  imageCarousel?: ReactNode[];
}

function ImageText({
  image,
  alt,
  title,
  titlePaddingBottom = false,
  subtitle,
  text,
  reverse = false,
  flipImage = false,
  children,
  titleStyle = TextStyleType.TopPageTitle,
  subtitleStyle = TextStyleType.Tagline,
  textStyle = TextStyleType.BodyParagraph,
  textColor = defaultTheme.palette.secondary.main,
  titleColor = defaultTheme.palette.secondary.main,
  className,
  imageCarousel,
}: ImageTextProps) {
  const { width } = useWindowDimensions();
  const { fullHd } = defaultTheme.screensSize;

  return (
    <S.Container className={className} reverse={reverse}>
      <S.Column>
        {imageCarousel === undefined ? (
          <S.Image src={image} alt={alt} flipImage={flipImage} />
        ) : (
          <Carousel
            carouselHeight={width < fullHd ? 220 : 314}
            items={imageCarousel}
            itemWidth={width < fullHd ? 332 : 574}
            showNextButtons
            itemsPerPage={1}
            isCircular={false}
          />
        )}
      </S.Column>

      <S.Gap />

      <S.Column>
        {subtitle && (
          <S.Subtitle type={subtitleStyle} color={titleColor}>
            {subtitle}
          </S.Subtitle>
        )}

        <S.Title className={className} type={titleStyle} color={titleColor} paddingBottom={titlePaddingBottom}>
          {title}
        </S.Title>

        {text && (
          <S.Text type={textStyle} color={textColor}>
            {text}
          </S.Text>
        )}

        {children}
      </S.Column>
    </S.Container>
  );
}

export default ImageText;
