import styled from 'styled-components';

import CardJoinUsBase from 'components/CardJoinUs';

export const Container = styled.section`
  flex-direction: column;
  align-items: center;
  width: 100%;

  background-color: ${({ theme }) => theme.palette.mono.main};

  padding: 80px 14px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding: 40px 14px;
  }
`;

export const CardJoinUs = styled(CardJoinUsBase)`
  ::-moz-selection {
    color: ${({ theme }) => theme.palette.primary.main};
    background: ${({ theme }) => theme.palette.mono.main};
  }

  ::selection {
    color: ${({ theme }) => theme.palette.primary.main};
    background: ${({ theme }) => theme.palette.mono.main};
  }
`;
