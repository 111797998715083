import React, { ReactNode, useState } from 'react';

import { useTranslation } from 'react-i18next';

import useWindowDimensions from 'hooks/window-dimensions';
import defaultTheme from 'styles/theme';

import { clients } from '@static-files/clients';

import Spacer from 'components/Spacer';

import { Carousel } from 'components/Carousel';

import Text, { TextStyleType } from 'components/Text';

import CompanyCard from './CompanyCard';
import Badge from './Badge';

import * as S from './styles';

function SuccessCases() {
  const { t: translator } = useTranslation();

  const { width } = useWindowDimensions();
  const { mobile } = defaultTheme.screensSize;

  const [index, setIndex] = useState(0);

  const carouselItemWidth = width > mobile ? width : 235;
  const carouselItemHeight = 200;

  const carouselContent: ReactNode[] = clients.map((item, i) => {
    return (
      <div itemProp='brand' itemScope itemType='http://schema.org/Organization'>
        <CompanyCard key={i} logo={item.logo} />

        <meta itemProp='name' content={item.name} />
      </div>
    );
  });

  return (
    <S.Container>
      <Spacer height={width < mobile ? 40 : 80} />

      <Text type={TextStyleType.SectionTitle} color={defaultTheme.palette.secondary.main}>
        {translator('home.successCase.title')}
      </Text>

      <Spacer height={width < mobile ? 10 : 20} />

      {width < mobile ? (
        <>
          {index === 1 ? <Badge /> : <Spacer height={16} />}

          <Carousel items={carouselContent} carouselHeight={carouselItemHeight} itemWidth={carouselItemWidth} onChangeSlide={setIndex} />
        </>
      ) : (
        <S.Row>
          <CompanyCard logo={clients[0].logo} />

          <S.Column>
            <Badge />
            <CompanyCard logo={clients[1].logo} />
          </S.Column>
        </S.Row>
      )}
      <Spacer height={width < mobile ? 40 : 80} />
    </S.Container>
  );
}

export default SuccessCases;
