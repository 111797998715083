import React from 'react';

import { useTranslation } from 'react-i18next';

import CardJoinUs from 'components/CardJoinUs';

import * as S from './styles';

function JoinUs() {
  const { t: translator } = useTranslation();

  return (
    <S.Container>
      <CardJoinUs
        route='/career'
        title={translator('services.joinUs.title')}
        text={translator('services.joinUs.text')}
        alt={translator('services.joinUs.alt')}
        buttonLabel={translator('services.joinUs.buttonLabel')}
      />
    </S.Container>
  );
}

export default JoinUs;
