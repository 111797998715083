import styled from 'styled-components';

import ArrowLeftUri from 'assets/icons/carousel-arrow-left.svg';
import ArrowLeftHighlight from 'assets/icons/carousel-arrow-left-black.svg';
import { ArrowType as ArrowStyle } from '.';

interface WidthProp {
  width: number;
}

interface ArrowProps {
  arrowStyle?: ArrowStyle;
}

interface OverlayContainerProps {
  bottom: number;
  left?: number;
}
export const OverlayContainer = styled.div<OverlayContainerProps>`
  position: ${({ left }) => (left ? 'relative' : 'absolute')};

  bottom: ${({ bottom }) => `${bottom}px;`};
  left: ${({ left }) => `${left}px`};

  display: flex;

  width: fit-content;
  height: fit-content;

  align-items: center;
  justify-content: center;

  z-index: 15;
`;

export const Content = styled.div<WidthProp>`
  width: ${({ width }) => `${width}px;`};

  position: relative;

  align-items: center;
  justify-content: center;
`;

export const CarouselItemWrapper = styled.div`
  scroll-snap-align: center;

  img,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button,
  p {
    user-drag: none;
    user-select: none;

    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;

export const IndicatorContainer = styled.div``;

interface ScrollableContainerProps {
  width: number;
  height: number;
  applySmoothBehavior: boolean;
}

export const ScrollableContainer = styled.div<ScrollableContainerProps>`
  width: ${({ width }) => `${width}px;`};
  height: ${({ height }) => `${height}px;`};

  justify-content: flex-start;

  overflow-y: hidden;
  overflow-x: scroll;

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;

  scroll-behavior: ${({ applySmoothBehavior }) => (applySmoothBehavior ? `smooth;` : 'auto;')};
  scroll-snap-type: ${({ applySmoothBehavior }) => (applySmoothBehavior ? `x mandatory;` : 'none;')};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    scroll-snap-type: x mandatory;

    scroll-behavior: auto;
  }
`;

export const Column = styled.div`
  width: 100%;

  flex-direction: column;

  align-items: center;
  justify-content: center;
`;

export const ArrowLeft = styled.img.attrs<ArrowProps>(({ arrowStyle }) => {
  return {
    src: arrowStyle === 'highlight' ? ArrowLeftHighlight : ArrowLeftUri,
    alt: '',
  };
})<ArrowProps>`
  width: fit-content;
  height: fit-content;

  position: absolute;

  cursor: pointer;
  padding: 20px;
  bottom: calc(50% - 40px);

  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;

  user-drag: none; /* only works for Safari, do NOT recognized by VS Code lint */
  user-select: none;

  -webkit-user-drag: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  :hover {
    transition: opacity 0.3s linear;
    opacity: 0.4;
  }

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding: 14px;
    bottom: calc(50% - 28px);
  }
`;

export const ArrowRight = styled(ArrowLeft)`
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);

  left: auto;
  right: 0;
`;
