import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: column;
  align-items: center;

  width: 100%;

  background-color: ${({ theme }) => theme.palette.mono.main};

  padding: 14px;
`;

export const BlueCard = styled.div`
  width: 100%;

  background-color: ${({ theme }) => theme.palette.secondary.main};

  height: fit-content;

  padding: 80px 60px;

  max-width: 1180px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.hd}px`}) {
    padding: 40px 20px;

    flex-direction: column;
  }
`;
