import styled from 'styled-components';
import carouselArrow from 'assets/icons/carousel-arrow-left-black.svg';

interface iconProps {
  reverse?: boolean;
}

export const Container = styled.section`
  flex-direction: column;
  width: 100%;

  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.palette.primary.background};
`;

export const Content = styled.div`
  width: 100%;
  flex-direction: column;

  justify-content: center;
`;

export const Icon = styled.img.attrs<iconProps>({
  alt: '',
  src: carouselArrow,
})<iconProps>`
  width: 64px;

  ${(props) => props.reverse && 'transform: scaleX(-1);'}
`;
