import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow-x: hidden;
`;
