import React from 'react';
import { Method, ResponseType, AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import MSApi, { MSApiTypes } from 'services/MSApi';

const types: { [type: string]: string[] } = {
  base: ['base'],
};

const apis: { [type: string]: any } = {
  base: MSApi,
};

export interface UseServiceOptions<Params = any> {
  params?: Params;
  headers?: Record<string, string>;
  responseType?: ResponseType;
}

export interface DynamicUseServiceOptions<Params = any> {
  dynamicParams?: Params;
  dynamicHeaders?: Record<string, string>;
  dynamicResponseType?: ResponseType;
}

const useApi = <Response = any, Params = any>(
  apiType: MSApiTypes,
  type: Method,
  route: string,
  { params, headers, responseType }: UseServiceOptions<Params>
) => {
  const [data, setData] = React.useState<AxiosResponse<Response>>();

  const apiName = Object.keys(types).find((item) => types[item].includes(apiType)) as string;
  const API = apis[apiName];
  const api: AxiosInstance = API.getApi(apiType);

  const fetchData = async ({ dynamicParams, dynamicHeaders, dynamicResponseType }: DynamicUseServiceOptions<Params>): Promise<Response> => {
    const methodParams = ['get', 'options', 'head', 'delete'].includes(type.toLowerCase())
      ? { params: dynamicParams !== undefined ? dynamicParams : params }
      : { data: dynamicParams !== undefined ? dynamicParams : params };

    const response = await api
      .request<Response>({
        method: type,
        url: route,
        headers: {
          ...dynamicHeaders,
          ...headers,
        },
        responseType: dynamicResponseType !== undefined ? dynamicResponseType : responseType,
        ...methodParams,
      })
      .catch((err: AxiosError) => {
        throw err;
      });

    setData(response);

    return response.data;
  };

  return { response: data, fetch: fetchData };
};

export default useApi;
