import React from 'react';

import PrerequisitesImage from 'assets/images/rubcamp/prerequisites.webp';

import UniversityIcon from 'assets/icons/university.svg';
import TagIcon from 'assets/icons/tag.svg';
import CalendarIcon from 'assets/icons/calendar.svg';
import PeopleIcon from 'assets/icons/people.svg';

import { useTranslation } from 'react-i18next';
import { SectionsId } from 'pages/Rubcamp/sectionIds';

import defaultTheme from 'styles/theme';

import { TextStyleType } from 'components/Text';
import Spacer from 'components/Spacer';
import RowItem from 'components/RowItem';

import * as S from './styles';

function PreRequisites() {
  const { t: translator } = useTranslation();

  const listEntries = [
    { icon: UniversityIcon, text: translator('rubcamp.prerequisites.text1') },
    { icon: TagIcon, text: translator('rubcamp.prerequisites.text2') },
    { icon: CalendarIcon, text: translator('rubcamp.prerequisites.text3') },
    { icon: PeopleIcon, text: translator('rubcamp.prerequisites.text4') },
  ];

  return (
    <S.Container id={SectionsId.PreRequisites}>
      <S.ImageText
        image={PrerequisitesImage}
        alt={translator('rubcamp.prerequisites.alt')}
        reverse
        title={translator('rubcamp.prerequisites.title')}
        titleStyle={TextStyleType.SectionTitle}
        titleColor={defaultTheme.palette.mono.main}
      >
        <Spacer height={24} />

        {listEntries.map((item, index) => {
          return <RowItem key={index} {...item} />;
        })}
      </S.ImageText>
    </S.Container>
  );
}

export default PreRequisites;
