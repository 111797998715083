import React from 'react';

import * as S from './styles';

interface BannerProps {
  title: string;
  subtitle: string;
  imageUri: string;
  imageAlt: string;
}

function Banner({ title, subtitle, imageUri, imageAlt }: BannerProps) {
  return (
    <S.Container>
      <S.Column>
        <S.SubTitle>{subtitle}</S.SubTitle>

        <S.Title>{title}</S.Title>
      </S.Column>

      <S.Gap />

      <S.Column>
        <S.Image src={imageUri} alt={imageAlt} />
      </S.Column>
    </S.Container>
  );
}

export default Banner;
