import React, { ReactNode } from 'react';

import Text, { TextAlign, TextStyleType } from 'components/Text';
import { rubcampEditions } from '@static-files/rubcamp-editions';
import Spacer from 'components/Spacer';
import defaultTheme from 'styles/theme';
import { useTranslation } from 'react-i18next';

import rubCampFunnel from 'assets/images/rubcamp/stages-funnel.webp';
import * as S from './styles';

function carouselItems(screenSize: number) {
  const items: ReactNode[] = [];
  const data = rubcampEditions;
  const { t: translator } = useTranslation();

  const { fullHd } = defaultTheme.screensSize;

  data.forEach((rubcamp) => {
    items.push(
      <S.Content>
        <Text type={TextStyleType.BodyParagraph} textAlign={TextAlign.Center}>
          {translator(`rubcamp.lastEdition.item.description1`)}#{rubcamp.edition}
          {translator(`rubcamp.lastEdition.item.description2`)}
          {translator(`rubcamp.lastEdition.item.date.month.${rubcamp.month}`)}
          {rubcamp.year}
          {translator(`rubcamp.lastEdition.item.description3`)}
        </Text>
        <Spacer height={40} />
        <S.Item>
          {screenSize > fullHd && (
            <S.FunnelContent>
              <S.Image src={rubCampFunnel} />
              <Spacer height={75} />
              <Text type={TextStyleType.CampInfoItemHighlight} textAlign={TextAlign.Center}>
                {rubcamp.selected}
                {translator('rubcamp.lastEdition.item.selected')}
              </Text>
            </S.FunnelContent>
          )}

          <S.ItemInfo>
            <S.ItemInfoTopic>
              <Text type={TextStyleType.HighlightParagraphTitle} textAlign={TextAlign.Left}>
                {translator('rubcamp.lastEdition.item.label1')}
              </Text>
              <Text type={TextStyleType.CampInfoItemLabel} textAlign={TextAlign.Left} marginLeft={25}>
                {rubcamp.Inscriptions}
                {translator('rubcamp.lastEdition.item.data1')}
              </Text>
            </S.ItemInfoTopic>
            <S.ItemInfoTopic>
              <Text type={TextStyleType.HighlightParagraphTitle} textAlign={TextAlign.Left}>
                {translator('rubcamp.lastEdition.item.label2')}
              </Text>
              <Text type={TextStyleType.CampInfoItemLabel} textAlign={TextAlign.Left} marginLeft={25}>
                {rubcamp.programmingTestSubmits}
                {translator('rubcamp.lastEdition.item.data2')}
              </Text>
            </S.ItemInfoTopic>
            <S.ItemInfoTopic>
              <Text type={TextStyleType.HighlightParagraphTitle} textAlign={TextAlign.Left}>
                {translator('rubcamp.lastEdition.item.label3')}
              </Text>
              <Text type={TextStyleType.CampInfoItemLabel} textAlign={TextAlign.Left} marginLeft={25}>
                {rubcamp.groupDynamicDays}
                {translator('rubcamp.lastEdition.item.data3')}
                {rubcamp.groupDynamicSubmits}
                {translator('rubcamp.lastEdition.item.data4')}
              </Text>
            </S.ItemInfoTopic>
            <S.ItemInfoTopic>
              <Text type={TextStyleType.HighlightParagraphTitle} textAlign={TextAlign.Left}>
                {translator('rubcamp.lastEdition.item.label4')}
              </Text>
              <Text type={TextStyleType.CampInfoItemLabel} textAlign={TextAlign.Left} marginLeft={25}>
                {rubcamp.interviews}
                {translator('rubcamp.lastEdition.item.data5')}
              </Text>
            </S.ItemInfoTopic>
          </S.ItemInfo>
        </S.Item>
      </S.Content>
    );
  });

  return items;
}
export default carouselItems;
