import React, { useEffect } from 'react';

import DocIcon from 'assets/icons/program/doc.svg';
import PostgreIcon from 'assets/icons/program/postgre.svg';
import NodeIcon from 'assets/icons/program/node.svg';
import TypescriptIcon from 'assets/icons/program/typescript.svg';

import HtmlIcon from 'assets/icons/program/html.svg';
import CssIcon from 'assets/icons/program/css.svg';
import JavascriptIcon from 'assets/icons/program/javascript.svg';
import ReactIcon from 'assets/icons/program/react.svg';

import DatabaseIcon from 'assets/icons/program/database.svg';
import GoogleDataIcon from 'assets/icons/program/google-data.svg';
import PythonIcon from 'assets/icons/program/python.svg';
import ProjectorIcon from 'assets/icons/program/projector.svg';

import { useTranslation } from 'react-i18next';
import defaultTheme from 'styles/theme';
import useWindowDimensions from 'hooks/window-dimensions';

import Spacer from 'components/Spacer';

import Text, { TextAlign, TextStyleType } from 'components/Text';

import { SectionsId } from 'pages/Rubcamp/sectionIds';

import SuccessIcon from './SuccessIcon';
import MapEntry from './MapEntry';

import * as S from './styles';

function TimeLineMap() {
  const { t: translator } = useTranslation();

  const { width } = useWindowDimensions();
  const { mobile } = defaultTheme.screensSize;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Container id={SectionsId.Format}>
      <S.Content>
        <Text type={TextStyleType.SectionTitle}>{translator('rubcamp.timeLine.title')}</Text>

        <Spacer height={20} />

        <Text
          type={TextStyleType.BodyParagraph}
          color={defaultTheme.palette.neutral.main}
          textAlign={width < mobile ? TextAlign.Start : TextAlign.Center}
        >
          {translator('rubcamp.timeLine.text1')}
        </Text>

        <S.TimeLine>
          <MapEntry
            title={translator('rubcamp.timeLine.month1.title')}
            subtitle={translator('rubcamp.timeLine.month1.subtitle')}
            texts={[
              translator('rubcamp.timeLine.month1.text1'),
              translator('rubcamp.timeLine.month1.text2'),
              translator('rubcamp.timeLine.month1.text3'),
              translator('rubcamp.timeLine.month1.text4'),
              translator('rubcamp.timeLine.month1.text5'),
              translator('rubcamp.timeLine.month1.text6'),
            ]}
            icons={[DocIcon, PostgreIcon, NodeIcon, TypescriptIcon]}
            isFirst
            isMobile={width < mobile}
          />
          <MapEntry
            isReversed
            title={translator('rubcamp.timeLine.month2.title')}
            subtitle={translator('rubcamp.timeLine.month2.subtitle')}
            texts={[
              translator('rubcamp.timeLine.month2.text1'),
              translator('rubcamp.timeLine.month2.text2'),
              translator('rubcamp.timeLine.month2.text3'),
              translator('rubcamp.timeLine.month2.text4'),
              translator('rubcamp.timeLine.month2.text5'),
            ]}
            icons={[HtmlIcon, CssIcon, JavascriptIcon, ReactIcon]}
            isMobile={width < mobile}
          />
          <MapEntry
            title={translator('rubcamp.timeLine.month3.title')}
            subtitle={translator('rubcamp.timeLine.month3.subtitle')}
            texts={[
              translator('rubcamp.timeLine.month3.text1'),
              translator('rubcamp.timeLine.month3.text2'),
              translator('rubcamp.timeLine.month3.text3'),
              translator('rubcamp.timeLine.month3.text4'),
            ]}
            icons={[DatabaseIcon, GoogleDataIcon, PythonIcon, ProjectorIcon]}
            isMobile={width < mobile}
          />

          <SuccessIcon label={translator('rubcamp.timeLine.finishLabel')} />
        </S.TimeLine>

        <Text
          type={TextStyleType.BodyParagraph}
          color={defaultTheme.palette.neutral.main}
          textAlign={width < mobile ? TextAlign.Start : TextAlign.Center}
        >
          {translator('rubcamp.timeLine.text2')}
        </Text>
      </S.Content>
    </S.Container>
  );
}

export default TimeLineMap;
