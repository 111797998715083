import React, { useEffect, useState } from 'react';

import BrazilFlag from 'assets/icons/flags/brazil.svg';
import EuaFlag from 'assets/icons/flags/eua.svg';

import Store from 'contexts/Store';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

interface FlagsProps {
  className?: string;
}

function Flags({ className }: FlagsProps) {
  const { i18n } = useTranslation();

  const { setData, data } = Store.useStore();

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  const [currentLanguage, setCurrentLanguage] = useState('');

  function handleChangeLanguage(language: string) {
    i18n.changeLanguage(language);

    setData({ language });
    setCurrentLanguage(language);
  }

  useEffect(() => {
    setCurrentLanguage(data?.language);
  }, [data]);

  useEffect(() => {
    const language = getLanguage() ?? 'en-US';
    setCurrentLanguage(language);
    setData({ language });
  }, []);

  return (
    <S.Container className={className}>
      <S.Flag
        src={BrazilFlag}
        onClick={() => handleChangeLanguage('pt-BR')}
        isSelected={currentLanguage === 'pt-BR'}
        alt={currentLanguage === 'pt-BR' ? 'Bandeira do Brasil' : 'Flag of Brazil'}
      />

      <S.Separator />

      <S.Flag
        src={EuaFlag}
        onClick={() => handleChangeLanguage('en-US')}
        isSelected={currentLanguage === 'en-US'}
        alt={currentLanguage === 'pt-BR' ? 'Bandeira dos Estados Unidos' : 'Flag of the United States'}
      />
    </S.Container>
  );
}

export default Flags;
