import React from 'react';

import { useTranslation } from 'react-i18next';
import useWindowDimensions from 'hooks/window-dimensions';
import defaultTheme from 'styles/theme';

import Spacer from 'components/Spacer';

import TimeLineItem from './TimeLineItem';

import * as S from './styles';

function RenderDesktopTimeline() {
  const { t: translator } = useTranslation();

  return (
    <S.Column>
      <S.ResponsiveContainer>
        <S.TextContainer>
          <S.Title>{translator('about.ourHistory.title')}</S.Title>
        </S.TextContainer>
      </S.ResponsiveContainer>

      <S.ResponsiveContainer>
        <S.TimeLineContainer>
          <S.SuperiorRow>
            <S.HorizontalSpacer />

            <TimeLineItem isSuperior year='2020' description={translator('about.ourHistory.text2')} />

            <S.HorizontalSpacer />

            <TimeLineItem isSuperior year='2022.4' description={translator('about.ourHistory.text4')} />
          </S.SuperiorRow>
        </S.TimeLineContainer>
      </S.ResponsiveContainer>

      <S.Line />

      <S.ResponsiveContainer>
        <S.TimeLineContainer>
          <S.InferiorRow>
            <TimeLineItem isSuperior={false} year='2019' description={translator('about.ourHistory.text1')} />

            <S.HorizontalSpacer />

            <TimeLineItem isSuperior={false} year='2022.3' description={translator('about.ourHistory.text3')} />

            <S.HorizontalSpacer />
          </S.InferiorRow>
        </S.TimeLineContainer>
      </S.ResponsiveContainer>

      <Spacer height={33} />
    </S.Column>
  );
}

function RenderMobileTimeline() {
  const { t: translator } = useTranslation();

  return (
    <S.Column>
      <S.Title>{translator('about.ourHistory.title')}</S.Title>

      <S.TimeLineContainer>
        <S.SuperiorColumn>
          <TimeLineItem isSuperior={false} year='2019' description={translator('about.ourHistory.text1')} />
        </S.SuperiorColumn>

        <S.SuperiorColumn>
          <TimeLineItem isSuperior={false} year='2020' description={translator('about.ourHistory.text2')} />
        </S.SuperiorColumn>

        <S.SuperiorColumn>
          <TimeLineItem isSuperior={false} year='2022.3' description={translator('about.ourHistory.text3')} />
        </S.SuperiorColumn>

        <S.SuperiorColumn>
          <TimeLineItem isSuperior={false} year='2022.4' description={translator('about.ourHistory.text4')} isLast />
        </S.SuperiorColumn>
      </S.TimeLineContainer>
    </S.Column>
  );
}

function OurHistory() {
  const { width } = useWindowDimensions();
  const { mobile } = defaultTheme.screensSize;

  return (
    <S.Container>
      <S.OurHistoryContainer>{width < mobile ? RenderMobileTimeline() : RenderDesktopTimeline()}</S.OurHistoryContainer>
    </S.Container>
  );
}

export default OurHistory;
