import React from 'react';

import defaultTheme from 'styles/theme';

import Text, { TextAlign, TextStyleType } from 'components/Text';

import * as S from './styles';

interface MapEntryProps {
  title: string;
  subtitle: string;
  texts: string[];
  icons: string[];
  isMobile: boolean;
  isReversed?: boolean;
  isFirst?: boolean;
}

function MapEntry({ isReversed = false, isFirst = false, title, subtitle, texts, icons, isMobile }: MapEntryProps) {
  return isMobile ? (
    <S.Container isReversed={false}>
      <S.LineColumn>
        <S.Circle />
        <S.Line />
      </S.LineColumn>

      <S.Column>
        <Text type={TextStyleType.TopPageTitle} color={defaultTheme.palette.primary.main}>
          {title}
        </Text>

        <S.Row>
          <S.Icon src={icons[0]} alt='' />
          <S.Icon src={icons[1]} alt='' />
          <S.Icon src={icons[2]} alt='' />
          <S.Icon src={icons[3]} alt='' />
        </S.Row>

        <Text type={TextStyleType.ProgramSubtitle}>{subtitle}</Text>

        {texts.map((item: string, index: number) => {
          return (
            <S.RowItem key={index}>
              <Text type={TextStyleType.BodyParagraph}>{`• ${item}`}</Text>
            </S.RowItem>
          );
        })}
      </S.Column>
    </S.Container>
  ) : (
    <S.Container isReversed={isReversed}>
      <S.ColumnTexts>
        <Text
          type={TextStyleType.TopPageTitle}
          textAlign={isReversed ? TextAlign.Start : TextAlign.End}
          color={defaultTheme.palette.primary.main}
        >
          {title}
        </Text>

        <Text type={TextStyleType.ProgramSubtitle} textAlign={isReversed ? TextAlign.Start : TextAlign.End}>
          {subtitle}
        </Text>

        {texts.map((item: string, index: number) => {
          return (
            <Text key={index} type={TextStyleType.BodyParagraph} textAlign={isReversed ? TextAlign.Start : TextAlign.End}>
              {item}
            </Text>
          );
        })}
      </S.ColumnTexts>

      <S.LineColumn>
        <S.SmallLine isFirst={isFirst} />
        <S.Circle />
        <S.Line />
      </S.LineColumn>

      <S.ColumnIcons isReversed={isReversed}>
        <S.Row>
          <S.Icon src={icons[0]} alt='' />
          <S.Icon src={icons[1]} alt='' />
        </S.Row>

        <S.Row>
          <S.Icon src={icons[2]} alt='' />
          <S.Icon src={icons[3]} alt='' />
        </S.Row>
      </S.ColumnIcons>
    </S.Container>
  );
}

export default MapEntry;
