import React, { ReactNode } from 'react';
import Spacer from 'components/Spacer';

import { Carousel } from 'components/Carousel';
import ArrowButton, { ArrowButtonColor } from 'components/ArrowButton';

import { TextHighlight } from 'components/Text/styles';

import useWindowDimensions from 'hooks/window-dimensions';

import CarouselImage01 from 'assets/images/office/rublabs-01.webp';
import CarouselImage02 from 'assets/images/office/rublabs-02.webp';
import CarouselImage03 from 'assets/images/office/rublabs-03.webp';
import CarouselImage04 from 'assets/images/office/rublabs-04.webp';

import defaultTheme from 'styles/theme';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Text, { TextStyleType } from 'components/Text';

import { HOMEPAGE_SCREENS } from 'routes/screen-definitions';

import * as S from './styles';

function RubLabs() {
  const navigate = useNavigate();
  const { t: translator } = useTranslation();

  const { width } = useWindowDimensions();
  const { mobile, hd } = defaultTheme.screensSize;

  const carouselItemWidth = width < mobile ? 332 : 558;
  const carouselItemHeight = width < mobile ? 220 : 368;

  const carouselContent: ReactNode[] = [
    <img src={CarouselImage01} alt={translator('rubLabs.office.alt1')} width={carouselItemWidth} height={carouselItemHeight} />,
    <img src={CarouselImage02} alt={translator('rubLabs.office.alt2')} width={carouselItemWidth} height={carouselItemHeight} />,
    <img src={CarouselImage03} alt={translator('rubLabs.office.alt3')} width={carouselItemWidth} height={carouselItemHeight} />,
    <img src={CarouselImage04} alt={translator('rubLabs.office.alt4')} width={carouselItemWidth} height={carouselItemHeight} />,
  ];
  const pageContent = (
    <>
      <S.Column>
        <S.Column>
          <Text type={TextStyleType.SectionTitle} color={defaultTheme.palette.secondary.main}>
            {translator('home.rubLabs.title')}
          </Text>

          <Spacer height={8} />

          <S.TextParagraph>
            {translator('home.rubLabs.text1')}
            <TextHighlight>{translator('home.rubLabs.highlight1')}</TextHighlight>
            {translator('home.rubLabs.text2')}
            <TextHighlight>{translator('home.rubLabs.highlight2')}</TextHighlight>
            {translator('home.rubLabs.text3')}
          </S.TextParagraph>
        </S.Column>

        <Spacer height={width < hd ? 40 : 0} />

        <ArrowButton
          label={translator('home.rubLabs.buttonLabel')}
          color={ArrowButtonColor.Blue}
          onPressed={() => navigate(`${HOMEPAGE_SCREENS.home}${HOMEPAGE_SCREENS.rublabs}`)}
        />

        <Spacer height={width < hd ? 40 : 0} />
      </S.Column>

      <Carousel items={carouselContent} carouselHeight={carouselItemHeight} itemWidth={carouselItemWidth} />
    </>
  );

  return (
    <S.Container>
      <Spacer height={width < mobile ? 40 : 80} />

      {width < hd ? <S.DefaultColumn>{pageContent}</S.DefaultColumn> : <S.Row>{pageContent}</S.Row>}

      <Spacer height={width < mobile ? 40 : 80} />
    </S.Container>
  );
}

export default RubLabs;
