import SoftwareDevelopmentImage from 'assets/images/features-cards/software-development.webp';
import DataEngineeringImage from 'assets/images/features-cards/data-engineering.webp';
import UxUiImage from 'assets/images/features-cards/ux-ui.webp';

export const featuresCards = [
  {
    title: 'staticFiles.featuresCards.card1.title',
    subtitle: 'staticFiles.featuresCards.card1.subtitle',
    image: SoftwareDevelopmentImage,
    alt: '',
  },
  {
    title: 'staticFiles.featuresCards.card2.title',
    subtitle: 'staticFiles.featuresCards.card2.subtitle',
    image: DataEngineeringImage,
    alt: '',
  },
  {
    title: 'staticFiles.featuresCards.card3.title',
    subtitle: 'staticFiles.featuresCards.card3.subtitle',
    image: UxUiImage,
    alt: '',
  },
];
