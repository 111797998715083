/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactNode, useEffect, useRef } from 'react';

import * as S from './styles';

interface DragToScrollProps {
  componentToScrollRef: any;
  itemWidth: number;
  itemsCountAfterTriplicate: number;
  children: ReactNode;
  isInfinite: boolean;
  onChangeSlide?: (i: number) => void;
  setApplySmoothBehavior?: (i: boolean) => void;
}

function DragToScroll({
  componentToScrollRef,
  itemWidth,
  itemsCountAfterTriplicate,
  children,
  isInfinite,
  onChangeSlide,
  setApplySmoothBehavior,
}: DragToScrollProps) {
  const imagePositions: [number, number, number][] = [];

  const itemsIndividualCount = itemsCountAfterTriplicate / 3;

  const SliderRef: any = useRef();

  let pos = { top: 0, left: 0, x: 0, y: 0 };

  function setImagesScrollLeftPositions() {
    for (let n = 0; n < itemsIndividualCount; n += 1) {
      imagePositions.push([itemWidth * n, itemWidth * (n + itemsIndividualCount), itemWidth * (n + 2 * itemsIndividualCount)]);
    }
  }

  function updateIndicator() {
    for (let n = 0; n < itemsIndividualCount; n += 1) {
      if (
        imagePositions[n].includes(Math.trunc(componentToScrollRef?.current?.scrollLeft)) ||
        imagePositions[n].includes(Math.ceil(componentToScrollRef?.current?.scrollLeft))
      ) {
        if (onChangeSlide) {
          onChangeSlide(n);

          break;
        }
      }
    }
  }

  const mouseMoveHandler = function f1(e: MouseEvent) {
    updateIndicator();

    // How far the mouse has been moved
    const dx = e.clientX - pos.x;

    componentToScrollRef.current.scrollLeft = pos.left - dx * 2;

    updateIndicator();
  };

  const mouseUpHandler = function f2() {
    SliderRef.current.removeEventListener('mousemove', mouseMoveHandler);
    SliderRef.current.removeEventListener('mouseup', mouseUpHandler);
    SliderRef.current.removeEventListener('mouseleave', mouseUpHandler);

    if (setApplySmoothBehavior) setApplySmoothBehavior(true);

    updateIndicator();
  };

  function touchStartHandler(e: TouchEvent) {
    pos = {
      left: componentToScrollRef.current.scrollLeft,
      top: 0,
      // Get the current touch position
      x: e.changedTouches[0].clientX,
      y: 0,
    };

    if (setApplySmoothBehavior) setApplySmoothBehavior(false);
  }

  function touchEndHandler() {
    if (setApplySmoothBehavior) setApplySmoothBehavior(true);

    updateIndicator();
  }

  function mouseDownHandler(e: MouseEvent) {
    pos = {
      left: componentToScrollRef.current.scrollLeft,
      top: 0,
      // Get the current mouse position
      x: e.clientX,
      y: 0,
    };

    if (setApplySmoothBehavior) setApplySmoothBehavior(false);
    // if (setApplySmoothBehavior && isDesktop) setApplySmoothBehavior(false);

    SliderRef.current.addEventListener('mousemove', mouseMoveHandler);
    SliderRef.current.addEventListener('mouseup', mouseUpHandler);
    SliderRef.current.addEventListener('mouseleave', mouseUpHandler);
  }

  function circularScroll() {
    updateIndicator();

    if (isInfinite) {
      const divSize = itemWidth * itemsCountAfterTriplicate;

      if (componentToScrollRef?.current?.scrollLeft < divSize / 3.0) {
        componentToScrollRef.current.scrollTo({
          left: componentToScrollRef.current.scrollLeft + (divSize * 1.0) / 3.0,
          top: 0,
          behavior: 'instant',
        });
      } else if (componentToScrollRef.current.scrollLeft > (2.0 * divSize) / 3.0) {
        componentToScrollRef.current.scrollTo({
          left: componentToScrollRef.current.scrollLeft - (divSize * 1.0) / 3.0,
          top: 0,
          behavior: 'instant',
        });
      }
    }
  }

  useEffect(() => {
    setImagesScrollLeftPositions();

    componentToScrollRef?.current.addEventListener('scroll', circularScroll, {
      capture: true,
      passive: true,
    });

    SliderRef.current.addEventListener('mousedown', mouseDownHandler, {
      capture: true,
      // passive: true,
    });

    SliderRef.current.addEventListener('touchstart', touchStartHandler, {
      capture: true,
      passive: true,
    });
    SliderRef.current.addEventListener('touchend', touchEndHandler, {
      // capture: true,
      // passive: true,
    });

    componentToScrollRef.current.scrollTo({
      left: componentToScrollRef.current.scrollLeft,
      top: 0,
      behavior: 'instant',
    });

    return function f() {
      return componentToScrollRef.current && componentToScrollRef.current.removeEventListener('scroll', circularScroll);
    };
  }, []);

  return (
    <S.Slider ref={SliderRef} isInfinite={isInfinite}>
      {children}
    </S.Slider>
  );
}

export default DragToScroll;
