import React from 'react';

import { formRubcamp } from '@static-files/about-rub';

import * as S from './styles';

interface SubscribeButtonProps {
  label: string;
  onPressed?: () => void;
}

function SubscribeButton({ label, onPressed = () => window.open(formRubcamp, '_blank')?.focus() }: SubscribeButtonProps) {
  return (
    <S.Button onClick={onPressed}>
      <S.ButtonLabel>{label}</S.ButtonLabel>
    </S.Button>
  );
}

export default SubscribeButton;
