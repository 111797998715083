interface rubCampInfo {
  edition: number;
  month: number;
  year: number;
  Inscriptions: number;
  programmingTestSubmits: number;
  groupDynamicSubmits: number;
  groupDynamicDays: number;
  interviews: number;
  selected: number;
  onTheMainTeam: number;
}

export const rubcampEditions: rubCampInfo[] = [
  {
    edition: 1,
    month: 11,
    year: 2022,
    Inscriptions: 150,
    programmingTestSubmits: 75,
    groupDynamicSubmits: 31,
    groupDynamicDays: 4,
    interviews: 16,
    selected: 10,
    onTheMainTeam: 8,
  },
  {
    edition: 2,
    month: 5,
    year: 2023,
    Inscriptions: 158,
    programmingTestSubmits: 68,
    groupDynamicSubmits: 49,
    groupDynamicDays: 4,
    interviews: 24,
    selected: 14,
    onTheMainTeam: 10,
  },
];
