import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import Text, { TextStyleType } from 'components/Text';

import { availableJobs } from '@static-files/available-jobs';

import defaultTheme from 'styles/theme';

import Job from './Job';

import * as S from './styles';

function Jobs() {
  const { t: translator } = useTranslation();

  return (
    <S.Container>
      <S.Content>
        <Text type={TextStyleType.JobsSectionTitle} color={defaultTheme.palette.primary.main}>
          {translator('career.jobs.title')}
        </Text>

        {availableJobs.map((item, i) => {
          return (
            <Fragment key={i}>
              <Job {...item} />

              {i + 1 !== availableJobs.length && <S.Divider />}
            </Fragment>
          );
        })}
      </S.Content>
    </S.Container>
  );
}

export default Jobs;
